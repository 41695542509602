import {
  AdjustmentsIcon,
  ChartPieIcon,
  ChartSquareBarIcon,
  ClockIcon,
  CubeIcon,
  CurrencyDollarIcon,
  NewspaperIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import "../../../scss/Associatelevel/associate.scss";
import ReportTablePagination from "components/ReportTablePagination";
import TableData from "../../../Data/TableData.json";
import chartData from "../../../Data/Data_1.json";
import chartData2 from "../../../Data/Data_3.json";
import LineChartComponent from "components/LineChart";
import { PieChartComponent } from "components/PieChart";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProjectFun } from "redux/projects/action";
import { getAssociateLevelReport } from "redux/reports/Associate_Level/action";
import Loader from "components/Loader";

const data = [
  { name: "Non-Billable", value: 86 },
  { name: "Billable", value: 14 },
];

function AssociateLevel() {
  const location = useLocation();
  const currentRoute = location.pathname;
  const projects = useSelector((state) => state.projects.allProjects);
  const reportData = useSelector((state) => state.associateLevel);
  const dispatch = useDispatch();
  const initialInterval = getPreviousWeekDates();
  const [intervalStart, setIntervalStart] = useState(initialInterval.start);
  const [intervalEnd, setIntervalEnd] = useState(initialInterval.end);
  const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getProjectFun());
  }, [dispatch]);

  function formatDate(date) {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  function getPreviousWeekDates() {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const diffToPreviousSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
    const previousWeekEnd = new Date(currentDate);
    previousWeekEnd.setDate(currentDate.getDate() - diffToPreviousSaturday - 1);
    const previousWeekStart = new Date(previousWeekEnd);
    previousWeekStart.setDate(previousWeekEnd.getDate() - 6);

    // Format the dates as strings
    const startDateString = formatDate(previousWeekStart);
    const endDateString = formatDate(previousWeekEnd);

    return { start: startDateString, end: endDateString };
  }

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    setIsLoading(true);
    const formattedEndDate = formatDateToYYYYMMDD(new Date(intervalEnd));
    const formattedStartDate = formatDateToYYYYMMDD(new Date(intervalStart));
    const requestBody = {
      end_of_week_date: formattedEndDate,
      start_of_week_date: formattedStartDate,
    };

    dispatch(getAssociateLevelReport(requestBody))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [dispatch, intervalEnd]);

 

  const DropdownIndicator = (props) => {
    const Icon = props.selectProps.indicatorIcon;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon width={20} height={20} />
        </components.DropdownIndicator>
      )
    );
  };
  const currentDate = new Date();

  const moveIntervalLeft = () => {
    const currentStart = new Date(intervalStart);
    const currentEnd = new Date(intervalEnd);
    currentStart.setDate(currentStart.getDate() - 7);
    currentEnd.setDate(currentEnd.getDate() - 7);
    setIntervalStart(formatDate(currentStart));
    setIntervalEnd(formatDate(currentEnd));

    // Check if the new interval is on the previous week
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
    if (currentStart <= currentDate - oneWeekInMilliseconds) {
      setIsRightButtonDisabled(false);
    } else {
      setIsRightButtonDisabled(true);
    }
  };
  const columns = [
    { title: "Project Name", field: "name" },
    { title: "Timesheet Hours", field: "timesheet" },
    { title: "Activity", field: "activity" },
    { title: "Utilization", field: "utilization" },
  ];

  const moveIntervalRight = () => {
    const currentStart = new Date(intervalStart);
    const currentEnd = new Date(intervalEnd);
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds

    // Calculate the new interval dates
    const newStart = new Date(currentStart.getTime() + oneWeekInMilliseconds);
    const newEnd = new Date(currentEnd.getTime() + oneWeekInMilliseconds);

    // Check if the new start date is after the week before the previous week
    const weekBeforePrevious = new Date(getPreviousWeekDates().start);
    weekBeforePrevious.setDate(weekBeforePrevious.getDate() - 7);

    if (newStart > weekBeforePrevious) {
      setIsRightButtonDisabled(true);
    } else {
      setIsRightButtonDisabled(false);
    }

    // Update the interval dates
    setIntervalStart(formatDate(newStart));
    setIntervalEnd(formatDate(newEnd));
  };

  function getUtilizationGrade(utilization) {
    if (utilization >= 61) {
      return "A+";
    } else if (utilization >= 40) {
      return "A";
    } else {
      return "B";
    }
  }

  function getActivityGrade(activity) {
    activity = Math.round(activity);
    if (activity > 50) {
      return "A+";
    } else if (activity >= 40 && activity <= 50) {
      return "A";
    } else if (activity >= 25 && activity <= 39) {
      return "B";
    } else if (activity >= 0 && activity <= 24) {
      return "C";
    }
  }

  function getPadding(grade) {
    return grade === "A+" ? "5px 10px" : "5px 6px ";
  }

  useEffect(() => {
    if (Array.isArray(projects) && projects.length > 0) {
      const newProjectOptions = projects.map((project) => ({
        label: project.name,
        value: project.id,
      }));
      setProjectOptions(newProjectOptions);
    }
  }, [projects]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h3 className="fw-bolder">Associate Level</h3>
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex align-items-center"></div>
            <div className="d-flex align-items-center">
              {/* Date Filter */}
              <div className="d-flex align-items-center">
                <button onClick={moveIntervalLeft} className="btn btn-primary">
                  &lt; {/* Left arrow */}
                </button>
                <div className="date-interval-container">
                  <span>Week Interval (Sat To Fri)</span>
                  <br />
                  <p>
                    {intervalStart} - {intervalEnd}
                  </p>
                </div>
                <button
                  onClick={moveIntervalRight}
                  className="btn btn-primary "
                  disabled={isRightButtonDisabled}
                >
                  &gt; {/* Right arrow */}
                </button>
              </div>
            </div>
          </div>
          <h5 className="mt-3">Past Utilization</h5>
          <div className="mt-3">
            <Row>
              <Col>
                <div className="card-group mb-4">
                  <div className="card order-card-blue rounded-2 mx-1 ">
                    <div className="card-block">
                      <div className="icon-container">
                        <ClockIcon className="mx-2" width={20} height={20} />
                      </div>
                      <div className="mt-3">
                        <span className="card-title">Time sheets</span>
                        <br />
                        <span className="mx-2 fw-bolder card-figure">
                          {`${reportData.data.user_data.timesheet.toFixed(2)}`}{" "}
                          hrs
                        </span>
                        <br />
                        <div className="mb-2">
                          {/* <span className="card-foot">
                        Monthly Average{" "}
                        <span className="fw-bolder">100+ hrs</span>
                      </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card order-card-red rounded-2 mx-1">
                    <div className="card-block">
                      <div className="icon-container">
                        <ChartPieIcon className="mx-2" width={20} height={20} />
                        <span
                          className="grade-utilization"
                          style={{ padding: getPadding("B") }}
                        >
                          {getUtilizationGrade(
                            reportData.data.user_data.utilization
                          )}
                        </span>
                      </div>
                      <span className="card-title">Utilization</span>
                      <br />
                      <span className="mx-2 fw-bolder card-figure">
                        {`${reportData.data.user_data.utilization.toFixed(2)}%`}
                      </span>
                      <br />
                      <div className="mb-2">
                        {/* <span className="card-foot">
                      Monthly Average <span className="fw-bolder">42%</span>
                    </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="card order-card-yellow rounded-2 mx-1">
                    <div className="card-block">
                      <div className="icon-container">
                        <NewspaperIcon
                          className="mx-2"
                          width={20}
                          height={20}
                        />
                        <span
                          className="grade"
                          style={{ padding: getPadding("A+") }}
                        >
                          {getActivityGrade(reportData.data.user_data.activity)}
                        </span>
                      </div>
                      <span className="card-title">Activity</span>
                      <br />
                      <span className="mx-2 fw-bolder card-figure">
                        {`${reportData.data.user_data.activity.toFixed(2)}%`}
                      </span>
                      <br />
                      <div className="mb-2">
                        {/* <span className="card-foot">
                      Monthly Average <span className="fw-bolder">80%</span>
                    </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="card order-card-blue rounded-2 mx-1">
                    <div className="card-block">
                      <div className="icon-container">
                        <AdjustmentsIcon
                          className="mx-2"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="mt-3">
                        <span className="card-title">Non-Billable</span>
                        <br />
                        <span className="mx-2 fw-bolder card-figure">
                          {" "}
                          {`${reportData.data.user_data.non_billable_hours.toFixed(
                            2
                          )}`}{" "}
                          hrs
                        </span>
                        <br />
                        <div className="mb-2">
                          {/* <span className="card-foot">
                        Monthly Average <span className="fw-bolder">68%</span>
                      </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card order-card-green rounded-2 mx-1">
                    <div className="card-block">
                      <div className="icon-container">
                        <CurrencyDollarIcon
                          className="mx-2"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="mt-3">
                        <span className="card-title">Billable</span>
                        <br />
                        <span className="mx-2 fw-bolder card-figure">
                          {" "}
                          {`${reportData.data.user_data.billable_hours.toFixed(
                            2
                          )}`}{" "}
                          hrs
                        </span>
                        <br />
                        <div className="mb-2">
                          {/* <span className="card-foot">
                        Monthly Average <span className="fw-bolder">80%</span>
                      </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card order-card-yellow rounded-2 mx-1">
                    <div className="card-block">
                      <div className="icon-container">
                        <ChartSquareBarIcon
                          className="mx-2"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="mt-3">
                        <span className="card-title">Score</span>
                        <br />
                        <span className="mx-2 fw-bolder card-figure">23%</span>
                        <br />
                        <div className="mb-2">
                           <span className="card-foot">
                        Monthly Average <span className="fw-bolder">45%</span>
                      </span> 
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <ReportTablePagination
                  columns={columns}
                  data={reportData.data.user_data.projects.map((project) => ({
                    name: project.name,
                    timesheet: `${
                      Number.isInteger(project.timesheet)
                        ? project.timesheet.toFixed(0)
                        : project.timesheet.toFixed(2).replace(/\.0$/, "")
                    } hrs`,
                    activity: `${
                      Number.isInteger(project.activity)
                        ? project.activity.toFixed(0)
                        : project.activity.toFixed(2)
                    }%`,
                    utilization: `${
                      Number.isInteger(project.utilization)
                        ? project.utilization.toFixed(0)
                        : project.utilization.toFixed(2)
                    }%`,
                  }))}
                />
              </Col>
              <Col lg={6}>
                <h5>Performance Last 8 weeks</h5>
                <LineChartComponent
                  data={reportData.data.user_data.eight_weeks_performance}
                  // lines={chartData.lines}
                />
              </Col>
            </Row>
            {/* <h4>Project Profitability</h4>
            <div className="dropdown-container custom-dropdown-container">
              <p className="mb-0">Projects</p>
              <Select
                className="custom-dropdown form-select"
                options={projectOptions}
                placeholder="Select Projects"
                isSearchable={false}
                components={{ DropdownIndicator }}
                indicatorIcon={CubeIcon}
                styles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                  }),
                  indicatorSeparator: () => ({}),
                }}
              />
            </div>
            <Row className="mt-3">
              <Col lg={6}>
                <ReportTablePagination
                  columns={TableData.columns_7}
                  data={TableData.data_7}
                />
              </Col>
              <Col lg={6} className="mt-3">
                <LineChartComponent
                  data={chartData2.data}
                  lines={chartData2.lines}
                />
              </Col>
            </Row>
            <h4 className="mt-5">Future schedule</h4>
            <Row className="mt-2">
              <Col lg={8}>
                <ReportTablePagination
                  columns={TableData.columns_7}
                  data={TableData.data_7}
                />
              </Col>
              <Col lg={4}>
                <PieChartComponent
                  data={data}
                  width={500}
                  height={350}
                  cx={200}
                  cy={200}
                />
              </Col>
            </Row>
            <h4 className="mt-5">Avaza Notes</h4>
            <Row>
              <ReportTablePagination
                columns={TableData.columns_9}
                data={TableData.data_9}
                currentRoute={currentRoute}
              />
            </Row> */}
          </div>
        </>
      )}
    </>
  );
}

export default AssociateLevel;
