import React, { useState } from "react";
import { Table, Badge, Button } from "react-bootstrap";
import "./Styles/ReportTable.scss";
import NotesModal from "./NotesModal";
import { ChevronDownIcon } from "@heroicons/react/solid";



const ReportTable = ({ columns, data, currentRoute }) => {
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [modalShow, setModalShow] = useState(false);
  const [visibleRowCount, setVisibleRowCount] = useState(8); // Number of rows initially visible

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const getBadgeVariant = (status) => {
    return status === "Submitted" ? "success" : "danger";
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleInfoBadgeClick = () => {
    setModalShow(true);
  };

  const handleLoadMore = () => {
    setVisibleRowCount((prevCount) =>
      Math.min(prevCount + 2, sortedData.length)
    );
  };

  return (
    <div className="table-container">
      <Table className="custom-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                onClick={() => handleSort(column.field)}
                className={
                  sortField === column.field ? `sorted ${sortOrder}` : ""
                }
              >
                {column.title.charAt(0).toUpperCase() + column.title.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.slice(0, visibleRowCount).map((row, index) => (
            <tr key={index}>
              {columns.map((column, columnIndex) => {
                let cellContent;
                if (column.field === "status") {
                  cellContent = (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        className="badge"
                        bg={getBadgeVariant(row[column.field])}
                      >
                        {row[column.field]}
                      </Badge>
                      {currentRoute === "/associate_reports" &&
                        row[column.field] === "Not Submitted" && (
                          <Badge
                            style={{ border: "none" }}
                            as="button"
                            bg="info"
                            onClick={handleInfoBadgeClick}
                          >
                            Submit Notes
                          </Badge>
                        )}
                    </div>
                  );
                } else if (column.field === "skills") {
                  if (Array.isArray(row[column.field])) {
                    cellContent = row[column.field].join(", ");
                  } else {
                    cellContent = row[column.field];
                  }
                } else if (column.field === "notes") {
                  cellContent = (
                    <div style={{ whiteSpace: "pre-line" }}>
                      {row[column.field]}
                    </div>
                  );
                } else {
                  cellContent = row[column.field];
                }

                return <td key={columnIndex}>{cellContent}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {visibleRowCount < sortedData.length && (
       <div className="load-more-button" onClick={handleLoadMore}>
       <ChevronDownIcon className="icon" />
     </div>
      )}
      <NotesModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default ReportTable;
