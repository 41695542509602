import React, { useState, useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const [contracted, setContracted] = useState(false);
  const [contractSidebar, setContractSidebar] = useState(false);

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    const newContracted = !contracted;
    setContracted(newContracted);
    setContractSidebar(newContracted);
    localStorage.setItem("sidebarContracted", String(newContracted));
    resize();
  };

  let history = useHistory();
  const userDetail = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (!userDetail.token) {
      return history.push("/");
    }
  }, [userDetail]);

  useEffect(() => {
    const { pathname } = location;
    if (
      pathname === "/management_reports" ||
      pathname === "/financial_reports" ||
      pathname === "/associate_reports"
    ) {
      setContracted(true);
      setContractSidebar(true);
      localStorage.setItem("sidebarContracted", "true");
    } else {
      setContracted(false);
      setContractSidebar(false);
      localStorage.setItem("sidebarContracted", "false");
    }
  }, [location]);

  if (userDetail.token) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            <Sidebar
              setContracted={setContractSidebar}
              contracted={contractSidebar}
              onMouseEnter={toggleMouseOver}
              onMouseLeave={toggleMouseOver}
              toggleContracted={toggleContracted}
            />

            <main
              className="content"
              style={{
                backgroundColor: "white",
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Topbar toggleContracted={toggleContracted} />
              <Component {...props} />
            </main>
          </>
        )}
      />
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default PrivateRoute;
