import * as types from "./contants";

const initialState = {
  allProjects: {},
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECT: {
      return {
        ...state,
        allProjects: action.payload,
      };
    }
    case types.CREATE_PROJECT: {
      return {
        ...state,
        allProjects: [...state.allProjects, action.payload],
      };
    }
    default:
      return state;
  }
}
