import React, { useEffect, useMemo, useState } from "react";
import { Modal, Form, InputGroup, Button, Tabs, Tab } from "react-bootstrap";
import Datetime from "react-datetime";
import { CalendarIcon, PencilIcon, XIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  getAllPublicHolidays,
  addPublicHoliday,
  updatePublicHoliday,
  deletePublicHoliday,
} from "redux/requests/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import countryList from "react-select-country-list";

const PublicHolidayModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  const holidayList = useSelector((state) => state.reqs.allPublicHolidays.data);
  const [activeTab, setActiveTab] = useState("Create Holiday");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentHoliday, setCurrentHoliday] = useState(null);
  const [repeat_annually, setrepeat_annually] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedregion, setSelectedRegion] = useState(null);
  const [regionSelected, setRegionSelected] = useState(false);
  const [holidaysForSelectedRegion, setHolidaysForSelectedRegion] = useState(
    []
  );
  const [disable, setDisable] = useState(true);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
      .min(Yup.ref("start_date"), "End date can't be before start date")
      .required("End Date is required"),
    repeat_annually: Yup.bool(),
    region: editMode
      ? Yup.object().nullable()
      : Yup.object().nullable().required("Region is required"),
  });

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: false,
    })
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      repeat_annually: false,
      region: null,
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        setDisableSubmitButton(true); // Disable the submit button
        if (editMode) {
          await handleUpdateHoliday(currentHoliday.id, values);
        } else {
          await handleAddHoliday(values);
        }
        setDisableSubmitButton(false); // Enable the submit button after successful submission
      } catch (error) {
        setDisableSubmitButton(false); // Enable the submit button in case of an error
        toast.error(error?.response?.data?.detail);
      }
    },
  });

  useEffect(() => {
    // Fetch the country options when the component mounts
    const options = countryList().getData();
    setCountryOptions(options);
  }, []);
  const isValid = (currentDate) => {
    // Check if the date is a weekend (Saturday or Sunday)
    if (currentDate.isoWeekday() === 6 || currentDate.isoWeekday() === 7) {
      return false; // Disable selection for weekends
    }

    // Check if the date is a holiday
    const formattedDate = moment(currentDate).format("YYYY-MM-DD");
    const selectedRegion = selectedregion?.value?.toLowerCase();
    const isHoliday = holidayList?.some(
      (holiday) =>
        holiday.region.toLowerCase() === selectedregion?.value?.toLowerCase() &&
        moment(formattedDate).isBetween(
          holiday.start_date,
          holiday.end_date,
          null,
          "[]"
        )
    );


    return !isHoliday 
  };

  const handleAddHoliday = (values) => {
    const holidayData = {
      ...values,
      repeat_annually: repeat_annually,
      region: selectedregion.value,
    };
    dispatch(addPublicHoliday(holidayData))
      .then(() => {
        formik.resetForm();
        setSelectedRegion(null);
        setRegionSelected(false);
        setDisable(true);
      })
      .catch((error) => {
        toast.error(error);
        setSelectedRegion(null);
        setRegionSelected(false);
        formik.resetForm();
        setDisable(true);
      });
  };

  const handleUpdateHoliday = async (id, values) => {
    try {
      const holidayData = {
        ...values,
        repeat_annually: repeat_annually,
        region: values.region.value,
        id: id,
      };
  
      await dispatch(updatePublicHoliday(id, holidayData));
      setEditMode(false);
      setCurrentHoliday(null);
      formik.resetForm();
      setSelectedRegion(null);
      setRegionSelected(false);
      setDisable(true);
      setActiveTab("List of Holidays");
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      formik.resetForm();
      setSelectedRegion(null);
      setRegionSelected(false);
      setDisable(true);
    }
  };

  const handleEdit = (holiday) => {
    setDisable(false);
    setActiveTab("Create Holiday");
    setEditMode(true);
    setCurrentHoliday(holiday);

    // Find the country name (label) based on the value from the countryList
    const selectedCountry = countryOptions.find(
      (option) => option.value === holiday.region
    );
    const countryName = selectedCountry
      ? selectedCountry.label
      : holiday.region;

    setSelectedRegion({ value: holiday.region, label: countryName });
    formik.setValues({
      name: holiday.name,
      start_date: moment(holiday.start_date).format("YYYY-MM-DD"),
      end_date: moment(holiday.end_date).format("YYYY-MM-DD"),
      repeat_annually: holiday.repeat_annually,
      region: { value: holiday.region, label: countryName }, // Set region for react-select
    });
    // Filter holidays for the selected region during editMode
    const holidaysInRegion = holidayList.filter(
      (h) => h.region === holiday.region
    );
    setHolidaysForSelectedRegion(holidaysInRegion);
    setRegionSelected(true);
  };

  const handleDelete = async (id) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      text: "Are you sure you want to delete this holiday?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: false,
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deletePublicHoliday(id));
        setEditMode(false);
        setCurrentHoliday(null);
        formik.resetForm();
        setSelectedRegion(null);
      } catch (error) {
        toast.error(error?.response?.data?.detail);
        setSelectedRegion(null);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllPublicHolidays());
  }, [dispatch, activeTab]);

  const handleCloseModal = () => {
    setCurrentHoliday(null);
    setActiveTab("Create Holiday");
    if (editMode) {
      setEditMode(false);
      setCurrentHoliday(null);
      formik.resetForm();
      setActiveTab("Create Holiday");
      setSelectedRegion(null);
      setDisable(true);
    } else {
      formik.resetForm();
      setCancelClicked(false);
      handleClose();
      setSelectedRegion(null); // Clear the selected region
      setDisable(true);
    }
  };
  const renderDay = (props, currentDate, selectedDate) => {
    const formattedDate = moment(currentDate).format("YYYY-MM-DD");
    const isWeekend =
      currentDate.isoWeekday() === 6 ||
      currentDate.isoWeekday() === 7 

    // Check if the current date is within any holidays for the selected region
    const isHoliday = holidayList?.some(
      (holiday) =>
        holiday.region === selectedregion?.value?.toLowerCase() &&
        moment(formattedDate).isBetween(
          holiday.start_date,
          holiday.end_date,
          null,
          "[]"
        )
    );

    // Define a class to change the color of holidays to red
    const dayClass = isHoliday ? "holiday-day" : "";

    // Define the cursor style
    const cursorStyle = isWeekend || isHoliday ? "not-allowed" : "pointer";

    const isToday = moment(currentDate).isSame(moment(), "day");

    const todayClass =
      isToday && isHoliday ? "today-holiday" : isToday ? "bold-today" : "";

    return (
      <td
        {...props}
        className={`rdtDay ${
          isWeekend && !isHoliday ? "disabled" : ""
        } ${dayClass} ${todayClass}`}
        style={{ cursor: cursorStyle }}
      >
        {currentDate.date()}
        {isHoliday && (
          <div className="holiday-tooltip">
            {
              holidayList.find((holiday) =>
                moment(formattedDate).isBetween(
                  holiday.start_date,
                  holiday.end_date,
                  null,
                  "[]"
                )
              ).name
            }
          </div>
        )}
      </td>
    );
  };

  return (
    <Modal centered show={show} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? "Edit Holiday" : "Add Holiday"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Tabs
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
          id="holiday-tabs"
        >
          <Tab
            eventKey="Create Holiday"
            title={editMode ? "Edit Holiday" : "Create Holiday"}
          >
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group id="name" className="mt-2">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    autoFocus
                    placeholder="Enter holiday name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {formik.submitCount > 0 && formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </Form.Group>
              <Form.Group id="region" className="mt-2">
                <Form.Label>Region</Form.Label>
                <Select
                  required
                  className="region-select"
                  name="region"
                  options={countryOptions}
                  isDisabled={editMode}
                  value={selectedregion}
                  onChange={(selectedOption) => {
                    setSelectedRegion(selectedOption);
                    formik.setFieldValue("region", selectedOption);
                    setRegionSelected(true);
                    setDisable(false);
                    const selectedRegionValue = selectedOption.value;
                    const holidaysInRegion = holidayList.filter(
                      (holiday) => holiday.region === selectedRegionValue
                    );
                    setHolidaysForSelectedRegion(holidaysInRegion);
                  }}
                />
                {formik.touched.region && formik.errors.region && (
                  <div className="text-danger">{formik.errors.region}</div>
                )}
              </Form.Group>
              <Form.Group id="start_date" className="mt-2">
                <Form.Label>Select start date</Form.Label>
                <Datetime
                  timeFormat={false}
                  value={formik.values.start_date}
                  isValidDate={isValid}
                  onChange={(date) => {
                    formik.setFieldValue(
                      "start_date",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={formik.values.start_date}
                        onFocus={openCalendar}
                        onChange={() => {}}
                        disabled={disable}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik?.errors?.start_date}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                  renderDay={renderDay}
                  closeOnSelect={true}
                />
              </Form.Group>

              <Form.Group id="end_date" className="my-2">
                <Form.Label>Select end date</Form.Label>
                <Datetime
                  timeFormat={false}
                  value={formik.values.end_date}
                  isValidDate={isValid}
                  onChange={(date) => {
                    formik.setFieldValue(
                      "end_date",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={formik.values.end_date}
                        onFocus={openCalendar}
                        onChange={() => {}}
                        disabled={disable}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.end_date}
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                  renderDay={renderDay}
                  closeOnSelect={true}
                />
                {formik.touched.end_date && formik.errors.end_date && (
                  <div className="text-danger">{formik.errors.end_date}</div>
                )}
              </Form.Group>
              <Form.Group id="repeat_annually" className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="Repeat Annually"
                  name="repeat_annually"
                  checked={formik.values.repeat_annually}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setrepeat_annually(e.target.checked); // Update repeat_annually in the component state
                  }}
                />
                {formik.touched.repeat_annually &&
                  formik.errors.repeat_annually && (
                    <div className="text-danger">
                      {formik.errors.repeat_annually}
                    </div>
                  )}
              </Form.Group>

              <div className="d-flex">
                <Button
                  className="mt-2"
                  variant="secondary"
                  type="submit"
                  disabled={disableSubmitButton}
                >
                  {editMode ? "Update Holiday" : "Add Holiday"}
                </Button>
                {editMode ? (
                  <Button
                    variant="link"
                    className="text-gray ms-auto"
                    onClick={() => {
                      handleCloseModal();
                      setEditMode(false); // Handle cancel action
                      setCurrentHoliday(null); // Clear currentHoliday
                      formik.resetForm(); // Reset the form
                      setActiveTab("Create Holiday"); // Switch to "Create Holiday" tab
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    className="text-gray ms-auto"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                )}
              </div>
            </Form>
          </Tab>
          <Tab eventKey="List of Holidays" title="List of Holidays">
            <div className="scrollable-table">
              {holidayList?.length === 0 ? (
                <span className="w-100 d-block text-center my-4">
                  No Public Holiday declared by Admin!
                </span>
              ) : (
                <table className="table mt-2 holiday-table">
                  <thead>
                    <tr className="thead-light">
                      <th>Holiday Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Region</th>
                      <th>Repeat</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(Array.isArray(holidayList) ? [...holidayList] : [])
                      .sort((a, b) => {
                        // Sort by a custom criteria, e.g., by the holiday's ID
                        // You can adjust this based on your actual sorting criteria
                        return b.id - a.id;
                      })
                      .map((holiday) => (
                        <tr key={holiday.id}>
                          <td>{holiday.name}</td>
                          <td>
                            {moment(holiday.start_date).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {moment(holiday.end_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{holiday.region}</td>
                          <td>
                            {holiday.repeat_annually ? (
                              <span className="text-success">Yes</span>
                            ) : (
                              <span className="text-danger">No</span>
                            )}
                          </td>
                          <td>
                            <span
                              onClick={() => {
                                handleEdit(holiday);
                              }}
                              className="action-icon mr-2"
                            >
                              <PencilIcon
                                style={{ cursor: "pointer" }}
                                className="icon icon-xs me-3"
                              />
                            </span>
                            <span
                              onClick={() => handleDelete(holiday.id)}
                              className="action-icon"
                            >
                              <XIcon
                                style={{ cursor: "pointer" }}
                                className="icon icon-xs me-3"
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default PublicHolidayModal;
