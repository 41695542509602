import React from "react";
import { Nav, Tab } from "react-bootstrap";
import "../../../scss/managementlevel/managementlevel.scss";
import PastContent from "./PastContent";
import FutureContent from "./FutureContent";
import NoteComponent from "./NoteComponent";

function ManagementLevel() {
  return (
    <>
      <h3 className="fw-bolder">Management Level</h3>
      <Tab.Container defaultActiveKey="past">
        <Nav variant="tabs" className="custom-tabs">
          <Nav.Item>
            <Nav.Link eventKey="past">Analysis</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="future">
              Future
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="notes">Notes</Nav.Link>
          </Nav.Item> */}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="past">
            <PastContent />
          </Tab.Pane>
          <Tab.Pane eventKey="future">
            <FutureContent />
          </Tab.Pane>
          <Tab.Pane eventKey="notes">
            <NoteComponent />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default ManagementLevel;
