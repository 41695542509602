import {
  CalendarIcon,
  CubeIcon,
  IdentificationIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import TableData from "../../../Data/TableData.json";
import ReportTablePagination from "components/ReportTablePagination";
import { PieChartComponent } from "../../../components/PieChart";
import "../../../scss/managementlevel/future.scss";
import { getProjectFun } from "redux/projects/action";
import { getUserFun } from "redux/user/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "components/Loader";

const dropdownOptions = [
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
  { value: "option4", label: "Option 4" },
  { value: "option5", label: "Option 5" },
];

const data = [
  { name: "Non-Billable", value: 86 },
  { name: "Billable", value: 14 },
];

function getStartingDateOfNextWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilNextSaturday = 5 - dayOfWeek;
  today.setDate(today.getDate() + daysUntilNextSaturday + 1);
  return today;
}
function formatDate(date) {
  return date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
function formatDateForMonth(date) {
  return date.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  });
}
function getNextMonth() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const nextMonth = new Date(today);
  nextMonth.setMonth(currentMonth + 1);
  return nextMonth;
}

function FutureContent() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);

  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.allProjects);
  const users = useSelector((state) => state.users.allUsers);
  const teamSupervisors = Array.isArray(users)
    ? users.filter((user) => user.isSupervisor)
    : [];

  const nextWeekStartingDate = getStartingDateOfNextWeek();
  const nextWeekEndingDate = new Date(nextWeekStartingDate);
  nextWeekEndingDate.setDate(nextWeekStartingDate.getDate() + 6);
  const formattedStartDate = formatDate(nextWeekStartingDate);
  const formattedEndDate = formatDate(nextWeekEndingDate);
  const nextMonthDate = getNextMonth();
  const formattedNextMonth = formatDateForMonth(nextMonthDate);
  const [projectOptions, setProjectOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const dateOptions = [
    {
      label: `Next Week`,
      value: `${formattedStartDate} - ${formattedEndDate}`,
    },
    { label: "Next Month", value: formattedNextMonth },
  ];

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
   
  };
  useEffect(() => {
    dispatch(getProjectFun());
    dispatch(getUserFun());
  }, [dispatch]);
  useEffect(() => {
    // When the projects data changes, update projectOptions
    if (Array.isArray(projects) && projects.length > 0) {
      const newProjectOptions = projects.map((project) => ({
        label: project.name,
        value: project.id,
      }));
      setProjectOptions(newProjectOptions);
    }
  }, [projects]);
  useEffect(() => {
    // When the projects data changes, update projectOptions
    if (Array.isArray(users) && users.length > 0) {
      const newUserOptions = users.map((user) => ({
        label: user.name,
        value: user.id,
      }));
      setUserOptions(newUserOptions);
    }
  }, [users]);

  const DropdownIndicator = (props) => {
    const Icon = props.selectProps.indicatorIcon;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon width={20} height={20} />
        </components.DropdownIndicator>
      )
    );
  };

  useEffect(() => {
    // Fetch projects data and set loading state to true
    dispatch(getProjectFun())
      .then(() => setIsLoadingProjects(false)) // Set loading state to false when data is fetched
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setIsLoadingProjects(false); // Set loading state to false even on error
      });
  }, [dispatch]);

  useEffect(() => {
    // Fetch projects data and set loading state to true
    dispatch(getUserFun())
      .then(() => setIsLoadingProjects(false)) // Set loading state to false when data is fetched
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setIsLoadingProjects(false); // Set loading state to false even on error
      });
  }, [dispatch]);
  return isLoadingProjects ? (
    <Loader />
  ) : (
    <>
      <div>
        <div className="d-flex justify-content-start mt-2">
          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Projects</p>
            <Select
              className="custom-dropdown form-select"
              // options={projects.map((project) => ({
              //   value: project.id,
              //   label: project.name,
              // }))}
              placeholder="Select Projects"
              isSearchable={true}
              components={{ DropdownIndicator }}
              indicatorIcon={CubeIcon}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </div>

          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Employee</p>
            <Select
              className="custom-dropdown form-select"
              options={userOptions}
              placeholder="Select Employee"
              isSearchable={true}
              components={{ DropdownIndicator }}
              indicatorIcon={IdentificationIcon}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </div>

          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Teams</p>
            <Select
              className="custom-dropdown form-select"
              options={teamSupervisors.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
              placeholder="Select Team"
              isSearchable={false}
              components={{ DropdownIndicator }}
              indicatorIcon={UserGroupIcon}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </div>

          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Admin</p>
            <Select
              className="custom-dropdown form-select"
              options={dropdownOptions}
              placeholder="Select Admin"
              isSearchable={false}
              components={{ DropdownIndicator }}
              indicatorIcon={UserCircleIcon}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </div>
          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Date</p>
            <Select
              className="custom-dropdown form-select"
              options={dateOptions}
              isSearchable={false}
              components={{ DropdownIndicator }}
              indicatorIcon={CalendarIcon}
              onChange={handleSelectChange}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </div>
        </div>
        <div className="report-container">
          <div className="report">
            <div className="my-3">
              <ReportTablePagination
                columns={TableData.columns_1}
                data={TableData.data_1}
              />
            </div>
            <div className="my-5">
              <ReportTablePagination
                columns={TableData.columns_2}
                data={TableData.data_2}
              />
            </div>
          </div>
          <div className="pie=chart">
            <PieChartComponent data={data} width={500} height={400} />
          </div>
        </div>
      </div>
    </>
  );
}

export default FutureContent;
