import * as types from "./contants";

const initialState = {
  allReqs: [],
  allPublicHolidays: [],
};

export default function requestReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_All_REQ: {
      return {
        ...state,
        allReqs: action.payload,
      };
    }
    case types.EDIT_REQUEST: {
      return {
        ...state,
        allReqs: {
          data: state.allReqs.data?.map((x) =>
            x.id === action.payload.id ? action.payload : x
          ),
        },
      };
    }
    case types.UPDATE_LEAVES_ALLOWED: {
      return {
        ...state,
        leavesAllowed: action.payload,
      };
    }

    case types.GET_ALL_PUBLIC_HOLIDAYS: {
      return {
        ...state,
        allPublicHolidays: action.payload,
      };
    }
    case types.ADD_PUBLIC_HOLIDAY: {
      return {
        ...state,
        allPublicHolidays: [...state.allPublicHolidays, action.payload],
      };
    }
    case types.UPDATE_PUBLIC_HOLIDAY: {
      return {
        ...state,
        allPublicHolidays: state.allPublicHolidays.map((holiday) =>
          holiday.id === action.payload.id ? action.payload : holiday
        ),
      };
    }
    case types.DELETE_PUBLIC_HOLIDAY: {
      return {
        ...state,
        allPublicHolidays: state.allPublicHolidays.filter(
          (holiday) => holiday.id !== action.payload.id
        ),
      };
    }

    // case types.GET_DESIGNATION: {
    //   return {
    //     ...state,
    //     designation: action.payload,
    //     designationData: action.payload.data,
    //   };
    // }
    // case types.EDIT_DESIGNATION: {
    //   return {
    //     ...state,
    //     designationData: state.designationData?.map((x) =>
    //       x.id === action.payload.id ? action.payload : x
    //     ),
    //   };
    // }
    default:
      return state;
  }
}
