import React, { useState } from "react";
import { ArrowNarrowLeftIcon, MailIcon } from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Routes } from "utils/routes";
import { useDispatch } from "react-redux";
import { forgetFun } from "redux/auth/action";

export default () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const formikSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: formikSchema,
    onSubmit: async (values, action) => {
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = await dispatch(forgetFun(values, history));

      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });
  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="d-flex align-items-center justify-content-center"
              >
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">
                  Don't fret! Just type in your email and we will send you a
                  link to reset your password!
                </p>
                <Form
                  onSubmit={Formik.handleSubmit}
                  className="mt-4 signUp_Form"
                >
                  <div className="mb-4">
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <MailIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          type="text"
                          name="email"
                          value={Formik.values.email}
                          onChange={(e) => {
                            Formik.setFieldValue("email", e.target.value);
                          }}
                          placeholder="Enter email"
                        />
                        {Formik.submitCount > 0 && (
                          <Form.Control.Feedback type="invalid">
                            {Formik?.errors?.email}
                          </Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="d-grid">
                    <Button
                      variant="gray-800"
                      type="submit"
                      disabled={disableSubmitButton}
                    >
                      {disableSubmitButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Recover password"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
