import React, { useEffect, useState } from "react";
import {
  CheckIcon,
  EyeIcon,
  HomeIcon,
  XIcon,
  RefreshIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { Breadcrumb, Button } from "react-bootstrap";
import GlobleTable from "components/GlobleTable";
import { useDispatch, useSelector } from "react-redux";
import { getProjectFun, syncProjectFun } from "redux/projects/action";
import { useHistory } from "react-router-dom";
import AddProjectModal from "./AddProjectModal";
import UpDownIcon from "components/UpDownIcon";


export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const projects = useSelector((state) => state.projects.allProjects);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSyncClick = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await dispatch(syncProjectFun());
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const columns = [
    // { dataField: "id", text: "ID", hidden: true, csvExport: false },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: () => (
        <span>
          Name <UpDownIcon width={14} height={14} />
        </span>
      ),
    },
    // { dataField: "email", text: "Email" },
    {
      dataField: "hubStaffId",
      text: "HubStaff Created",
      searchable: false,
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (a && !b) {
          return order === "asc" ? -1 : 1;
        }
        if (!a && b) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      },
      formatter: (col) =>
        col ? (
          <CheckIcon className="icon icon-xs me-3" role="button" />
        ) : (
          <XIcon className="icon icon-xs" role="button" />
        ),
      headerFormatter: () => (
        <span>
          HubStaff Created <UpDownIcon width={14} height={14} />
        </span>
      ),
    },

    {
      dataField: "avazaId",
      text: "Avaza Created",
      searchable: false,
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (a && !b) {
          return order === "asc" ? -1 : 1;
        }
        if (!a && b) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      },
      formatter: (col) =>
        col ? (
          <CheckIcon className="icon icon-xs me-3" role="button" />
        ) : (
          <XIcon className="icon icon-xs" role="button" />
        ),
      headerFormatter: () => (
        <span>
          Avaza Created <UpDownIcon width={14} height={14} />
        </span>
      ),
    },

    {
      dataField: "action",
      text: "Action",
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <span title="View">
              <EyeIcon
                className="icon icon-xs me-2"
                role="button"
                onClick={() => {
                  history.push(`/project_detail/${row.id}`);
                }}
              />
            </span>
          </div>
        );
      },
    },
    // {
    //   dataField: "leaves",
    //   text: "Annual Leaves",
    //   csvFormatter: (col) => (col ? col : "-"),
    //   formatter: (cellContent, row) => {
    //     return row?.leaves !== undefined
    //       ? row.leaves.annualUtilized + "/" + row.leaves.annualAllowed
    //       : "-";
    //   },
    // },
    // {
    //   dataField: "leaves",
    //   text: "Sick Leaves",
    //   csvFormatter: (col) => (col ? col : "-"),
    //   formatter: (cellContent, row) => {
    //     return row?.leaves !== undefined
    //       ? row.leaves.sickUtilized + "/" + row.leaves.sickAllowed
    //       : "-";
    //   },
    // },
    // {
    //   dataField: "leaves",
    //   text: "Casual Leaves",
    //   csvFormatter: (col) => (col ? col : "-"),
    //   formatter: (cellContent, row) => {
    //     return row?.leaves !== undefined
    //       ? row.leaves.casualUtilized + "/" + row.leaves.casualAllowed
    //       : "-";
    //   },
    // },
  ];

  useEffect(() => {
    // dispatch(getUserFun());
    dispatch(getProjectFun());
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="pt-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Projects</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>Projects List</h4>
            </div>
          </div>
        </div>
        <div className="flex">
          <span title="Sync Projects">
            <RefreshIcon
              style={{ height: "24px", width: "24px", marginRight: "12px" }}
              className={isLoading ? "animate-spin" : ""}
              onClick={isLoading ? null : handleSyncClick}
              disabled={isLoading}
            />
          </span>

          <Button
            variant="tertiary"
            size="sm"
            className="d-inline-flex align-items-center"
            onClick={openModal}
          >
            <PlusIcon className="icon icon-xs me-2" /> Create Project
          </Button>
          <AddProjectModal showModal={showModal} closeModal={closeModal} />
        </div>
      </div>

      <GlobleTable
        data={projects}
        columns={columns}
        totalEntries={projects?.length}
        totalPage={projects?.totalPages}
        tableType={"projects"}
        currentPage={projects?.currentPage}
      />
    </>
  );
};
