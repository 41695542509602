import moment from "moment-timezone";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CapitalizeFirstLetter } from "utils/Global";

export default function UserDetailedView({ obj }) {
  return (
    obj && (
      <>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Name: </b> {obj.name}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Email: </b> {obj.email}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Phone Number: </b> {obj.phone}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Designation: </b> {obj.designation}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Skills: </b>{" "}
          {obj.skillSet.length > 0
            ? obj.skillSet.map((val, i) => {
                return (
                  <span>
                    {val.label}
                    {i < obj.skillSet?.length - 1 ? ", " : ""}
                  </span>
                );
              })
            : "Not Assigned"}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Joining: </b>{" "}
          {moment(obj.joinDate).format("DD-MM-YYYY")}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Job Type: </b>{" "}
          {(obj &&
            obj.jobType &&
            CapitalizeFirstLetter(obj.jobType.replace(/_/g, " "))) ||
            ""}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Admin Account: </b>{" "}
          {obj.isAdmin ? "Yes" : "No"}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Supervisor Account: </b>{" "}
          {obj.isSupervisor ? "Yes" : "No"}
        </p>
        <p className="mb-2 small pe-3 text-gray-700">
          <b className="text-gray-800">Supervisors: </b>{" "}
          {obj.supervisors?.length > 0 ? (
            <table>
              <tbody>
                {obj.supervisors.map((val, i) => {
                  return (
                    <tr key={i}>
                      <td>{val.label}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            "Not Assigned"
          )}
        </p>
        {!obj.isAdmin && (
          <>
            <p className="mb-2 small pe-3 text-gray-700">
              <b className="text-gray-800">Hubstaff ID:</b>{" "}
              {obj.hubStaffId ? obj.hubStaffId : "Not Assigned"}
            </p>
            <p className="mb-2 small pe-3 text-gray-700">
              {" "}
              <b className="text-gray-800">Avaza ID:</b>{" "}
              {obj.avazaId ? obj.avazaId : "Not Assigned"}
            </p>
          </>
        )}
        {/*  */}
        {/* <hr />
        <h4 className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Leaves Status</b>
        </h4>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Annual Leaves: </b>{" "}
          {obj.leaves?.annualAllowed == 0
            ? "Not Available"
            : obj.leaves?.annualAllowed
            ? obj.leaves?.annualUtilized + "/" + obj.leaves?.annualAllowed
            : "Not Available"}
        </p>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Casual Leaves: </b>{" "}
          {obj.leaves?.casualAllowed
            ? obj.leaves?.casualUtilized + "/" + obj.leaves?.casualAllowed
            : "Not Available"}
        </p>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Sick Leaves: </b>{" "}
          {obj.leaves?.sickAllowed
            ? obj.leaves?.sickUtilized + "/" + obj.leaves?.sickAllowed
            : "Not Available"}
        </p> */}
        <div className="btn-toolbar mb-2 mb-md-0 float-end">
          <Button
            variant="secondary"
            size="sm"
            className="d-inline-flex align-items-center"
            as={Link}
            to={`/user_detail/${obj.email}`}
          >
            {" "}
            View More
          </Button>
        </div>
      </>
    )
  );
}
