import React, { useState } from "react";
import Select, { components } from "react-select";
import { CalendarIcon } from "@heroicons/react/solid";

const DateFilterMonth = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedDate(selectedOption);
   
  };

  const getCurrentYearMonths = () => {
    const months = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const newDate = new Date(currentDate.getFullYear(), i, 1);
      const month = newDate.toLocaleString("default", { month: "long" });
      const year = newDate.getFullYear();
      months.push({ value: `${month} ${year}`, label: `${month} ${year}` });
    }
    return months;
  };

  const months = getCurrentYearMonths();

  const DropdownIndicator = (props) => {
    const Icon = props.selectProps.indicatorIcon;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon width={20} height={20} />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div className="dropdown-container custom-dropdown-container">
      <p className="mb-0">Date</p>
      <div style={{ position: "relative" }}>
        <div style={{ position: "relative" }}>
          <Select
            className="custom-dropdown form-select"
            options={months}
            value={selectedDate}
            isSearchable={false}
            onChange={handleSelectChange}
            components={{ DropdownIndicator }}
            indicatorIcon={CalendarIcon}
            styles={{
              dropdownIndicator: (provided) => ({
                ...provided,
              }),
              indicatorSeparator: () => ({}),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DateFilterMonth;
