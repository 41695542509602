import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { HomeIcon, PlusIcon } from "@heroicons/react/solid";
import {
  Breadcrumb,
  Card,
  Form,
  Table,
  Button,
  Modal,
  Spinner,
  Col,
  Row,
  Tab,
  Nav,
  Container,
  Navbar,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CapitalizeFirstLetter } from "utils/Global";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams, Link } from "react-router-dom";
import { Routes } from "utils/routes";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { customSelectStyles } from "utils/Global";
import LeavesTable from "components/LeavesTable";
import moment from "moment-timezone";
import { getProjectFun } from "redux/projects/action";
import { getUserProjects, updateProjectMember } from "services/projectServices";
import { getUserByEmail } from "services/userServices";
import { updateLeavesAllowed } from "redux/requests/action";

const UserDetailsPage = () => {
  const params = useParams();
  let history = useHistory();
  const [userObj, setUserObj] = useState({});
  const tabs = ["Basic info", "Leave Statistics", "Project Details"];
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [userProjects, setUserProjects] = useState([]);
  const [tabValue, setTabValue] = useState("Basic info");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.allProjects);
  const [expanded, setExpanded] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedLeaveStatistics, setEditedLeaveStatistics] = useState({});
  const [leaveStatistics, setLeaveStatistics] = useState(
    userObj.leaveStatistics
  );

  const node = useRef();

  const openEditModal = () => {
    setEditedLeaveStatistics({ ...userObj.leaveStatistics });
    setShowEditModal(true);
  };

  const getProjectsOfUser = async () => {
    setLoading(true);
    const projects = await getUserProjects(userObj.email);
    setUserProjects(projects);
    setLoading(false);
  };

  const handleEditSave = () => {
    dispatch(updateLeavesAllowed(userObj.id, editedLeaveStatistics));
    getUser();
    setShowEditModal(false);
  };

  useEffect(() => {
    if (tabValue === "Project Details") {
      dispatch(getProjectFun());
      getProjectsOfUser();
    }
  }, [tabValue]);

  let projectArray = [];
  if (projects.length > 0) {
    projectArray = projects.map((project) => ({
      label: project.name,
      value: project.id,
      hubStaffId: project.hubStaffId,
      avazaId: project.avazaId,
    }));
  }

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const getUser = async () => {
    try {
      const user = await getUserByEmail(params.email);
      if (!user.length) {
        toast.error("User is not available on e-portal", {
          autoClose: 2000,
          onClose: () => history.goBack(),
        });
      } else {
        setUserObj(user[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleUnAssignClick = (project) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to unassign this project?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const SwalWithLoader = Swal.mixin({
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        SwalWithLoader.fire({
          title: "Processing",
          text: "Please wait...",
          icon: "info",
        });

        const reqObj = {
          hubStaff: project.hubStaffId
            ? {
                email: userObj.email,
                projectId: project.hubStaffId,
                userRole: "remove",
              }
            : {},
          avaza: {},
        };

        try {
          await updateProjectMember(reqObj, true);
          await getProjectsOfUser();

          SwalWithLoader.fire({
            icon: "success",
            title: "Unassign successful",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
        } catch (error) {
          SwalWithLoader.fire({
            icon: "error",
            title: "Error",
            text: "Failed to unassign the project",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const [showDefaultDetail, setShowDefaultDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDefaultDetail(false);
    setFilteredProjects([]);
    Formik.resetForm();
  };

  const formikSchema = Yup.object().shape({
    projects: Yup.array().min(1).required("Projects is required"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projects: filteredProjects,
    },
    validationSchema: formikSchema,
    onSubmit: async (values, action) => {
      const project = values.projects[0];
      const reqObj = {
        hubStaff: project.hubStaffId
          ? {
              email: userObj.email,
              projectId: project.hubStaffId,
              userRole: "user",
            }
          : {},
        avaza: {
          email: userObj.email,
          projectId: project.avazaId,
        },
      };

      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = "";
      isSubmit = await updateProjectMember(reqObj);
      await getProjectsOfUser();
      if (!isSubmit?.isSubmitButton) {
        setDisableSubmitButton(isSubmit?.isSubmitButton);
        handleCloseDetail();
      }
    },
  });

  const handleTabChange = (tab) => {
    setTabValue(tab);
    setLoading(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="pt-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} to={Routes.ListingView.path}>
              Users
            </Breadcrumb.Item>
            <Breadcrumb.Item>{userObj.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <Col xs={12} xl={12}>
          <Card className="p-3">
            <Tab.Container
              defaultActiveKey="Basic info"
              activeKey={tabValue}
              onSelect={handleTabChange}
            >
              <Row>
                <Col lg={12} className="d-lg-flex">
                  <div ref={node}>
                    <Navbar expand="lg" variant="light" expanded={expanded}>
                      <Container>
                        <Navbar.Toggle
                          aria-controls="responsive-navbar-nav"
                          onClick={() => setExpanded(!expanded)}
                        />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="me-auto nav-tabs">
                            {tabs.map((val, index) => (
                              <Nav.Item key={index}>
                                <Nav.Link
                                  eventKey={val}
                                  onClick={() => {
                                    setTabValue(val);
                                    setExpanded(false);
                                  }}
                                  className="mb-sm-3 mb-md-0"
                                >
                                  {CapitalizeFirstLetter(val)}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>
                </Col>
                {Object.keys(userObj).length ? (
                  <Col lg={12}>
                    <Tab.Content>
                      {tabs.map((val, index) => {
                        return (
                          <Tab.Pane key={index} eventKey={val} className="py-0">
                            {loading && tabValue === val ? (
                              <div className="d-flex justify-content-center align-items-center my-4">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              <>
                                {tabValue === "Basic info" && (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">Name: </b>{" "}
                                        {userObj?.name}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">Email: </b>{" "}
                                        {userObj?.email}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Phone Number:{" "}
                                        </b>{" "}
                                        {userObj?.phone}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">CNIC: </b>{" "}
                                        {userObj?.cnic}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Date of Birth:{" "}
                                        </b>{" "}
                                        {moment(userObj?.dob).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Designation:{" "}
                                        </b>{" "}
                                        {userObj?.designation}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Address:{" "}
                                        </b>{" "}
                                        {userObj?.address}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Gender:{" "}
                                        </b>
                                        {CapitalizeFirstLetter(userObj?.gender)}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Date of Joining:{" "}
                                        </b>{" "}
                                        {moment(userObj?.joinDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Admin Account:{" "}
                                        </b>{" "}
                                        {userObj?.isAdmin ? "Yes" : "No"}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Supervisor Account:{" "}
                                        </b>{" "}
                                        {userObj?.isSupervisor ? "Yes" : "No"}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Account Status:{" "}
                                        </b>{" "}
                                        {userObj?.isActive
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Job Type:{" "}
                                        </b>{" "}
                                        {CapitalizeFirstLetter(
                                          userObj?.jobType?.replace(/_/g, " ")
                                        )}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Supervisors:{" "}
                                        </b>{" "}
                                        {userObj?.supervisors?.length > 0 ? (
                                          <table>
                                            <tbody>
                                              {userObj?.supervisors.map(
                                                (val, i) => {
                                                  return (
                                                    <tr key={i}>
                                                      <td>{val.label}</td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        ) : (
                                          "Not Assigned"
                                        )}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Skill Set:{" "}
                                        </b>{" "}
                                        {userObj?.skillSet?.length > 0 ? (
                                          <table>
                                            <tbody>
                                              {userObj?.skillSet.map(
                                                (val, i) => {
                                                  return (
                                                    <tr key={i}>
                                                      <td>{val.label}</td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        ) : (
                                          "Not Assigned"
                                        )}
                                      </p>

                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Hubstaff ID:
                                        </b>
                                        {userObj.hubStaffId
                                          ? userObj.hubStaffId
                                          : "Not Assigned"}
                                      </p>
                                      <p className="mb-2 small pe-3 text-gray-700">
                                        <b className="text-gray-800">
                                          Avaza ID:
                                        </b>
                                        {userObj.avazaId
                                          ? userObj.avazaId
                                          : "Not Assigned"}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {tabValue === "Leave Statistics" && (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        className="btn btn-primary my-2"
                                        onClick={openEditModal}
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                    <LeavesTable
                                      leaves={
                                        leaveStatistics ||
                                        userObj.leaveStatistics
                                      }
                                    />
                                  </>
                                )}
                                <Modal
                                  show={showEditModal}
                                  onHide={() => setShowEditModal(false)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Edit Leave Statistics
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {/* Add the form or content for editing the user here */}
                                    <Form.Group className="mb-3">
                                      <Form.Label>Casual Allowed</Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={
                                          editedLeaveStatistics.casualAllowed
                                        }
                                        onChange={(e) => {
                                          const value = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          if (value >= 0) {
                                            setEditedLeaveStatistics({
                                              ...editedLeaveStatistics,
                                              casualAllowed: value,
                                            });
                                          }
                                        }}
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Sick Allowed</Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={
                                          editedLeaveStatistics.sickAllowed
                                        }
                                        onChange={(e) => {
                                          const value = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          if (value >= 0) {
                                            setEditedLeaveStatistics({
                                              ...editedLeaveStatistics,
                                              sickAllowed: value,
                                            });
                                          }
                                        }}
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Annual Allowed</Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={
                                          editedLeaveStatistics.annualAllowed
                                        }
                                        onChange={(e) => {
                                          const value = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          if (value >= 0) {
                                            setEditedLeaveStatistics({
                                              ...editedLeaveStatistics,
                                              annualAllowed: value,
                                            });
                                          }
                                        }}
                                      />
                                    </Form.Group>
                                  </Modal.Body>
                                  <Modal.Footer className="d-flex justify-content-between">
                                    <Button
                                      variant="secondary"
                                      onClick={handleEditSave}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      variant="link"
                                      onClick={() => setShowEditModal(false)}
                                    >
                                      Close
                                    </Button>
                                  </Modal.Footer>
                                </Modal>

                                {tabValue === "Project Details" && (
                                  <>
                                    <div className="btn-toolbar d-flex justify-content-end">
                                      <Button
                                        style={{
                                          position: "absolute",
                                          top: "52px",
                                        }}
                                        variant="tertiary"
                                        size="sm"
                                        className="d-inline-flex align-items-center"
                                        onClick={() => {
                                          setShowDefaultDetail(true);
                                        }}
                                      >
                                        <PlusIcon className="icon icon-xs me-2" />
                                        Assign Project
                                      </Button>
                                    </div>
                                    <div style={{ minHeight: "500px" }}>
                                      <Table striped bordered responsive>
                                        <thead>
                                          <tr>
                                            <td>
                                              <strong>NAME</strong>
                                            </td>
                                            <td>
                                              <strong>HUBSTAFF ASSIGNED</strong>
                                            </td>
                                            <td>
                                              <strong>AVAZA ASSIGNED</strong>
                                            </td>
                                            {/* <td>
                                            <strong>ACTION</strong>
                                          </td> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userProjects &&
                                            userProjects.map(
                                              (project, index) => (
                                                <tr
                                                  key={index}
                                                  className="table-row"
                                                >
                                                  <td
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    {project.name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    {project.user
                                                      ?.hubStaffId ? (
                                                      <CheckIcon className="icon icon-xs me-3" />
                                                    ) : (
                                                      <XIcon className="icon icon-xs" />
                                                    )}
                                                  </td>

                                                  <td
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    {project.user?.avazaId ? (
                                                      <CheckIcon className="icon icon-xs me-3" />
                                                    ) : (
                                                      <XIcon className="icon icon-xs" />
                                                    )}
                                                  </td>
                                                  {/* <td>
                                                {project.user?.hubStaffId && (
                                                  <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="btn-danger mb-2 mb-md-0"
                                                    onClick={() =>
                                                      handleUnAssignClick(
                                                        project
                                                      )
                                                    }
                                                  >
                                                    Unassign
                                                  </Button>
                                                )}
                                              </td> */}
                                                </tr>
                                              )
                                            )}
                                        </tbody>
                                      </Table>
                                    </div>
                                    <Modal
                                      as={Modal.Dialog}
                                      centered
                                      show={showDefaultDetail}
                                      onHide={handleCloseDetail}
                                    >
                                      <Modal.Header>
                                        <Modal.Title className="h6">
                                          Assign Projects
                                        </Modal.Title>
                                        <Button
                                          variant="close"
                                          aria-label="Close"
                                          onClick={handleCloseDetail}
                                        />
                                      </Modal.Header>
                                      <Form
                                        onSubmit={Formik.handleSubmit}
                                        className="mt-0 signUp_Form"
                                      >
                                        <Modal.Body>
                                          <Form.Group
                                            id="select_user"
                                            className="mb-3"
                                          >
                                            <Form.Label>User</Form.Label>
                                            <Form.Control
                                              name="user"
                                              disabled
                                              placeholder={
                                                userObj.name
                                                  ? userObj.name
                                                  : userObj.email
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            id="select_user"
                                            className="mb-3"
                                          >
                                            <Form.Label>
                                              Select Projects
                                            </Form.Label>
                                            <ReactSelect
                                              options={projectArray}
                                              value={filteredProjects}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#61DAFB",
                                                  primary: "#61DAFB",
                                                },
                                              })}
                                              styles={customSelectStyles}
                                              onChange={(obj) => {
                                                setFilteredProjects([obj]);
                                              }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {Formik?.errors?.projects
                                                ? "Projects is required"
                                                : ""}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="secondary"
                                            type="submit"
                                            disabled={disableSubmitButton}
                                          >
                                            {disableSubmitButton ? (
                                              <Spinner
                                                animation="border"
                                                size="sm"
                                              />
                                            ) : (
                                              "Save Changes"
                                            )}
                                          </Button>
                                          <Button
                                            variant="link"
                                            className="text-gray ms-auto"
                                            onClick={handleCloseDetail}
                                          >
                                            Close
                                          </Button>
                                        </Modal.Footer>
                                      </Form>
                                    </Modal>
                                  </>
                                )}
                              </>
                            )}
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                ) : (
                  <Col lg={12} className="text-center">
                    <Spinner animation="border" className="mt-4" />
                  </Col>
                )}
              </Row>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserDetailsPage;
