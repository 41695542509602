import * as types from "./contants";

const initialState = {
  allUsers: [],
  data: [],
};

export default function allUser(state = initialState, action) {
  switch (action.type) {
    case types.GET_User: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }
    // case types.GET_USER_BY_ID: {
    //   return {
    //     ...state,
    //     user: action.payload,
    //   };
    // }
    case types.EDIT_USER:
    case types.UPDATE_HUBSTAFF_AWAZA: {
      return {
        ...state,
        allUsers: state.allUsers.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    }
    case types.TOGGLE_USER_ACCOUNT_STATUS:
    default:
      return state;
  }
}
