import ReportTablePagination from "components/ReportTablePagination";
import React from "react";
import TableData from "../../../Data/TableData.json";
import "../../../scss/managementlevel/note.scss";
import DateFilter from "components/DateFilter";
//import reportCallback from "../../../assets/img/report-callback.svg";

function NoteComponent() {
  // if (Object.values(TableData.data_1).length === 0) {
  return (
    <div>
      <div className="d-flex justify-content-Start mt-2">
        <DateFilter />
      </div>
      <div className="mt-3 report-table-container">
        <ReportTablePagination
          columns={TableData.columns_3}
          data={TableData.data_3}
        />
      </div>
      <div className="my-3 report-table-container">
        <p className="fw-bolder">Avaza Notes</p>
        <ReportTablePagination
          columns={TableData.columns_4}
          data={TableData.data_4}
        />
      </div>
      <div className="my-3 report-table-container">
        <p className="fw-bolder">Utilization</p>
        <ReportTablePagination
          columns={TableData.columns_5}
          data={TableData.data_5}
        />
      </div>
    </div>
  );
  // } else {
  //   return (
  //     <div>
  //       <div className="d-flex justify-content-Start mt-2">
  //         <DateFilter />
  //       </div>
  //       <br />
  //       <div class="pt-7 m-auto text-center">
  //         <img
  //           style={{ maxWidth: "350px", width: "100%" }}
  //           src={reportCallback}
  //         />
  //         <br />
  //         <p class="mt-2">Select supervisors & employees and apply filters!</p>
  //       </div>
  //     </div>
  //   );
  // }
}
export default NoteComponent;
