import customAxios from "config";
import { toast } from "react-toastify";

export const getUserByEmail = async (email) =>  {
    try {
      const response = await customAxios.get(`users/?email=${email}`);
      return response.data
    } catch (err) {
        toast.error(err.response.data.detail);
      }
  };