import React from "react";
import { MenuAlt1Icon, SupportIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import {
  Nav,
  Image,
  Button,
  Navbar,
  Dropdown,
  Container,
} from "react-bootstrap";

import Profile3 from "assets/img/team/profile-picture.png";
import { logoutFun } from "redux/auth/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default (props) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const toggleContracted = () =>
    props.toggleContracted && props.toggleContracted();

  const handleLogOut = () => {
    dispatch(logoutFun(userDetail?.refresh));
  };

  return (
    <Navbar
      expand
      variant="dark"
      className="navbar-top navbar-dashboard ps-0 pe-2 pb-0"
    >
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
          </div>
          <Nav className="align-items-center d-none d-md-block">
            <Dropdown as={Nav.Item} className="ms-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Profile3} className="avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold text-gray-900">
                      {userDetail.name ? userDetail.name : userDetail.email}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
                <Dropdown.Item className="d-flex align-items-center">
                  <SupportIcon className="dropdown-icon text-gray-400 me-2" />{" "}
                  Support
                </Dropdown.Item>
                <Dropdown.Divider as="div" className="my-1" />
                <Dropdown.Item
                  className="d-flex align-items-center"
                  onClick={() => handleLogOut()}
                >
                  <LogoutIcon className="dropdown-icon text-danger me-2" />{" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
