import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Styles/LeavesTable.css";

export default function LeavesTable({ leaves }) {
  return (
    <Row>
      <Col lg={12} className="tableContainer">
        {leaves && (
          <table className="leavesTable">
            <thead>
              <tr>
                <th className="typeHeading">Type</th>
                <th className="leaveAvailable">Allowed</th>
                <th className="leaveUtilized">Utilized</th>
                <th className="leaveRemaining">Remaining</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="verticalHeader">Casual</th>
                <td>{leaves.casualAllowed}</td>
                <td>{leaves.casualUtilized}</td>
                <td>
                  {parseFloat(leaves.casualAllowed) -
                    parseFloat(leaves.casualUtilized)}
                </td>
              </tr>
              <tr>
                <th className="verticalHeader">Sick</th>
                <td>{leaves.sickAllowed}</td>
                <td>{leaves.sickUtilized}</td>
                <td>
                  {parseFloat(leaves.sickAllowed) -
                    parseFloat(leaves.sickUtilized)}
                </td>
              </tr>
              <tr>
                <th className="verticalHeader">Annual</th>
                <td>{leaves.annualAllowed}</td>
                <td>{leaves.annualUtilized}</td>
                <td>
                  {parseFloat(leaves.annualAllowed) -
                    parseFloat(leaves.annualUtilized)}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Col>
    </Row>
  );
}
