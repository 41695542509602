import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";

const localFirebaseConfig = {
  apiKey: "AIzaSyBXqCHnxgEZHLUXg9HApxgVwqqtDEK76ZY",
  authDomain: "leave-management-3c7fd.firebaseapp.com",
  projectId: "leave-management-3c7fd",
  storageBucket: "leave-management-3c7fd.appspot.com",
  messagingSenderId: "342421196875",
  appId: "1:342421196875:web:6abe833de0e1e6281a3dce",
  measurementId: "G-9KY7994ZF9",
};

const liveFirebaseConfig = {
  apiKey: "AIzaSyA3pEr6OOi03QQqRys8Npi3s0hKwxwyvKY",
  authDomain: "eportal-decklaration.firebaseapp.com",
  projectId: "eportal-decklaration",
  storageBucket: "eportal-decklaration.appspot.com",
  messagingSenderId: "923023811361",
  appId: "1:923023811361:web:3ca7fef6b15686a4161460",
  measurementId: "G-DYRQJLGN6M",
};

const app = initializeApp(localFirebaseConfig);
// const app = initializeApp(liveFirebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
