export const GET_All_REQ = "GET_All_REQ";
export const EDIT_REQUEST = "EDIT_REQUEST";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const EDIT_DESIGNATION = "EDIT_DESIGNATION";

export const UPDATE_LEAVES_ALLOWED = "UPDATE_LEAVES_ALLOWED";

export const GET_ALL_PUBLIC_HOLIDAYS = "GET_ALL_PUBLIC_HOLIDAYS";
export const ADD_PUBLIC_HOLIDAY = "ADD_PUBLIC_HOLIDAY";

export const UPDATE_PUBLIC_HOLIDAY = "UPDATE_PUBLIC_HOLIDAY";
export const DELETE_PUBLIC_HOLIDAY = "DELETE_PUBLIC_HOLIDAY";
