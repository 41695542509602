import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Routes } from "./routes";

const PublicRoute = ({ component: Component, restricted, ...props }) => {
  const userDetail = useSelector((state) => state.auth);
  let history = useHistory();
  useEffect(() => {
    let address = window.location.pathname.toString();
    if (!userDetail.token) {
      if (address.includes("/reset-password")) {
        return true;
      } else {
        return history.push("/");
      }
    }
  }, [userDetail]);
  return (
    <Route
      {...props}
      render={(props) =>
        userDetail.token ? (
          <Redirect
            to={
              userDetail?.isAdmin
                ? Routes.ReqListing.path
                : Routes.UserDashboard.path
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
