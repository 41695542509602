import React from "react";
import { Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Routes } from "utils/routes";

import PublicRoute from "utils/PublicRoute";
import PrivateRoute from "utils/PrivateRoute";
import UserListing from "pages/Users/UserListing";
import ReqListing from "pages/Requests/ReqListing";
import Signin from "pages/auth/Signin";
import NotFoundPage from "pages/auth/NotFound";
// import Signup from "pages/auth/Signup";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import Settings from "pages/settings/Profile_view";
import { useSelector } from "react-redux";
import UserDashboard from "../pages/UserDashboard/UserDashboard";
import UserDetailsPage from "pages/Users/UserDetailsPage";
import ProjectListing from "pages/Project/ProjectListing";
import ProjectDetailPage from "pages/Project/ProjectDetailPage";
import FinancialLevel from "pages/Reports/FinanceLevel/FinancialLevel";
import ManagementLevel from "pages/Reports/ManagementLevel/ManagementLevel";
import AssociateLevel from "pages/Reports/AssociateLevel/AssociateLevel";

export default () => {
  const userDetail = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={Routes.Signin.path} component={Signin} />
        {/* <PublicRoute exact path={Routes.Signup.path} component={Signup} /> */}
        <PublicRoute
          exact
          path={Routes.ForgotPassword.path}
          component={ForgotPassword}
        />
        <PublicRoute
          exact
          path={Routes.ResetPassword.path}
          component={ResetPassword}
        />

        {userDetail.isAdmin || userDetail.isSupervisor ? (
          <PrivateRoute
            exact
            path={Routes.ListingView.path}
            component={UserListing}
          />
        ) : null}
        {!userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.UserDashboard.path}
            component={UserDashboard}
          />
        )}

        <PrivateRoute
          exact
          path={Routes.ReqListing.path}
          component={ReqListing}
        />
        {userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.Project.path}
            component={ProjectListing}
          />
        )}
        {(userDetail.isAdmin || userDetail?.isSupervisor) && (
          <PrivateRoute
            exact
            path={Routes.Management.path}
            component={ManagementLevel}
          />
        )}
        {userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.Finance.path}
            component={FinancialLevel}
          />
        )}
        {!userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.Associate.path}
            component={AssociateLevel}
          />
        )}
        {(userDetail.isAdmin || userDetail?.isSupervisor) && (
          <PrivateRoute
            exact
            path={Routes.UserDetail.path}
            component={UserDetailsPage}
          />
        )}
        {userDetail.isAdmin && (
          <PrivateRoute
            exact
            path={Routes.ProjectDetail.path}
            component={ProjectDetailPage}
          />
        )}
        <PrivateRoute exact path={Routes.Settings.path} component={Settings} />

        <PublicRoute
          exact
          path={Routes.NotFound.path}
          component={NotFoundPage}
        />

        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </BrowserRouter>
  );
};
