import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase-config";

//Signin Fuction
export const loginFun = (data, history) => async (dispatch) => {
  try {
    const response = await customAxios.post("auth/login/", data);
    dispatch({
      type: types.AUTH,
      payload: response.data,
    });
    history.push("/");
    toast.success("Successfully Login");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Login Function
// export const loginFun = (data, history) => async (dispatch) => {
//   try {
//     const res = await signInWithEmailAndPassword(
//       auth,
//       data.username,
//       data.password
//     );
//     const docRef = doc(db, "users", res.user.uid);
//     const docSnap = await getDoc(docRef);
//     const userDoc = docSnap.data();

//     if (!userDoc.isActive) {
//       toast.error("Your account is deactivated.");
//       return { isSubmitButton: false };
//     }

//     const parsed_data = {
//       token: res.user.accessToken,
//       refresh: res.user.stsTokenManager.refreshToken,
//       name: userDoc?.name,
//       email: res.user.email,
//       isAdmin: userDoc?.isAdmin,
//       isSuperAdmin: userDoc?.isSuperAdmin,
//       isSupervisor: userDoc?.isSupervisor,
//       isActive: userDoc.isActive,
//       id: res.user.uid,
//       designation: userDoc.designation,
//       leaves: userDoc.leaves,
//       joinDate: userDoc.joinDate,
//       jobType: userDoc.jobType,
//       supervisors: userDoc.supervisors,
//     };

//     dispatch({
//       type: types.AUTH,
//       payload: parsed_data,
//     });

//     history.push("/");
//     toast.success("Successfully Login");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message.replace("Firebase: ", ""));
//     return { isSubmitButton: false };
//   }
// };

// export const refreshUserInfo = (id) => async (dispatch) => {
//   try {
//     const docRef = doc(db, "users", id);
//     const docSnap = await getDoc(docRef);
//     const userDoc = docSnap.data();
//     const parsed_data = {
//       name: userDoc?.name,
//       email: userDoc?.email,
//       isAdmin: userDoc?.isAdmin,
//       isSuperAdmin: userDoc?.isSuperAdmin,
//       isSupervisor: userDoc?.isSupervisor,
//       isActive: userDoc.isActive,
//       designation: userDoc.designation,
//       leaves: userDoc.leaves,
//       joinDate: userDoc.joinDate,
//       jobType: userDoc.jobType,
//       supervisors: userDoc.supervisors,
//     };

//     dispatch({
//       type: types.REFRESH_PROFILE,
//       payload: parsed_data,
//     });

//     return { isSubmitButton: false };
//   } catch (error) {
//     return { isSubmitButton: false };
//   }
// };

// // Logout Function
export const logoutFun =
  (token, unauthorized = false) =>
  async (dispatch) => {
    try {
      await signOut(auth);
      dispatch({
        type: types.AUTH_LOGOUT,
        payload: null,
      });

      if (!unauthorized) {
        toast.success("Logout Successfully");
      }

      return { isSubmitButton: false };
    } catch (error) {
      toast.error(error.message);
      return { isSubmitButton: false };
    }
  };

// // Registration Function
// export const registerFun = (data, history) => async (dispatch) => {
//   try {
//     const response = await customAxios.post("auth/register/", data);
//     dispatch({
//       type: types.AUTH,
//       payload: response.data,
//     });
//     history.push("/");
//     toast.success("Successfully Registered 🎉");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.response.data.detail);
//     return { isSubmitButton: false };
//   }
// };

// // Registration Function
// export const profileUpdateFun = (data) => async (dispatch) => {
//   try {
//     const response = await customAxios.patch("users/profile/update/", data);
//     dispatch({
//       type: types.ACC_SETUP,
//       payload: response.data,
//     });
//     toast.success("Successfully Updated");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error?.response?.data?.detail);
//     return { isSubmitButton: false };
//   }
// };

// Registration Function

export const profileUpdateFun = (data) => async (dispatch) => {
  try {
    const response = await customAxios.patch("profile/update/", data);
    dispatch({
      type: types.ACC_SETUP,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

export const refreshUserInfoFun = () => async (dispatch) => {
  try {
    const response = await customAxios.get("profile/");
    dispatch({
      type: types.ACC_SETUP,
      payload: response.data,
    });
    return { isSubmitButton: false };
  } catch (error) {
    return { isSubmitButton: false };
  }
};

// Forget Password Function
export const forgetFun = (data, history) => async (dispatch) => {
  try {
    const response = await customAxios.post("auth/forget/", data);
    toast.success("Forget password request sent on the given email");
    history.push("/");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

// Reset Password Function
export const resetFun = (data, token, history) => async (dispatch) => {
  try {
    customAxios.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    const response = await customAxios.patch("auth/reset/", data);
    toast.success(response.data.detail);
    history.push("/");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

export const logoutIfAccountDeactivated = (uid) => async (dispatch) => {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  const userDoc = docSnap.data();

  if (userDoc && !userDoc.isActive) {
    await dispatch({
      type: types.AUTH_LOGOUT,
      payload: null,
    });
    toast.error("Your account is deactivated.");
    return { isSubmitButton: false };
  }
};

// Update Password
export const changePasswordFun = (data) => async (dispatch) => {
  try {
    await customAxios.patch("profile/change-password/", data);
    toast.success("Password updated successfully");
  } catch (error) {
    toast.error(error.response.data.detail);
  }
};
