import React, { useState } from "react";
import { LockClosedIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { resetFun } from "redux/auth/action";
import { useHistory, useParams } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams();
  const FormikSchema = Yup.object().shape({
    password: Yup.string()
      .trim()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    confirm_password: Yup.string()
      .trim()
      .required("Confirm password is required")
      .min(6, "Confirm password is too short - should be 6 chars minimum")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: FormikSchema,
    onSubmit: async (values, action) => {
      let payload = {
        password: values.password,
      };
      setDisableSubmitButton(true);
      let isSubmit = await dispatch(resetFun(payload, params?.token, history));
      setDisableSubmitButton(isSubmit.isSubmitButton);
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={Formik.handleSubmit} className="signUp_Form">
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={Formik.values.password}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        placeholder="New Password"
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <EyeIcon className="icon icon-xs  text-gray-600" />
                        ) : (
                          <EyeOffIcon className="icon icon-xs text-gray-600" />
                        )}
                      </InputGroup.Text>
                      {Formik.touched.password && Formik.errors.password && (
                        <Form.Control.Feedback type="invalid">
                          {Formik.errors.password}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirm_password" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirm_password"
                        value={Formik.values.confirm_password}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        placeholder="Confirm Password"
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      >
                        {showConfirmPassword ? (
                          <EyeIcon className="icon icon-xs  text-gray-600" />
                        ) : (
                          <EyeOffIcon className="icon icon-xs text-gray-600" />
                        )}
                      </InputGroup.Text>
                      {Formik.touched.confirm_password &&
                        !Formik.errors.password &&
                        Formik.errors.confirm_password && (
                          <Form.Control.Feedback type="invalid">
                            {Formik.errors.confirm_password}
                          </Form.Control.Feedback>
                        )}
                    </InputGroup>
                  </Form.Group>

                  <div className="d-grid">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={disableSubmitButton}
                    >
                      {disableSubmitButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Reset password"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
