import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card, Button, Form } from "react-bootstrap";
import Table from "react-bootstrap-table-next";
import Datetime from "react-datetime";
import Pagination, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { CloudDownloadIcon } from "@heroicons/react/solid";
import { AiFillCloseCircle } from "react-icons/ai";
import { getReqFun } from "redux/requests/action";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";

export default ({ data, columns, parentComponent, filters, totalPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTable, setSearchTable] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const dispatch = useDispatch();
  const { ExportCSVButton } = CSVExport;
  const customTotal = ({ page, sizePerPage }) => (
    <div>
      Showing {sizePerPage * page - 9} to {sizePerPage * page} of entries
    </div>
  );
  let pageArr = [];
  for (let i = 1; i <= totalPage; i++) {
    pageArr.push(i);
  }
  const customSizePerPage = (props) => {
    const { nextPageText, prePageText } = props;
    let pageArr = [];
    for (let i = 1; i <= totalPage; i++) {
      pageArr.push(i);
    }

    const onPageChanged = async (value) => {
      if (value <= totalPage && value !== 0) {
        setDisableSubmitButton(!disableSubmitButton);
        const isSubmit = await dispatch(
          getReqFun(value, searchTable, selectedOption, selectedDate)
        );
        if (!isSubmit?.isSubmitButton) {
          setDisableSubmitButton(isSubmit?.isSubmitButton);
          setCurrentPage(value);
        }
      }
    };

    const renderPageButtons = () => {
      const currentPageIndex = pageArr.indexOf(currentPage);
      const startIndex = currentPageIndex - 5 < 0 ? 0 : currentPageIndex - 5;
      const endIndex =
        startIndex + 10 < pageArr.length ? startIndex + 10 : pageArr.length;
      return pageArr.slice(startIndex, endIndex).map((val) => {
        return (
          <li
            className={`${
              val === Number(currentPage) ? "active" : ""
            } page-item mx-1`}
            key={val}
          >
            <Button
              variant="white"
              disabled={disableSubmitButton}
              onClick={() => {
                onPageChanged(val);
                setCurrentPage(val); // Update currentPage state
              }}
              className="page-link"
            >
              {val}
            </Button>
          </li>
        );
      });
    };

    return (
      <Row as="label">
        <Col xs="auto" style={{ zIndex: 0 }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  variant="white"
                  className="page-link me-1"
                  disabled={disableSubmitButton || currentPage === 1}
                  onClick={() => {
                    onPageChanged(Number(currentPage) - 1);
                  }}
                >
                  {prePageText}
                </Button>
              </li>
              {renderPageButtons()}
              {totalPage > 10 && (
                <li className="page-item">
                  <Button
                    variant="white"
                    className="page-link ms-1"
                    disabled={disableSubmitButton || currentPage === totalPage}
                    onClick={() => {
                      onPageChanged(Number(currentPage) + 1);
                      setCurrentPage(Number(currentPage) + 1); // Update currentPage state
                    }}
                  >
                    {nextPageText}
                  </Button>
                </li>
              )}
            </ul>
          </nav>
        </Col>
      </Row>
    );
  };

  const clearDate = () => {
    setSelectedDate(null);
    dispatch(getReqFun(1, searchTable, selectedOption)); // Dispatch action to fetch data with empty date filter
  };
  const onSearchTable = async (val) => {
    if (val === searchTable) {
      return 0;
    }
    setSearchTable(val);
    const isSubmit = await dispatch(getReqFun(1, searchTable));
  };
  const onDateChange = async (val) => {
    if (val) {
      const formattedDate = val.format("YYYY-MM-DD");
      setSelectedDate(formattedDate);
      const isSubmit = await dispatch(
        getReqFun(1, searchTable, selectedOption, formattedDate)
      );
    }
  };
  const onSelectStatus = async (value) => {
    setSelectedOption(value.value);
    if (value.value !== "all") {
      const isSubmit = await dispatch(getReqFun(1, searchTable, value.value));
    } else {
      setSelectedOption("");
      const isSubmit = await dispatch(getReqFun(1));
    }
  };
  useEffect(() => {
    if (!Array.isArray(data)) {
      data = [];
    }
    setTableData(data);
  }, [data]);

  return (
    <>
      <ToolkitProvider
        keyField="id"
        search={true}
        columns={columns}
        data={tableData}
      >
        {({ baseProps, searchProps, csvProps }) => (
          <PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: tableData?.length,
              withFirstAndLast: false,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <Card>
                <div className="table-responsive py-4">
                  <div className="dataTable-top">
                    <div className="d-flex flex-column flex-md-row">
                      <div className="dataTable-search mb-2 mb-md-0">
                        <Search.SearchBar
                          srText="Search this table"
                          {...searchProps}
                          delay={2}
                          onSearch={(searchText) => {
                            onSearchTable(searchText);
                          }}
                          searchText={searchTable}
                        />
                      </div>

                      {parentComponent === "requestListing" && (
                        <div className="mb-2 mb-md-0 ms-md-4">
                          <Form.Group style={{ width: "150px" }}>
                            <Form.Label className="mb-0">Status</Form.Label>
                            <ReactSelect
                              isSearchable={false}
                              options={filters.statusFilter}
                              value={filters.statusFilter.find(
                                (item) => item.value === selectedOption
                              )}
                              defaultValue={{ value: "all", label: "All" }}
                              onChange={onSelectStatus}
                            />
                          </Form.Group>
                        </div>
                      )}
                      {parentComponent === "requestListing" && (
                        <>
                          <div className="mb-2 mb-md-0 ms-md-4">
                            <Form.Group>
                              <Form.Label className="mb-0">Date</Form.Label>
                              <div className="d-flex align-items-center">
                                {" "}
                                {/* Flex container */}
                                <Datetime
                                  selected={selectedDate}
                                  render={(props) => {
                                    return <input {...props} />;
                                  }}
                                  renderInput={(props) => {
                                    function clear() {
                                      props.onChange({ target: { value: "" } });
                                      clearDate();
                                    }
                                    return (
                                      <div className="d-flex justify-content-center align-content-center">
                                        <input {...props} />
                                        {selectedDate && (
                                          <button
                                            title="clear date"
                                            onClick={clear}
                                            className="btn"
                                            type="button"
                                          >
                                            {" "}
                                            <AiFillCloseCircle />
                                          </button>
                                        )}
                                      </div>
                                    );
                                  }}
                                  dateFormat="DD-MM-YYYY"
                                  onChange={onDateChange}
                                  timeFormat={false}
                                  closeOnSelect
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </>
                      )}
                    </div>
                    <ExportCSVButton {...csvProps}>
                      <Button variant="primary" className="m-1">
                        Download{" "}
                        <CloudDownloadIcon className="icon icon-xs ms-1" />
                      </Button>
                    </ExportCSVButton>
                  </div>

                  <div style={{ minHeight: "150px" }}>
                    <Table
                      {...baseProps}
                      {...paginationTableProps}
                      headerWrapperClasses="thead-light"
                      bodyClasses="border-0"
                      rowClasses="border-bottom"
                      classes="table-flush dataTable-table"
                    />
                  </div>
                  <div className="dataTable-bottom">
                    <div className="dataTable-pagination me-3">
                      {customSizePerPage(paginationProps)}
                    </div>
                    <div className="dataTable-info">
                      {customTotal(paginationProps)}
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
    </>
  );
};
