import React, { useEffect} from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb, } from "react-bootstrap";
import { useSelector } from "react-redux";
import { refreshUserInfoFun } from "redux/auth/action";
import { useDispatch } from "react-redux";
import LeavesTable from "components/LeavesTable";

const UserDashboard = () => {
  const leavesData = useSelector((state) => state.auth.leaveStatistics);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUserInfoFun());
  }, []);

  // useEffect(() => {
  //   if (
  //     (!authen.isSuperAdmin &&
  //       authen.leaves.year !== new Date().getFullYear()) ||
  //     (!authen.isSuperAdmin &&
  //       authen.jobType === "intern" &&
  //       authen.leaves.month !== moment().format("MM-YYYY"))
  //   ) {
  //     dispatch(
  //       resetLeaves(
  //         leavesCalc({
  //           values: authen,
  //           isAnnualAllowed: true,
  //           setUtilizedToZero: true,
  //         }),
  //         authen.id
  //       )
  //     );
  //     setGetData(!getData);
  //   }
  //   // if (!authen.isSuperAdmin) dispatch(logoutIfAccountDeactivated(authen.id));
  // }, [authen]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="pt-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>Dashboard</h4>
            </div>
          </div>
        </div>
      </div>
      <LeavesTable leaves={leavesData} />
    </>
  );
};

export default UserDashboard;
