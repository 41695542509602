import * as types from "./constants";
import { toast } from "react-toastify";
import customAxios from "../../config/index";

export const getAllSupervisors = () => async (dispatch) => {
  try {
    const response = await customAxios.get("/users/get-all-supervisors/");
    dispatch({
      type: types.GET_ALL_SUPERVISORS,
      payload: response.data,
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const getTeamMembers = (supervisorId) => async (dispatch) => {
  try {
    const response = await customAxios.get(
      `/users/get-team-members/?supervisor_id=${supervisorId}`
    );
    dispatch({
      type: types.GET_TEAM_MEMBERS,
      payload: response.data.data,
    });
  } catch (error) {
    toast.error(error.message);
  }
};
