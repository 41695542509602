import * as types from "./contants";
import { toast } from "react-toastify";
import customAxios from "config";

// Get Department Function
export const getReqFun =
  (page_number, user_name, leave_status, leave_date) => async (dispatch) => {
    try {
      const params = { page_number };
      if (user_name) {
        params.user_name = user_name;
      }
      if (leave_status) {
        params.leave_status = leave_status;
      }
      if (leave_date) {
        params.leave_date = leave_date;
      }
      const response = await customAxios.get("/leaves/", {
        params,
      });
      const data = response.data;

      dispatch({
        type: types.GET_All_REQ,
        payload: {
          data: data,
        },
      });
      return { isSubmitButton: false };
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      return { isSubmitButton: false };
    }
  };

// // Get Department Function
// export const getReqFun = (user) => async (dispatch) => {
//   try {
//     const requestsCollectionRef = collection(db, "requests");
//     const q = query(requestsCollectionRef, where("userId", "==", user.id));
//     const data = await getDocs(
//       user.isAdmin || user.isSupervisor ? requestsCollectionRef : q
//     );

//     let users_data = null;
//     if (user.isAdmin || user.isSupervisor) {
//       const usersCollectionRef = collection(db, "users");
//       const users = await getDocs(usersCollectionRef);
//       users_data = users.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
//     }

//     const filteredReq = user.isAdmin
//       ? data.docs
//       : user.isSupervisor
//       ? data.docs.filter(
//           (doc) =>
//             doc.data().userId === user.id ||
//             users_data
//               .find((item) => item.id === doc.data().userId)
//               .supervisors?.find((el) => el.value === user.id)
//         )
//       : data.docs;

//     const parsed_data = filteredReq.map((doc) => {
//       return {
//         ...doc.data(),
//         id: doc.id,
//         user:
//           user.isAdmin || user.isSupervisor
//             ? users_data.find((el) => doc.data().userId === el.id)
//             : null,
//       };
//     });

//     dispatch({
//       type: types.GET_All_REQ,
//       payload: {
//         data: parsed_data,
//       },
//     });
//     // if (parsed_data.length === 0) {
//     //   toast.error("No Record Found!");
//     // }
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message);
//     return { isSubmitButton: false };
//   }
// };

// Add Department Function
export const addReqFun = (values, user) => async (dispatch) => {
  try {
    await customAxios.post("/leaves/create/", values);

    dispatch(getReqFun(1));
    toast.success("Successfully Added");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// // Add Department Function
// export const addReqFun = (values, user) => async (dispatch) => {
//   try {
//     const reqCollectionRef = collection(db, "requests");
//     await addDoc(reqCollectionRef, {
//       ...values,
//       status: "pending",
//       userId: user.id,
//       userEmail: user.email,
//     });

//     const emailTo = [
//       {
//         name: "admin",
//         email: "admin@decklaration.com",
//       },
//       ...user.supervisors.map((el) => ({
//         name: "not necessarily",
//         email: el.email,
//       })),
//     ];

//     const message = `${user.name} has generated ${
//       values.leaveType
//     } leave request for ${
//       values.startDate === values.endDate
//         ? values.startDate
//         : values.startDate + " to " + values.endDate
//     } (${
//       values.daysCount
//     } day). Please visit https://eportal.decklaration.com/ for more details.`;

//     dispatch(sendEmail("Leave Request", emailTo, message));

//     dispatch(getReqFun(user));
//     toast.success("Successfully Added");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message.data);
//     return { isSubmitButton: false };
//   }
// };
// Edit request
export const editReqFun = (reqId, status, user) => async (dispatch) => {
  try {
    const requestData = { leave_id: reqId, leave_new_status: status };
    await customAxios.patch(`/leaves/update-leave-status/`, requestData);

    toast.success("Successfully Updated");

    // dispatch({
    //   type: types.EDIT_REQUEST,
    //   payload: { ...requestData, id: reqId },
    // });

    dispatch(getReqFun(1));
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};
// Update Leaves Allowed Function
// Update Leaves Allowed Function
export const updateLeavesAllowed = (userId, data) => async (dispatch) => {
  try {
    const payload = {
      user_id: userId,
      ...data, // Include the edited leave statistics
    };

    const response = await customAxios.patch(
      "/leaves/update-leaves-allowed/",
      payload
    );
    const updatedData = response.data;

    dispatch({
      type: types.UPDATE_LEAVES_ALLOWED,
      payload: updatedData,
    });

    toast.success("Leaves allowed updated successfully");
  } catch (error) {
    toast.error(error?.response?.data?.detail);
  }
};

// // // Edit Department Function
// export const editReqFun = (reqId, status, user) => async (dispatch) => {
//   try {
//     const reqDoc = doc(db, "requests", reqId);
//     const newFields = { status: status };
//     await updateDoc(reqDoc, newFields);

//     const reqDocUpdated = doc(db, "requests", reqId);
//     const docSnap = await getDoc(reqDocUpdated);
//     const data = docSnap.data();

//     toast.success("Successfully Updated");
//     dispatch({
//       type: types.EDIT_REQUEST,
//       payload: { ...data, id: reqId },
//     });

//     const userToEmail = [
//       {
//         name: "not necessarily",
//         email: data.userEmail,
//       },
//     ];

//     const message = `Your ${
//       data.leaveType
//     } leave request has been ${status} for ${
//       data.startDate === data.endDate
//         ? data.startDate
//         : data.startDate + " to " + data.endDate
//     } (${data.daysCount} day) by ${user.name} ${
//       user.designation ? "(" + user.designation + ")" : ""
//     }`;

//     dispatch(
//       sendEmail(
//         `Leave Status | ${toTitleCase(status)} | ${
//           data.startDate === data.endDate
//             ? moment(data.startDate, "DD-MMMM-YYYY").format("DD/MMM/YY")
//             : moment(data.startDate, "DD-MMMM-YYYY").format("DD/MMM/YY") +
//               " - " +
//               moment(data.endDate, "DD-MMMM-YYYY").format("DD/MMM/YY")
//         }`,
//         userToEmail,
//         message
//       )
//     );

//     if (status === "approved") {
//       await dispatch(
//         updateUserLeaves(data.userId, data.leaveType, data.daysCount)
//       );
//       await dispatch(getUserFun());
//       if (data.userId === user.id) dispatch(refreshUserInfo(user.id));
//     }
//     dispatch(getReqFun(user));
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message);
//     return { isSubmitButton: false };
//   }
// };

// // Delete Department Function
// export const delDepartFun = (id) => async (dispatch) => {
//   try {
//     const response = await customAxios.delete(`departments/delete/${id}`);
//     toast.success(response.data.detail);
//     dispatch(getDepartFun(1));
//   } catch (error) {
//     toast.error(error.response.data.detail);
//   }
// };

// Fetch all public holidays
export const getAllPublicHolidays = () => async (dispatch) => {
  try {
    const response = await customAxios.get("/leaves/public-holidays/");
    const data = response.data;
    dispatch({
      type: types.GET_ALL_PUBLIC_HOLIDAYS,
      payload: data,
    });
  } catch (error) {
    toast.error(error?.response?.data?.detail);
  }
};

// Add a new public holiday
export const addPublicHoliday = (values) => async (dispatch) => {
  try {
    const response = await customAxios.post(
      "/leaves/create-public-holiday/",
      values
    );
    const data = response.data;
    toast.success(response.data.message); // Access the success message here
    dispatch({
      type: types.ADD_PUBLIC_HOLIDAY,
      payload: data,
    });
  } catch (error) {
    toast.error(error.response.data.errors.non_field_errors[0]);
  }
};

// Update a public holiday
export const updatePublicHoliday = (id, data) => async (dispatch) => {
  try {

    const response = await customAxios.put(
      `leaves/update-public-holiday/`,
      data
    );

    toast.success("Successfully Updated");
    dispatch({
      type: types.UPDATE_PUBLIC_HOLIDAY,
      payload: response.data,
    });

    //const data = response.data.data;
    dispatch(getAllPublicHolidays());
  } catch (error) {
    toast.error(error?.response?.data?.detail);
  }
};

// Delete a public holiday
export const deletePublicHoliday = (id) => async (dispatch) => {
  try {
    // await customAxios.delete(`/leaves/delete-public-holiday/${id}`);
    const response = await customAxios.delete(
      "/leaves/delete-public-holiday/",
      {
        data: { id: id },
      }
    );
    dispatch(getAllPublicHolidays());
    toast.success("Successfully Deleted");
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
