import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  XIcon,
  MenuAlt1Icon,
  DatabaseIcon,
  CogIcon,
  ViewGridAddIcon,
  ClipboardIcon,
  LogoutIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  UserAddIcon,
} from "@heroicons/react/solid";
import { Nav, Badge, Image, Button, Navbar, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Routes } from "utils/routes";
import ReactHero from "assets/img/technologies/decklaration.svg";
import ProfilePicture from "assets/img/team/profile-picture.png";
import { useSelector } from "react-redux";
import { logoutFun } from "redux/auth/action";

export default (props = {}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetail = useSelector((state) => state.auth);
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";

  const onCollapse = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      props.setContracted(false);
      setShow(!show);
    }, 300);

    setTimerId(newTimerId);
  };
  // const onMouseEnter = () => {
  //   props.onMouseEnter();
  // };

  // const onMouseLeave = () => {
  //   props.onMouseLeave();
  // };

  const handleLogOut = () => {
    dispatch(logoutFun(userDetail?.refresh));
  };
  // const events = isMobile ? {} : { onMouseEnter, onMouseLeave};

  const NavItem = (props) => {
    const {
      title,
      link,
      target,
      icon: NavItemIcon,
      image,
      badgeText,
      badgeBg,
      badgeColor = "white",
    } = props;
    const classNames = badgeText
      ? "d-flex align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted">{title[0]}</span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-sm notification-count"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        as={Col}
        xs={12}
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-lg-none"
      >
        <Navbar.Brand
          as={Link}
          to={"/"}
          className="me-lg-5 d-flex align-content-end"
        >
          <Image src={ReactHero} className="navbar-brand-dark" />
          <h4 className="mb-0 ms-2">Eportal</h4>
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          // {...events}
          className={`${contracted} ${showClass} sidebar d-lg-block bg-gray-800 text-white collapse `}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-lg me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h5 className="mb-0">
                    {userDetail.name ? userDetail.name : userDetail.email}
                  </h5>

                  <p className="mb-0">{userDetail.designation}</p>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={handleLogOut}
                    className="d-inline-flex align-items-center"
                  >
                    <LogoutIcon className="icon icon-xxs me-1" /> Logout
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title="Decklaration e-portal"
                link={"/"}
                image={ReactHero}
              />

              {!userDetail.isAdmin && (
                <NavItem
                  title="Dashboard"
                  icon={DatabaseIcon}
                  link={Routes.UserDashboard.path}
                />
              )}
              <NavItem
                title="Requests"
                icon={MenuAlt1Icon}
                link={Routes.ReqListing.path}
              />
              {userDetail.isAdmin || userDetail.isSupervisor ? (
                <>
                  <NavItem
                    title="Users"
                    icon={UserAddIcon}
                    link={Routes.ListingView.path}
                  />
                </>
              ) : null}
              {userDetail.isAdmin && (
                <>
                  <NavItem
                    title="Projects"
                    icon={ViewGridAddIcon}
                    link={Routes.Project.path}
                  />
                </>
              )}
              {window.innerWidth >= 768 && ( // Show only on tablet and desktop
                <>
                  {userDetail.isAdmin || userDetail.isSupervisor ? (
                    <>
                      <NavItem
                        title="Management Reports"
                        icon={ClipboardIcon}
                        link={Routes.Management.path}
                      />
                    </>
                  ) : null}
                  {userDetail.isAdmin && (
                    <NavItem
                      title="Finance Reports"
                      icon={CurrencyDollarIcon}
                      link={Routes.Finance.path}
                    />
                  )}
                </>
              )} 
              {window.innerWidth >= 768 && ( // Show only on tablet and desktop
                <>
                  {!userDetail.isAdmin && (
                    <NavItem
                      title="Associate Reports"
                      icon={DocumentIcon}
                      link={Routes.Associate.path}
                    />
                  )}
                </>
              )}
              <NavItem
                title="Settings"
                icon={CogIcon}
                link={Routes.Settings.path}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
