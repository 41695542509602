import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card, Button, Form } from "react-bootstrap";
import Table from "react-bootstrap-table-next";
import ReactSelect from "react-select";
import Pagination, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { CloudDownloadIcon } from "@heroicons/react/solid";


import moment from "moment";

export default ({ data = [], columns, parentComponent, filters }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(data);
  const [selectedOption, setSelectedOption] = useState("all");
  const [selectedDate, setSelectedDate] = useState(null);
  const [date, setDate] = useState();
  const { ExportCSVButton } = CSVExport;
  const customTotal = (from, to, size) => (
    <div>
      Showing {from} to {to} of {size} entries
    </div>
  );
  const ref = useRef();
  const customSizePerPage = (props) => {
    const { options, currentSizePerPage, onSizePerPageChange } = props;

    const onPageChange = (e) => {
      const page = e.target.value;
      onSizePerPageChange(page);
    };

    return (
      <Row as="label">
        <Col xs="auto">
          <Form.Select
            value={currentSizePerPage}
            onChange={onPageChange}
            className="pe-5"
          >
            {options.map((o, index) => (
              <option key={index} value={o.page}>
                {o.text}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs="auto" className="d-flex align-items-center ps-0">
          entries per page
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleFilterChange = (selectedDate, selectedStatus) => {
    setSelectedDate(selectedDate);
    setSelectedOption(selectedStatus);
    setCurrentPage(1); // Reset current page to 1
  };
  

  useEffect(() => {
    if (Array.isArray(data)) {
      const filteredData = data?.filter((item) => {
        const statusFilterPass =
          selectedOption === "all" || item.status === selectedOption;

        const formattedSelectedDate =
          selectedDate && selectedDate.isValid()
            ? selectedDate.format("YYYY-MM-DD")
            : null;

        const formattedStartDate = item.startDate; // No need to format since it's already in "YYYY-MM-DD" format
        const formattedEndDate = item.endDate; // No need to format since it's already in "YYYY-MM-DD" format

        const dateFilterPass =
          !formattedSelectedDate ||
          (formattedStartDate <= formattedSelectedDate &&
            formattedEndDate >= formattedSelectedDate);

        return dateFilterPass && statusFilterPass;
      });

      setTableData(filteredData);
    }
  }, [selectedDate, selectedOption, data]);

  const handleClearClick = () => {
    setSelectedDate(null);
    setSelectedOption("all");
    setTableData(data);
  };
  return (
    <>
      <ToolkitProvider
        keyField="id"
        search={true}
        columns={columns}
        data={Array.isArray(tableData) ? tableData : []}
      >
        {({ baseProps, searchProps, csvProps }) => (
          <PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: tableData?.length || 0,
            withFirstAndLast: false,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
            page: currentPage, // Set the current page
          })}
          
          >
            {({ paginationProps, paginationTableProps }) => (
              <Card>
                <div className="table-responsive py-4">
                  <div className="dataTable-top">
                    <div className="d-flex flex-column flex-md-row">
                      <div className="dataTable-search mb-2 mb-md-0">
                        <Search.SearchBar
                          {...searchProps}
                          placeholder=" "
                          delay={2}
                          style={{ boxShadow: "none" }}
                        />
                      </div>
                     
                    </div>

                    {data?.length > 0 && (
                      <div className="mt-2 mt-md-0">
                        <ExportCSVButton {...csvProps} className="ps-0 ms-0">
                          <Button variant="primary" className="m-1">
                            Download{" "}
                            <CloudDownloadIcon className="icon icon-xs ms-1" />
                          </Button>
                        </ExportCSVButton>
                      </div>
                    )}
                  </div>

                  <div style={{ minHeight: "150px" }}>
                    <Table
                      {...baseProps}
                      {...paginationTableProps}
                      headerWrapperClasses="thead-light"
                      bodyClasses="border-0"
                      rowClasses="border-bottom"
                      classes="table-flush dataTable-table"
                    />
                  </div>
                  <div className="dataTable-bottom">
                    <div className="dataTable-pagination">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                    <div className="dataTable-info">
                      <PaginationTotalStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
    </>
  );
};
