import React, { useMemo, useState } from "react";
import { Col, Row, Card, Form, Spinner, Tab, Nav } from "react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import ReactSelect from "react-select";
import {
  LockClosedIcon,
  UserIcon,
  MailIcon,
  CalendarIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import { Button, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import { changePasswordFun, profileUpdateFun } from "redux/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { CapitalizeFirstLetter, customSelectStyles } from "utils/Global";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { refreshUserInfoFun } from "redux/auth/action";
import Select from "react-select";
import countryList from "react-select-country-list";

export default () => {
  let object = ["profile settings", "security"];
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let options = useSelector((state) => state.accountSetup);
  const today = moment(new Date()).format("YYYY-MM-DD");

  let initialSchema = Yup.object().shape({
    // name: Yup.string()
    //   .trim()
    //   .required("Name is required")
    //   .min(3, "Too Short!")
    //   .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    //   .max(50, "Too Long!"),
    oldPassword: Yup.string()
      .min(6, "Password is too short")
      .matches(/^\S*$/, "Password should not contain spaces")
      .when("newPassword", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required(
          "Old password is required when setting new password"
        ),
        otherwise: Yup.string(),
      }),
    cnic: Yup.string()
      .trim()
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
        "Please enter a valid CNIC number"
      )
      .required("CNIC number is required"),
    newPassword: Yup.string()
      .min(6, "Password is too short - should be 6 chars minimum")
      .matches(/^\S*$/, "Password should not contain spaces"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .matches(/^\S*$/, "Password should not contain spaces"),
    email: Yup.string().trim().email().required("Email is required"),
    // designation: Yup.string()
    //   .trim()
    //   .required("Designation is required")
    //   .min(3, "Too Short!")
    //   .max(50, "Too Long!"),
    // phone: Yup.string().trim().required("Phone number is required"),
    // gender: Yup.string().required("Gender is required"),
    // dob: Yup.date()
    //   .required("Date of Birth is required")
    //   .max(today, "Future date not allowed"),
    // cnic: Yup.string()
    //   .trim()
    //   .matches(
    //     /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
    //     "Please enter a valid CNIC number"
    //   )
    //   .required("CNIC number is required"),
    address: Yup.string().trim(),
  });

  let initialObj = {
    name: userDetail?.name?.length ? userDetail?.name : "",
    designation: userDetail?.designation?.length ? userDetail?.designation : "",
    email: userDetail?.email?.length ? userDetail?.email : "",
    phone: userDetail?.phone?.length ? userDetail?.phone : "",
    dob: userDetail?.dob?.length ? userDetail?.dob : today,
    gender: userDetail?.gender?.length ? userDetail?.gender : "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    cnic: userDetail?.cnic ? userDetail.cnic : "",
    address: userDetail?.address ? userDetail.address : "",
    skillSet: userDetail?.skillSet ? userDetail.skillSet : "",
    joinDate: userDetail?.joinDate
      ? moment(userDetail.joinDate).format("DD-MM-YYYY")
      : moment(new Date()).format("DD-MM-YYYY"),
    jobType: userDetail?.jobType ? userDetail.jobType : "",
    isAdmin: userDetail?.isAdmin ? "Yes" : "No",
    isSupervisor: userDetail?.isSupervisor ? "Yes" : "No",
    supervisors: userDetail?.supervisors ? userDetail.supervisors : "",
    country: userDetail?.country ? userDetail.country : "",
    maritalStatus: userDetail?.maritalStatus ? userDetail.maritalStatus : null,
    childbirthLeavesAllowed: userDetail?.childbirthLeavesAllowed
      ? userDetail.childbirthLeavesAllowed
      : "",
  };
  const typeOptions = [
    {
      value: "full_time",
      label: "Full Time",
    },
    { value: "part_time", label: "Part Time" },
    { value: "intern", label: "Intern" },
  ];
  
  useEffect(() => {
    dispatch(refreshUserInfoFun());
  }, [dispatch]);

  const skillSetOptions = [
    { value: "ReactJS", label: "ReactJS" },
    { value: "VueJS", label: "VueJS" },
    { value: "AngularJS", label: "AngularJS" },
    { value: "NodeJS", label: "NodeJS" },
    { value: "Java", label: "Java" },
    { value: "Python", label: "Python" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "HTML", label: "HTML" },
    { value: "CSS", label: "CSS" },
    { value: "MySQL", label: "MySQL" },
    { value: "MongoDB", label: "MongoDB" },
    { value: "AWS", label: "AWS" },
    { value: "Azure", label: "Azure" },
    { value: "Git", label: "Git" },
    { value: "Jira", label: "Jira" },
    { value: "Unity", label: "Unity" },
    { value: "Unreal Engine", label: "Unreal Engine" },
    { value: "Cocos2D", label: "Cocos2D" },
    { value: "Android", label: "Android" },
    { value: "Kotlin", label: "Kotlin" },
    { value: "React Native", label: "React Native" },
    { value: "Flutter", label: "Flutter" },
    { value: "Block Chain", label: "Block Chain" },
  ];
  const Formik = useFormik({
    enableReinitialize: true,
    validationSchema: initialSchema,
    initialValues: initialObj,

    onSubmit: async (values, action) => {
      setIsLoading(true);
      if (tabValue === "security") {
        if (
          values.oldPassword.length === 0 ||
          values.newPassword.length === 0 ||
          values.confirmNewPassword.length === 0
        ) {
          toast.error("Fields are required!");
          setIsLoading(false);
          return;
        }
        if (values.oldPassword === values.newPassword) {
          toast.error("Old and new passwords cannot be the same");
          setIsLoading(false);
          return;
        }
        if (values.newPassword !== values.confirmNewPassword) {
          toast.error("Password does not match");
          setIsLoading(false);
          return;
        }
        const body = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        };

        let isSubmit = await dispatch(changePasswordFun(body));
        Formik.resetForm();
        setIsLoading(false);
        setDisableSubmitButton(isSubmit.isSubmitButton);
      } else {
        let validPhone = isValidPhoneNumber(values.phone);
        if (
          tabValue === "profile settings" &&
          values.phone &&
          validPhone === false
        ) {
          Formik.setFieldError("phone", "Invalid phone number");
          setIsLoading(false);
          return;
        }
        setDisableSubmitButton(!disableSubmitButton);
        let updatedObj = { ...values };
        updatedObj.dob = moment(updatedObj.dob).format("YYYY-MM-DD");
        updatedObj.skillSet = selectedSkills
          .map((item) => item.value)
          .join(", ");
        let isSubmit = await dispatch(profileUpdateFun(updatedObj));
        setDisableSubmitButton(isSubmit.isSubmitButton);
      }
      setIsLoading(false);
    },
  });
  useEffect(() => {
    if (userDetail.length) {
      const onlySupervisors = userDetail?.filter((user) => user.isSupervisor);

      const arr = onlySupervisors.map((val) => ({
        value: val.id,

        label: `${val?.name} - ( ${val?.designation} )`,
      }));

      setSupervisorsList(arr);
    }
  }, [userDetail]);

  const [selectedSkills, setSelectedSkills] = useState(
    userDetail.skillSet || []
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setOldPassword] = useState(false);
  const [tabValue, setTabValue] = useState("profile settings");
  const countryOptions = useMemo(() => countryList().getData(), []);
  return (
    <>
      <Row className="py-4">
        <Col xs={12} xl={12}>
          <Card className="p-3">
            <Tab.Container defaultActiveKey="profile settings">
              <Row>
                <Col lg={12}>
                  <Nav className="nav-tabs">
                    {object.map((val, index) => {
                      return (
                        <Nav.Item key={index}>
                          <Nav.Link
                            eventKey={val}
                            onClick={() => {
                              setTabValue(val);
                            }}
                            className="mb-sm-3 mb-md-0"
                          >
                            {CapitalizeFirstLetter(val)}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col lg={12}>
                  <Tab.Content>
                    {object.map((val, index) => {
                      return (
                        <Tab.Pane key={index} eventKey={val} className="py-4">
                          <Form
                            onSubmit={Formik.handleSubmit}
                            className="signUp_Form"
                          >
                            <Row>
                              {tabValue === "profile settings" && (
                                <>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="name">
                                      <Form.Label>Name</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <UserIcon className="icon icon-xs text-gray-600" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="rounded-end"
                                          disabled
                                          autoFocus
                                          name="name"
                                          value={Formik.values.name}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="email">
                                      <Form.Label>Email</Form.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <MailIcon className="icon icon-xs text-gray-600" />
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="rounded-end"
                                          autoFocus
                                          type="email"
                                          name="email"
                                          disabled
                                          placeholder={Formik.values.email}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="designation">
                                      <Form.Label>Designation</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          className="rounded-end"
                                          autoFocus
                                          type="designation"
                                          name="designation"
                                          disabled
                                          placeholder={
                                            Formik.values.designation
                                          }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="birthday">
                                      <Form.Label>Date of Birth</Form.Label>
                                      <Datetime
                                        disabled
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={(date) => {
                                          Formik.setFieldValue(
                                            "dob",
                                            moment(date).format("YYYY-MM-DD")
                                          );
                                        }}
                                        renderInput={(props, openCalendar) => (
                                          <InputGroup>
                                            <InputGroup.Text>
                                              <CalendarIcon className="icon icon-xs" />
                                            </InputGroup.Text>
                                            <Form.Control
                                              className="rounded-end"
                                              required
                                              type="text"
                                              value={moment(
                                                Formik.values.dob
                                              ).format("DD-MM-YYYY")}
                                              placeholder="YYYY-MM-DD"
                                              onFocus={openCalendar}
                                              onChange={() => {}}
                                            />
                                            <Form.Control.Feedback
                                              type="invalid"
                                              style={{ display: "block" }}
                                            >
                                              {Formik?.errors?.dob}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="gender">
                                      <Form.Label>Gender</Form.Label>
                                      <InputGroup>
                                        <Form.Select
                                          className="rounded-end"
                                          value={Formik.values.gender}
                                          onChange={(e) => {
                                            Formik.setFieldValue(
                                              "gender",
                                              e.target.value
                                            );
                                          }}
                                          multiple={false}
                                        >
                                          <option defaultValue>Gender</option>
                                          <option value="male">Male</option>
                                          <option value="female">Female</option>
                                          <option value="other">Other</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.gender}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>

                                  <Col md={6} className="mb-3">
                                    <Form.Group id="cnic">
                                      <Form.Label>CNIC</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          className="rounded-end noNumberSpinner"
                                          autoFocus
                                          type="text"
                                          name="cnic"
                                          value={Formik.values.cnic}
                                          onChange={(e) => {
                                            const inputCnic = e.target.value;
                                            const formattedCnic = inputCnic
                                              .replace(/\D/g, "") // Remove all non-numeric characters
                                              .replace(
                                                /(\d{5})(\d{7})(\d{1})/,
                                                "$1-$2-$3"
                                              ); // Add dashes at appropriate positions
                                            Formik.setFieldValue(
                                              "cnic",
                                              formattedCnic
                                            );
                                          }}
                                          placeholder="Enter CNIC"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.cnic}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>

                                  <Col md={6} className="mb-3">
                                    <Form.Group id="phone">
                                      <Form.Label>Phone Number</Form.Label>
                                      <InputGroup>
                                        <PhoneInput
                                          international
                                          defaultCountry="PK"
                                          type="text"
                                          label="Phone Number"
                                          value={Formik.values.phone}
                                          placeholder="Phone Number"
                                          isinvalid="true"
                                          className="phoneNumberField form-control rounded-end"
                                          onChange={(phone) => {
                                            Formik.setFieldValue(
                                              "phone",
                                              phone
                                            );
                                          }}
                                        />
                                        <Form.Control.Feedback
                                          type="invalid"
                                          style={{ display: "block" }}
                                        >
                                          {Formik?.errors?.phone}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group
                                      id="startDate"
                                      className="mb-2 mt-0"
                                    >
                                      <Form.Label>Joining date</Form.Label>
                                      <Datetime
                                        timeFormat={false}
                                        onChange={(date) => {
                                          Formik.setFieldValue(
                                            "joinDate",
                                            moment(date).format("YYYY-MM-DD")
                                          );
                                        }}
                                        renderInput={(props, openCalendar) => (
                                          <InputGroup>
                                            <InputGroup.Text>
                                              <CalendarIcon className="icon icon-xs" />
                                            </InputGroup.Text>
                                            <Form.Control
                                              className="rounded-end"
                                              disabled
                                              required
                                              type="text"
                                              placeholder="YYYY-MM-DD"
                                              value={Formik.values.joinDate}
                                              onFocus={openCalendar}
                                              onChange={() => {}}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {Formik?.errors?.joinDate}
                                            </Form.Control.Feedback>
                                          </InputGroup>
                                        )}
                                        closeOnSelect={true}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="isSupervisor">
                                      <Form.Label>
                                        Supervisor Account
                                      </Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          className="rounded-end"
                                          disabled
                                          autoFocus
                                          type="isSupervisor"
                                          name="isSupervisor"
                                          placeholder={
                                            Formik.values.isSupervisor
                                          }
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="isAdmin">
                                      <Form.Label>Admin Account</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          className="rounded-end"
                                          autoFocus
                                          type="isAdmin"
                                          name="isAdmin"
                                          disabled
                                          placeholder={Formik.values.isAdmin}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  {!userDetail.isAdmin ? (
                                    <Col md={6} className="mb-3">
                                      <Form.Group id="childbirthleave">
                                        <Form.Label>
                                          Maternity/Paternity leaves allowed
                                        </Form.Label>
                                        <InputGroup>
                                          <Form.Control
                                            className="rounded-end"
                                            autoFocus
                                            type="childbirthleave"
                                            name="childbirthleave"
                                            disabled
                                            value={
                                              userDetail.childbirthLeavesAllowed ===
                                              true
                                                ? "Yes"
                                                : "No"
                                            }
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  <Col md={6} className="mb-3">
                                    <Form.Group
                                      id="job_type"
                                      className="mb-2 mt-0"
                                    >
                                      <Form.Label>Job Type</Form.Label>
                                      <Form.Select
                                        disabled
                                        className="mb-0 rounded-end"
                                        name="jobType"
                                        value={Formik.values.jobType}
                                        onChange={(e) => {
                                          Formik.setFieldValue(
                                            "jobType",
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <option value="">Select Type</option>
                                        {typeOptions.map((item, i) => {
                                          return (
                                            <>
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {CapitalizeFirstLetter(
                                                  item.label
                                                )}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">
                                        {Formik?.errors?.jobType}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>

                                  <Col md={6} className="mb-3">
                                    <Form.Group id="address">
                                      <Form.Label>Address</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          className="rounded-end"
                                          autoFocus
                                          name="address"
                                          value={Formik.values.address}
                                          onChange={Formik.handleChange}
                                          type="text"
                                          placeholder="Enter Address"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.address}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group
                                      id="skillSet"
                                      className="mb-2 mt-0"
                                    >
                                      <Form.Label>Skill Set</Form.Label>
                                      <CreatableSelect
                                        style={{ index: "999" }}
                                        options={skillSetOptions}
                                        value={selectedSkills}
                                        onChange={(selectedOptions) => {
                                          setSelectedSkills(selectedOptions);
                                          Formik.setFieldValue(
                                            "skillSet",
                                            selectedOptions
                                              ? selectedOptions.map(
                                                  (option) => option.value
                                                )
                                              : []
                                          );
                                        }}
                                        styles={customSelectStyles}
                                        isMulti
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.value
                                        }
                                        formatCreateLabel={(inputValue) =>
                                          `Add "${inputValue}"`
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {Formik?.errors?.skillSet}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group
                                      id="Supervisors"
                                      className="mb-2 mt-0"
                                    >
                                      <Form.Label>Supervisors</Form.Label>
                                      <ReactSelect
                                        isDisabled={true}
                                        options={supervisorsList}
                                        value={Formik.values.supervisors}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                            primary25: "#61DAFB",
                                            primary: "#61DAFB",
                                          },
                                        })}
                                        styles={customSelectStyles}
                                        isMulti
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.value
                                        }
                                        valueKey={(option, index) =>
                                          option.value
                                        }
                                        labelKey={(option, index) =>
                                          option.label
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <Form.Group id="maritalStatus">
                                      <Form.Label>Marital Status</Form.Label>
                                      <Form.Select
                                        className="mb-0 rounded-end"
                                        name="maritalStatus"
                                        value={Formik.values.maritalStatus}
                                        onChange={(e) => {
                                          Formik.setFieldValue(
                                            "maritalStatus",
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <option value="single">Single</option>
                                        <option value="married">Married</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col md={6} className="mb-3">
                                    <Form.Group id="country">
                                      <Form.Label>Country</Form.Label>
                                      <Select
                                        options={countryOptions}
                                        value={countryOptions.find(
                                          (option) =>
                                            option.value ===
                                            Formik.values.country
                                        )}
                                        onChange={(selectedOption) => {
                                          Formik.setFieldValue(
                                            "country",
                                            selectedOption.value
                                          );
                                        }}
                                        isSearchable
                                        placeholder="Select a country"
                                      />
                                    </Form.Group>
                                  </Col>
                                </>
                              )}

                              {tabValue === "security" && (
                                <>
                                  <Row>
                                    <Col md={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>Old Password</Form.Label>
                                        <InputGroup>
                                          <InputGroup.Text>
                                            <LockClosedIcon className="icon icon-xs text-gray-600" />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type={
                                              showOldPassword
                                                ? "text"
                                                : "password"
                                            }
                                            name="oldPassword"
                                            value={Formik.values.oldPassword}
                                            onChange={Formik.handleChange}
                                            isInvalid={false}
                                          />
                                          <InputGroup.Text
                                            className="rounded-end"
                                            onClick={() => {
                                              setOldPassword(!showOldPassword);
                                            }}
                                          >
                                            {showOldPassword ? (
                                              <EyeIcon className="icon icon-xs  text-gray-600" />
                                            ) : (
                                              <EyeOffIcon className="icon icon-xs text-gray-600" />
                                            )}
                                          </InputGroup.Text>
                                          <Form.Control.Feedback type="invalid">
                                            {Formik?.errors?.oldPassword}
                                          </Form.Control.Feedback>
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>New Password</Form.Label>
                                        <InputGroup>
                                          <InputGroup.Text>
                                            <LockClosedIcon className="icon icon-xs text-gray-600" />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            name="newPassword"
                                            value={Formik.values.newPassword}
                                            onChange={Formik.handleChange}
                                          />
                                          <InputGroup.Text
                                            className="rounded-end"
                                            onClick={() => {
                                              setShowPassword(!showPassword);
                                            }}
                                          >
                                            {showPassword ? (
                                              <EyeIcon className="icon icon-xs  text-gray-600" />
                                            ) : (
                                              <EyeOffIcon className="icon icon-xs text-gray-600" />
                                            )}
                                          </InputGroup.Text>
                                          <Form.Control.Feedback type="invalid">
                                            {Formik?.errors?.newPassword}
                                          </Form.Control.Feedback>
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>
                                          Confirm New Password
                                        </Form.Label>
                                        <InputGroup>
                                          <InputGroup.Text>
                                            <LockClosedIcon className="icon icon-xs text-gray-600" />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type={
                                              showConfirmPassword
                                                ? "text"
                                                : "password"
                                            }
                                            name="confirmNewPassword"
                                            value={
                                              Formik.values.confirmNewPassword
                                            }
                                            onChange={Formik.handleChange}
                                          />
                                          <InputGroup.Text
                                            className="rounded-end"
                                            onClick={() => {
                                              setShowConfirmPassword(
                                                !showConfirmPassword
                                              );
                                            }}
                                          >
                                            {showConfirmPassword ? (
                                              <EyeIcon className="icon icon-xs  text-gray-600" />
                                            ) : (
                                              <EyeOffIcon className="icon icon-xs text-gray-600" />
                                            )}
                                          </InputGroup.Text>
                                          <Form.Control.Feedback type="invalid">
                                            {Formik?.errors?.confirmNewPassword}
                                          </Form.Control.Feedback>
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Row>
                            <div className="mt-3">
                              <Button
                                variant="gray-800"
                                className="mt-2 animate-up-2 d-flex align-items-center mb-1"
                                type="submit"
                                disabled={disableSubmitButton}
                                onClick={Formik.handleSubmit}
                              >
                                {isLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </Form>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};
