import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { createProjectFun } from "redux/projects/action";

export default function ProjectModal({ showModal, closeModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    title: "",
    avazaCompany: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    avazaCompany: Yup.string().required("Required"),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    let isSubmit = "";
    setIsSubmitting(true);
    isSubmit = await dispatch(createProjectFun(values));
    if (!isSubmit?.isSubmitButton) {
      setIsSubmitting(false);
      closeModal();
      resetForm();
    }
  };

  return (
    <Modal centered show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create Project</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="title">Title</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.title && formik.touched.title}
                />
                {formik.errors.title && formik.touched.title && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="Company">
                  Company
                  <span className="required-info"> (Required for Avaza)</span>
                </Form.Label>

                <Form.Control
                  type="text"
                  name="avazaCompany"
                  value={formik.values.avazaCompany}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.errors.avazaCompany && formik.touched.avazaCompany
                  }
                />
                {formik.errors.avazaCompany && formik.touched.avazaCompany && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.avazaCompany}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Creating..." : "Create"}
              </Button>
              <Button
                variant="link"
                className="text-gray ms-auto"
                onClick={closeModal}
                disabled={isSubmitting}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
