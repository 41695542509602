import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { CalendarIcon } from "@heroicons/react/solid";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const DateFilter = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  function getFirstSaturdayOfMonth(currentDate) {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    let firstSaturday = new Date(firstDayOfMonth);

    // Find the first Saturday
    while (firstSaturday.getDay() !== 6) {
      firstSaturday.setDate(firstSaturday.getDate() + 1);
    }

    return firstSaturday;
  }

  function getDatesOfCurrentMonth() {
    const currentDate = new Date();
    const firstSaturday = getFirstSaturdayOfMonth(currentDate);
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const dates = [];
    let currentDay = new Date(firstSaturday);

    while (currentDay <= lastDayOfMonth) {
      const endDate = new Date(currentDay);
      endDate.setDate(endDate.getDate() + 6); // Calculate the end date for the 7-day interval

      // Check if the interval falls outside of the current week
      if (currentDay <= currentDate && endDate >= currentDate) {
        currentDay.setDate(currentDay.getDate() + 7); // Move to the next Saturday for the next interval
        continue; // Skip adding the current week's interval
      }

      if (endDate > lastDayOfMonth) {
        endDate.setDate(lastDayOfMonth.getDate()); // Adjust the end date if it exceeds the last day of the month
      }

      // Check if there are fewer than 7 days left in the current month
      const daysLeftInMonth =
        lastDayOfMonth.getDate() - currentDay.getDate() + 1;
      if (daysLeftInMonth < 7) {
        // Calculate the start date of the next month's interval
        const nextMonthStartDate = new Date(lastDayOfMonth);
        nextMonthStartDate.setDate(nextMonthStartDate.getDate() + 1);

        // Calculate the end date of the next month's interval (first Saturday)
        const nextMonthEndDate = new Date(nextMonthStartDate);
        while (nextMonthEndDate.getDay() !== 5) {
          nextMonthEndDate.setDate(nextMonthEndDate.getDate() + 1);
        }

        dates.push({
          startDate: new Date(currentDay),
          endDate: new Date(nextMonthEndDate),
        });

        break; // Exit the loop as we have considered the remaining days of the current month
      }

      dates.push({ startDate: new Date(currentDay), endDate });
      currentDay.setDate(currentDay.getDate() + 7); // Move to the next Saturday for the next interval
    }

    return dates;
  }
  function formatDateRange(startDate, endDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const startFormatted = startDate.toLocaleDateString(undefined, options);
    const endFormatted = endDate.toLocaleDateString(undefined, options);
    return `${startFormatted} - ${endFormatted}`;
  }
  const DropdownIndicator = (props) => {
    const Icon = props.selectProps.indicatorIcon;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon width={20} height={20} />
        </components.DropdownIndicator>
      )
    );
  };
  const isWeekend = (date) => {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // Sunday (0) and Saturday (6)
  };

  const handleDateChange = (date) => {
    if (date instanceof Date) {
      const selectedDate = new Date(date);

      // Calculate the interval start (previous Saturday or same day if it's Saturday)
      const dayOfWeek = selectedDate.getDay();
      const diffToPreviousSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1; // Calculate days until previous Saturday
      const start = new Date(selectedDate);
      start.setDate(selectedDate.getDate() - diffToPreviousSaturday);

      // Calculate the interval end (next Friday or same day if it's Friday)
      let diffToNextFriday = (6 - dayOfWeek + 7) % 7; // Calculate days until next Friday
      if (diffToNextFriday >= 7) diffToNextFriday = 0; // If selected date is a Friday, keep it as the end of the interval
      const end = new Date(selectedDate);
      end.setDate(selectedDate.getDate() + diffToNextFriday);

      // Subtract one day from the end date
      end.setDate(end.getDate() - 1);

      // Format the start and end dates with month only
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedStartDate = start.toLocaleDateString(undefined, options);
      const formattedEndDate = end.toLocaleDateString(undefined, options);
      const formattedDateInterval = `${formattedStartDate} - ${formattedEndDate}`;
   
      setSelectedDate({
        value: formattedDateInterval, // Store the actual Date object in the value property
        label: formattedDateInterval, // Use the formatted date interval for display in the field
        start,
        end,
      });
    } else {
      setSelectedDate(null);
    }
    setShowCalendar(false);
  };
  const handleSelectChange = (option) => {
    if (option.value === "custom-date") {
      setSelectedDate({ value: null });
      setShowCalendar(true);
    } else if (option.value === "current-week") {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay();
      const diffToPreviousSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
      const start = new Date(currentDate);
      start.setDate(currentDate.getDate() - diffToPreviousSaturday);

      const end = new Date(currentDate);
      end.setDate(currentDate.getDate() + (6 - dayOfWeek));

      const formattedDateInterval = formatDateRange(start, end);

      setSelectedDate({
        value: formattedDateInterval,
        label: option.label,
        start,
        end,
      });

      setShowCalendar(false);


    } else if (option.value === "last-week") {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay();
      const diffToPreviousSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
      const end = new Date(currentDate);
      end.setDate(currentDate.getDate() - diffToPreviousSaturday - 1); // End of previous week

      const start = new Date(end);
      start.setDate(end.getDate() - 6); // Start of last week

      const formattedDateInterval = formatDateRange(start, end);

      setSelectedDate({
        value: formattedDateInterval,
        label: option.label,
        start,
        end,
      });

      setShowCalendar(false);

   
    } else {
      setSelectedDate(option);
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    const handleClickOutsideCalendar = (event) => {
      if (showCalendar && !event.target.closest(".calendar-container")) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("click", handleClickOutsideCalendar);

    return () => {
      document.removeEventListener("click", handleClickOutsideCalendar);
    };
  }, [showCalendar]);
  // Get intervals for the current month
  const currentMonthIntervals = getDatesOfCurrentMonth();

  // Calculate start and end dates of the previous week
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const diffToPreviousSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
  const previousWeekEnd = new Date(currentDate);
  previousWeekEnd.setDate(currentDate.getDate() - diffToPreviousSaturday - 1); // Subtract one more day to get the end of the previous week
  const previousWeekStart = new Date(previousWeekEnd);
  previousWeekStart.setDate(previousWeekEnd.getDate() - 6);

  const filteredIntervals = currentMonthIntervals.filter(
    ({ startDate, endDate }) => {
      const intervalStart = startDate.getTime();
      const intervalEnd = endDate.getTime();
      const previousWeekStartTimestamp = previousWeekStart.getTime();
      const previousWeekEndTimestamp = previousWeekEnd.getTime();
      return (
        intervalEnd < previousWeekStartTimestamp ||
        intervalStart > previousWeekEndTimestamp
      );
    }
  );

  const dateOptions = [
    { label: "Current Week", value: "current-week" },
    { label: "Last Week", value: "last-week" },
    ...filteredIntervals.map(({ startDate, endDate }) => ({
      label: formatDateRange(startDate, endDate),
      value: `${startDate.toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}-${endDate.toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}`,
    })),
    { label: "Custom Date", value: "custom-date" },
  ];

  return (
    <div className="dropdown-container custom-dropdown-container">
      <p className="mb-0">Date</p>
      <div style={{ position: "relative" }}>
        <div style={{ position: "relative" }}>
          <Select
            className="custom-dropdown form-select"
            options={dateOptions}
            value={
              selectedDate && selectedDate.value instanceof Date
                ? {
                    value: selectedDate.value,
                    label: formatDateRange(
                      selectedDate.start,
                      selectedDate.end // Use the start and end dates for the interval
                    ),
                  }
                : selectedDate
            }
            isSearchable={false}
            components={{ DropdownIndicator }}
            indicatorIcon={CalendarIcon}
            onChange={handleSelectChange}
            styles={{
              control: (provided) => ({
                ...provided,
                width: 250,
              }),
              menu: (provided) => ({
                ...provided,
                width: 250,
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
              }),
              indicatorSeparator: () => ({}),
            }}
          />
          {showCalendar && (
            <div className="calendar-container">
              <Calendar
                value={
                  selectedDate && selectedDate.value instanceof Date
                    ? selectedDate.value
                    : new Date()
                }
                onChange={handleDateChange}
                tileDisabled={({ date, view }) =>
                  view === "month" && isWeekend(date)
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
