import * as types from "./contants";
import customAxios from "../../config/index";
import { toast } from "react-toastify";

export const getProjectFun = () => async (dispatch) => {
  try {
    const response = await customAxios.get("/projects/");
    dispatch({
      type: types.GET_PROJECT,
      payload: response.data,
    });
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};
export const syncProjectFun = () => async (dispatch) => {
  try {
    const response = await customAxios.get("/projects/sync/");
    dispatch({
      type: types.GET_PROJECT,
      payload: response.data,
    });
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};

export const createProjectFun = (projectData) => async (dispatch) => {
  try {
    const response = await customAxios.post("projects/create/", projectData);
    dispatch({
      type: types.CREATE_PROJECT,
      payload: response.data,
    });
    toast.success("Project created successfully");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.detail);
    return { isSubmitButton: false };
  }
};
