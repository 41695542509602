export const Routes = {
  ListingView: { path: "/listing-view" },
  Settings: { path: "/settings" },
  UserDashboard: { path: "/user_dashboard" },
  UserDetail: { path: "/user_detail/:email" },
  ProjectDetail: { path: "/project_detail/:id" },
  Management: { path: "/management_reports" },
  Finance :{path: "/financial_reports"},
  Associate :{path: "/associate_reports"},
  ReqListing: { path: "/req_listing/:id" },
  Project: { path: "/project_listing" },
  Signin: { path: "/" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password/:token" },
  Lock: { path: "/lock" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" }
};
