import React, { useState, useEffect } from "react";
import {
  CheckIcon,
  XIcon,
  EyeIcon,
  DocumentDownloadIcon,
} from "@heroicons/react/outline";
import { CalendarIcon, HomeIcon, PlusIcon } from "@heroicons/react/solid";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  InputGroup,
  Col,
  Row,
  Spinner,
  Card,
  Nav,
  Tab,
} from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobleTable from "components/GlobleTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  addReqFun,
  getReqFun,
  editReqFun,
  getAllPublicHolidays,
} from "redux/requests/action";
import ReqDetailedView from "./ReqDetailedView";
import { CapitalizeFirstLetter } from "utils/Global";
import { useParams } from "react-router";
import { refreshUserInfoFun } from "redux/auth/action";
import PublicHolidayModal from "./PublicHolidayModal";
import "../../scss/customStyling.scss";
import ReqListingTable from "components/ReqListingTable";

export default () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const [object, setSelectedReqect] = useState(["sent", "received"]);
  const [tabValue, setTabValue] = useState("sent");
  const [showDefault, setShowDefault] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    { value: "casual", label: "Casual" },
    { value: "sick", label: "Sick" },
    { value: "annual", label: "Annual" },
  ]);
  const [isCreate, setIsCreate] = useState(false);

  let allReqs = useSelector((state) => state.reqs.allReqs.data);
  let authen = useSelector((state) => state.auth);
  const holidayList = useSelector((state) => state.reqs.allPublicHolidays);
  const [getData, setGetData] = useState(false);
  const [selectedReq, setSelectedReq] = useState({});
  const [userLeaves, setUserLeaves] = useState({});
  const [shouldOpenModal, setShouldOpenModal] = useState(true);
  const [showHolidayModal, setShowHolidayModal] = useState(false);

  let isAllowedAnnual = true;

  useEffect(() => {
    if (id && shouldOpenModal && allReqs?.data) {
      let filtered = allReqs?.data?.received_req?.find(
        (req) => req.id === parseInt(id)
      );
      if (!filtered) {
        const sentReqArray = Array.isArray(allReqs?.data?.sent_req)
          ? allReqs?.data?.sent_req
          : [allReqs?.data?.sent_req];

        filtered = sentReqArray.find((req) => req.id === parseInt(id));
      }
      if (filtered) {
        setSelectedReq(filtered);
        setShowDefault(true);
        setIsCreate(false);
        setShouldOpenModal(false);
      }
    }
  }, [id, allReqs]);

  useEffect(() => {
    if (authen.childbirthLeavesAllowed && authen.maritalStatus === "married") {
      setTypeOptions((prevOptions) => [
        ...prevOptions,
        { value: "childbirth", label: "Maternity/Paternity" },
      ]);
    }
  }, []);

  useEffect(() => {
    dispatch(getReqFun(1));
  }, [getData, tabValue]);

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: false,
    })
  );
  const handleClose = () => {
    setShowDefault(false);
  };
  const handleCloseHolidayModal = () => {
    setShowHolidayModal(false);
  };
  const onClickApproveDecline = async function (reqId, isApprove, status) {
    const result = await SwalWithBootstrapButtons.fire({
      icon: isApprove ? "success" : "error",
      title: "",
      text: isApprove ? "Do you want to approve?" : "Do you want to decline?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      dispatch(editReqFun(reqId, status, authen));
      setShowDefault(false);
    }
  };

  const onClickWithdraw = async function (reqId, status) {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "",
      text: "Do you want to withdraw?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      dispatch(editReqFun(reqId, status, authen));
    }
  };

  const handleAddRequest = () => {
    dispatch(refreshUserInfoFun());
    setShowDefault(true);
    setIsCreate(true);
    setGetData(!getData);
  };
  const handleAddHoliday = () => {
    setShowHolidayModal(true);
  };

  const customFormatter = (col) => {
    if (typeof col === "number") {
      if (Number.isInteger(col)) {
        return col.toString();
      } else {
        const formattedValue = `${col * 2} (0.5)`;
        return formattedValue;
      }
    }
    return "-";
  };

  const columnsList = [
    { dataField: "id", text: "ID", hidden: true, csvExport: false },
    {
      dataField: "leave_type",
      text: "Type",
      csvFormatter: (col) => (col ? col : "-"),
      formatter: (cellContent, row) => {
        let displayText;

        if (row.leave_type === "childbirth") {
          displayText = "Maternity/Paternity";
        } else {
          displayText = row.isHalf
            ? `${CapitalizeFirstLetter(row.leave_type)} (Half Day)`
            : `${CapitalizeFirstLetter(row.leave_type)} (Full Day)`;
        }

        return <span>{displayText}</span>;
      },
    },

    {
      dataField: "start_date",
      text: "Start",
      csvFormatter: (col) => (col ? moment(col).format("DD-MM-YYYY") : "-"),
      formatter: (col) => (col ? moment(col).format("DD-MM-YYYY") : "-"),
    },
    {
      dataField: "end_date",
      text: "End",
      hidden: false,
      csvExport: true,
      csvFormatter: (col) => (col ? moment(col).format("DD-MM-YYYY") : "-"),
      formatter: (col) => (col ? moment(col).format("DD-MM-YYYY") : "-"),
    },
    {
      dataField: "createdAt",
      text: "Submitted At",
      csvFormatter: (col) =>
        col ? moment(col).format("DD-MM-YYYY hh:mm a") : "-",
      formatter: (col) =>
        col ? moment(col).format("DD-MM-YYYY hh:mm a") : "-",
    },
    {
      dataField: "days",
      text: "Days",
      csvFormatter: customFormatter,
      formatter: customFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      csvFormatter: (col) => (col ? col : "-"),
      formatter: (cellContent, row) => {
        return (
          <div className="dotSpan">
            <span>{CapitalizeFirstLetter(row.status)}</span>
            <span className={`dot_${row.status}`}></span>
          </div>
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      hidden: true,
      csvExport: true,
      csvFormatter: (col) => (col ? col : "-"),
      formatter: (col) => (col ? col : "-"),
    },
  ];

  const [columns, setColumns] = useState(columnsList);
  const dateValidation = (start, end, type, isHalf) => {
    const start_date = moment(start).startOf("days");
    const end_date = moment(end).startOf("days"); //today
    const dailyInfo = [true, false, false, false, false, false, true];
    let totalDays = 0;
    dailyInfo.forEach((info, index) => {
      if (info === true) {
        let current = start_date.clone();
        if (current.isoWeekday() <= index) {
          current = current.isoWeekday(index);
        } else {
          current.add(1, "weeks").isoWeekday(index);
        }
        while (current.isSameOrBefore(end_date)) {
          current.day(7 + index);
          totalDays += 1;
        }
      }
    });
    let dateDiff = isHalf
      ? (moment(end).diff(moment(start), "days", true) + 1 - totalDays) / 2
      : moment(end).diff(moment(start), "days", true) + 1 - totalDays;

    let message = "";
    if (
      (type === "casual" && dateDiff > userLeaves.casual) ||
      (type === "sick" && dateDiff > userLeaves.sick) ||
      (type === "annual" && dateDiff > userLeaves.annual)
    ) {
      message = `${CapitalizeFirstLetter(
        type
      )} leave should be less than or equal to ${userLeaves[type]} days`;
    }
    if (dateDiff === 0) {
      message = "You can't add this request. Please select dates carefully.";
    }
    return { message, dateDiff };
  };
  const filters = {
    statusFilter:
      tabValue === "sent"
        ? [
            { value: "all", label: "All" },
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Approved",
              value: "approved",
            },
            {
              label: "Declined",
              value: "declined",
            },
            {
              label: "Withdrawn",
              value: "withdrawn",
            },
          ]
        : [
            { value: "all", label: "All" },
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Approved",
              value: "approved",
            },
            {
              label: "Declined",
              value: "declined",
            },
          ],
  };

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const FormikSchema = Yup.object().shape({
    leave_type: Yup.string().required("Leave type is required"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().when("leave_type", {
      is: (leave_type) => leave_type !== "childbirth",
      then: Yup.date()
        .min(Yup.ref("start_date"), "End date can't be before start date")
        .required("End Date is required"),
      otherwise: Yup.date(),
    }),
    description: Yup.string()
      .trim()
      .required("Description is required")
      .min(3, "Too Short!")
      .max(200, "Too Long! Only 200 characters are allowed"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      leave_type: "",
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
      description: "",
      isHalf: false,
    },
    validationSchema: FormikSchema,
    onSubmit: async (values, action) => {
      const { start_date, end_date, leave_type } = values;
      const start_dateMoment = moment(start_date);
      const end_dateMoment = moment(end_date);
      let dateDiff = 0;
      while (start_dateMoment.isSameOrBefore(end_dateMoment, "day")) {
        if (start_dateMoment.day() !== 0 && start_dateMoment.day() !== 6) {
          dateDiff++;
        }
        start_dateMoment.add(1, "day");
      }

      let { message } = dateValidation(
        values.start_date,
        values.end_date,
        values.leave_type,
        values.isHalf
      );

      let updateObj = Object.assign(values);
      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = await dispatch(addReqFun(updateObj, authen));
      if (!isSubmit.isSubmitButton) {
        setDisableSubmitButton(isSubmit.isSubmitButton);
        handleClose();
        Formik.resetForm();
      }
    },
  });

  useEffect(() => {
    setUserLeaves({
      annual:
        authen?.leaveStatistics?.annualAllowed -
        authen?.leaveStatistics?.annualUtilized,
      casual:
        authen?.leaveStatistics?.casualAllowed -
        authen?.leaveStatistics?.casualUtilized,
      sick:
        authen?.leaveStatistics?.sickAllowed -
        authen?.leaveStatistics?.sickUtilized,
    });
  }, [authen]);

  useEffect(() => {
    if (!isAllowedAnnual) {
      setTypeOptions([
        ...typeOptions.filter((item) => item.value !== "annual"),
      ]);

      if (!authen.childbirthLeavesAllowed) {
        setTypeOptions([
          ...typeOptions.filter((item) => item.value !== "childbirth"),
        ]);
      }
    }

    if (!authen?.isAdmin) {
      if (authen?.isSupervisor) {
        setSelectedReqect(["sent", "received"]);
      } else {
        setSelectedReqect(["sent"]);
      }

      setTabValue("sent");
    } else if (authen.isAdmin) {
      setSelectedReqect(["received"]);
      setTabValue("received");
    }
  }, [authen]);

  useEffect(() => {
    dispatch(getAllPublicHolidays());
  }, [dispatch]);

  useEffect(() => {
    let arry = [
      {
        dataField: "user.name",
        text: "Name",
        sort: true,
        csvFormatter: (col) => (col ? col : "-"),
        formatter: (col) => (col ? col : "-"),
      },
      // {
      //   dataField: "user.email",
      //   text: "Email",
      //   csvFormatter: (col) => (col ? col : "-"),
      //   formatter: (col) => (col ? col : "-"),
      // },
      // {
      //   dataField: "user.designation",
      //   text: "Designation",
      //   csvFormatter: (col) => (col ? col : "-"),
      //   formatter: (col) => (col ? col : "-"),
      // },
    ];
    let actionObj = [
      {
        dataField: "action",
        text: "Action",
        csvExport: false,
        formatter: (cellContent, row) => {
          return (
            <>
              <span title="View">
                <EyeIcon
                  className="icon icon-xs me-2"
                  role="button"
                  onClick={() => {
                    setSelectedReq(row);
                    setShowDefault(true);
                    setIsCreate(false);
                  }}
                />
              </span>
              {tabValue === "sent" && row.status === "pending" && (
                <>
                  <span title="Withdraw">
                    <DocumentDownloadIcon
                      className="icon icon-xs me-3"
                      role="button"
                      title=""
                      onClick={() => onClickWithdraw(row.id, "withdrawn")}
                    />
                  </span>
                </>
              )}
              {tabValue === "received" && row.status === "pending" ? (
                <>
                  <span title="Approve">
                    <CheckIcon
                      className="icon icon-xs me-3"
                      role="button"
                      onClick={() => {
                        onClickApproveDecline(row.id, true, "approved");
                      }}
                    />
                  </span>
                  <span title="Decline">
                    <XIcon
                      className="icon icon-xs"
                      role="button"
                      onClick={() => {
                        onClickApproveDecline(row.id, false, "declined");
                      }}
                    />
                  </span>
                </>
              ) : null}
            </>
          );
        },
      },
    ];
    if (authen?.isAdmin || (authen?.isSupervisor && tabValue === "received")) {
      setColumns([...arry, ...columnsList, ...actionObj]);
    }
    if (tabValue === "sent" && !authen?.isAdmin) {
      setColumns([...columnsList, ...actionObj]);
    }
  }, [tabValue]);

  const isValid = (currentDate) => {
    const formattedDate = moment(currentDate).format("YYYY-MM-DD");

    // Filter the holidayList based on the authen.country (in this case, "PK" for Pakistan)
    const filteredHolidays = holidayList.data.filter(

      (holiday) => holiday.region === authen.country.toLowerCase()

    );

    const isHoliday = filteredHolidays.some((holiday) =>
      moment(formattedDate).isBetween(
        holiday.start_date,
        holiday.end_date,
        null,
        "[]"
      )
    );

    return (
      currentDate.isoWeekday() !== 6 &&
      currentDate.isoWeekday() !== 7 &&
      currentDate.isSameOrAfter(
        moment(Formik.values.today).subtract(1, "months").format("YYYY-MM-DD")
      ) &&
      // currentDate.isBefore(
      //   moment(Formik.values.today).add(1, "months").format("YYYY-MM-DD")
      // ) &&
      !isHoliday // Check if the date is in the holidayList for the specific region
    );
  };

  const renderDay = (props, currentDate, selectedDate) => {
    const formattedDate = moment(currentDate).format("YYYY-MM-DD");
    const isWeekend =
      currentDate.isoWeekday() === 6 || currentDate.isoWeekday() === 7;

    // Filter the holidayList based on the authen.country (in this case, "PK" for Pakistan)
    const filteredHolidays = holidayList.data.filter(

      (holiday) => holiday.region === authen.country.toLowerCase()

    );
    // Check if the date is a holiday for the filtered region
    const isHoliday = filteredHolidays.some((holiday) => {
      return moment(formattedDate).isBetween(
        holiday.start_date,
        holiday.end_date,
        null,
        "[]"
      );
    });

    // Define a class to change the color of holidays to red
    const dayClass = isHoliday ? "holiday-day" : "";

    // Define the cursor style
    const cursorStyle = isHoliday || isWeekend ? "not-allowed" : "pointer";

    return (
      <td
        {...props}
        className={`rdtDay ${
          isWeekend && !isHoliday ? "disabled" : ""
        } ${dayClass}`}
        style={{ cursor: cursorStyle }}
      >
        {currentDate.date()}
        {isHoliday && (
          <div className="holiday-tooltip">
            {
              filteredHolidays.find((holiday) =>
                moment(formattedDate).isBetween(
                  holiday.start_date,
                  holiday.end_date,
                  null,
                  "[]"
                )
              ).name
            }
          </div>
        )}
      </td>
    );
  };

  const handleValue = (val) => {
    setTabValue(val);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="pt-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Requests</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>Request List</h4>
            </div>
          </div>
        </div>
        {authen?.isAdmin === false && tabValue === "sent" && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <Button
              variant="tertiary"
              size="sm"
              className="d-inline-flex align-items-center"
              onClick={handleAddRequest}
            >
              <PlusIcon className="icon icon-xs me-2" /> Add Request
            </Button>
          </div>
        )}
        {authen?.isAdmin === true && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <Button
              variant="tertiary"
              size="sm"
              className="d-inline-flex align-items-center"
              onClick={handleAddHoliday}
            >
              <PlusIcon className="icon icon-xs me-2" /> Add Holidays
            </Button>
          </div>
        )}
      </div>
      <Card className="p-3 mb-3">
        <Tab.Container activeKey={tabValue}>
          <Row>
            <Col lg={12}>
              <Nav className="nav-tabs">
                {object?.map((val, index) => {
                  return (
                    <Nav.Item key={index}>
                      <Nav.Link
                        eventKey={val}
                        onClick={() => {
                          handleValue(val);
                        }}
                        className="mb-sm-3 mb-md-0"
                      >
                        {CapitalizeFirstLetter(val)}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
            {/* <Col
              lg={{ span: 3, offset: 9 }}
              md={{ span: 4, offset: 8 }}
              sm={12}
              xs={12}
            >
              <Form.Group id="status_type" className="mt-3">
                <Form.Select className="mb-0" name="statusType">
                  {statusOptions.map((val, i) => {
                    return (
                      <>
                        <option value={val} key={i}>
                          {CapitalizeFirstLetter(val)}{" "}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col lg={12}>
              <Tab.Content>
                {object?.map((val, index) => {
                  return (
                    <Tab.Pane key={index} eventKey={val} className="py-4">
                      <ReqListingTable
                        data={
                          tabValue === "sent"
                            ? allReqs?.data?.sent_req
                            : allReqs?.data?.received_req
                        }
                        filters={filters}
                        parentComponent={"requestListing"}
                        columns={columns}
                        totalEntries={allReqs?.totalEntries}
                        totalPage={
                          tabValue === "sent"
                            ? allReqs?.data?.sent_req_total_number_of_pages
                            : allReqs?.data?.received_req_total_number_of_pages
                        }
                        tableType={"doctor"}
                        currentPage={1}
                      />
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">
            {isCreate
              ? "Add Request"
              : selectedReq.isHalf
              ? `${CapitalizeFirstLetter(
                  selectedReq.leave_type
                )} Leave (Half Day)`
              : `${CapitalizeFirstLetter(
                  selectedReq.leave_type
                )} Leave (Full Day)`}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={Formik.handleSubmit} className="mt-0 signUp_Form">
          <Modal.Body>
            {isCreate ? (
              <>
                <Form.Group id="leave_type" className="mb-2 mt-0">
                  <Form.Label>Leave Type</Form.Label>
                  <Form.Select
                    className="mb-0"
                    name="leave_type"
                    onChange={(e) => {
                      Formik.setFieldValue("leave_type", e.target.value);
                    }}
                  >
                    <option value="">Select Type</option>
                    {typeOptions?.map((item, i) => {
                      return (
                        <>
                          <option
                            value={item.value}
                            key={i}
                            disabled={userLeaves[item.value] === 0}
                          >
                            {CapitalizeFirstLetter(item.label)}{" "}
                            {item.value !== "childbirth" &&
                              userLeaves[item.value] > 0 &&
                              ` (${userLeaves[item.value]})`}
                          </option>
                        </>
                      );
                    })}
                    <option value="unpaid">Unpaid </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {Formik?.errors?.leave_type}
                  </Form.Control.Feedback>
                </Form.Group>
                {Formik.values.leave_type !== "childbirth" && (
                  <>
                    <Form.Group id="isHalf" className="mb-1">
                      <Form.Check
                        type="checkbox"
                        label="Half Day Leave"
                        name="isHalf"
                        onChange={(e) => {
                          Formik.setFieldValue("isHalf", e.target.checked);
                        }}
                      />
                    </Form.Group>
                  </>
                )}
                <Form.Group id="start_date">
                  <Form.Label>Select start date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={(date) => {
                      Formik.setFieldValue(
                        "start_date",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    isValidDate={isValid}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <CalendarIcon className="icon icon-xs" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          placeholder="YYYY-MM-DD"
                          value={Formik.values.start_date}
                          onFocus={openCalendar}
                          onChange={() => {}}
                        />
                        <Form.Control.Feedback type="invalid">
                          {Formik?.errors?.start_date}
                        </Form.Control.Feedback>
                      </InputGroup>
                    )}
                    renderDay={renderDay}
                    closeOnSelect={true}
                  />
                </Form.Group>
                {Formik.values.leave_type !== "childbirth" && (
                  <>
                    <Form.Group id="end_date" className="mb-2">
                      <Form.Label>Select end date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={(date) => {
                          Formik.setFieldValue(
                            "end_date",
                            moment(date).format("YYYY-MM-DD")
                          );
                        }}
                        isValidDate={isValid}
                        initialViewDate={
                          Formik.values.end_date || Formik.values.start_date
                        }
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text>
                              <CalendarIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              placeholder="YYYY-MM-DD"
                              value={Formik.values.end_date}
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                            <Form.Control.Feedback type="invalid">
                              {Formik?.errors?.end_date}
                            </Form.Control.Feedback>
                          </InputGroup>
                        )}
                        renderDay={renderDay}
                        closeOnSelect={true}
                      />
                    </Form.Group>
                  </>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Add reason here..."
                    value={Formik.values.description}
                    onChange={(e) => {
                      Formik.setFieldValue("description", e.target.value);
                    }}
                    onBlur={Formik.handleBlur("description")}
                  />
                  {Formik.touched.description && Formik.errors.description && (
                    <Form.Control.Feedback type="invalid">
                      {Formik.errors.description}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </>
            ) : (
              <>
                <ReqDetailedView
                  obj={selectedReq}
                  isView={authen?.isAdmin}
                  onClickApproveDecline={onClickApproveDecline}
                  email={authen.email}
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isCreate && (
              <>
                <Button
                  variant="secondary"
                  type="submit"
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Send Request"
                  )}
                </Button>
              </>
            )}
            <div className="d-flex flex-start">
              {!isCreate &&
                authen.id !== selectedReq.user?.id &&
                selectedReq.status === "pending" && (
                  <div className="d-flex flex-start">
                    <Button
                      className="btn btn-success text-white"
                      onClick={() =>
                        onClickApproveDecline(selectedReq.id, true, "approved")
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      className="btn btn-danger ms-2"
                      onClick={() =>
                        onClickApproveDecline(selectedReq.id, false, "declined")
                      }
                    >
                      Decline
                    </Button>
                  </div>
                )}
            </div>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <PublicHolidayModal
        show={showHolidayModal}
        handleClose={handleCloseHolidayModal}
      />
    </>
  );
};
