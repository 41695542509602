import * as types from "./contants";
import { toast } from "react-toastify";
import customAxios from "../../config/index";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

//Add User Function
export const addUserFun = (data, leaveData) => async (dispatch) => {
  try {
    const response = await customAxios.post("/users/create/", data);

    const addedUser = [
      {
        email: data.email,
        name: data.name,
      },
    ];

    const message = `Please login to https://eportal.decklaration.com/ with the following credentials: <br> <br> email: ${data.email} <br> password: ${data.password}`;

    // dispatch(sendEmail("Decklaration e-portal invitation", addedUser, message));

    dispatch(getUserFun());
    toast.success("Successfully Created");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.response.data.message);
    return { isSubmitButton: false };
  }
};

// Add Doctor Function
// export const addUserFun = (data, leaveData) => async (dispatch) => {
//   try {
//     const { user } = await createUserWithEmailAndPassword(
//       auth,
//       data.email,
//       data.password
//     );

//     const addedUser = [
//       {
//         email: data.email,
//         name: data.name,
//       },
//     ];

//     const message = `Please login to https://eportal.decklaration.com/ with the following credentials: <br> <br> email: ${data.email} <br> password: ${data.password}`;

//     dispatch(sendEmail("Decklaration e-portal invitation", addedUser, message));

//     delete data.password;

//     setDoc(doc(db, "users", user.uid), {
//       ...data,
//       leaves: leaveData,
//       createdAt: new Date(),
//       isAdmin: false,
//       isActive: true,
//     });

//     dispatch(getUserFun());
//     toast.success("Successfully Created");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message.replace("Firebase: ", ""));
//     return { isSubmitButton: false };
//   }
// };

// Edit User Fuction
export const editUsersFun = (data, id) => async (dispatch) => {
  try {
    const response = await customAxios.patch(`users/edit/${id}`, data);
    dispatch({
      type: types.EDIT_USER,
      payload: response.data,
    });
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

export const updateHubstaffandAvazaId = (data) => async (dispatch) => {
  try {
    const response = await customAxios.put(`users/update-hubstaff-avaza-ids/`, {
      user_email: data,
    });

    dispatch({
      type: types.UPDATE_HUBSTAFF_AWAZA,
      payload: response.data.data,
    });

    dispatch(getUserFun());

    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// User Status
export const toggleUserAccountStatus = (data, id) => async (dispatch) => {
  try {
    const formData = new FormData();

    formData.append("user_id", id);
    formData.append("is_active", data.isActive);

    const response = await customAxios.patch(
      `users/update-user-activate-status/`,
      formData
    );

    dispatch({
      type: types.TOGGLE_USER_ACCOUNT_STATUS,
      payload: response.data.data,
    });

    dispatch(getUserFun());

    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    return { isSubmitButton: false };
  }
};

// // Add Doctor Function
// export const editUsersFun = (data, leaveData, userId) => async (dispatch) => {
//   delete data.password;

//   try {
//     const reqDoc = doc(db, "users", userId);
//     const newFields = { ...data, leaves: leaveData };
//     await updateDoc(reqDoc, newFields);
//     dispatch(getUserFun());
//     toast.success("Successfully Updated");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message.replace("Firebase: ", ""));
//     return { isSubmitButton: false };
//   }
// };

export const toggleAnnualLeave = (leaveData, userId) => async (dispatch) => {
  try {
    const reqDoc = doc(db, "users", userId);
    const newFields = { leaves: leaveData };
    await updateDoc(reqDoc, newFields);
    dispatch(getUserFun());
    toast.success("Successfully Updated");
    return { isSubmitButton: false };
  } catch (error) {
    toast.error(error.message.replace("Firebase: ", ""));
    return { isSubmitButton: false };
  }
};

export const resetLeaves = (leaveData, userId) => async (dispatch) => {
  try {
    const reqDoc = doc(db, "users", userId);
    const newFields = { leaves: leaveData };
    await updateDoc(reqDoc, newFields);
  } catch (error) {

  }
};

// export const toggleAccountStatus = (val, userId) => async (dispatch) => {
//   try {
//     const reqDoc = doc(db, "users", userId);
//     const newFields = { isActive: val };
//     await updateDoc(reqDoc, newFields);
//     dispatch(getUserFun());
//     toast.success("Successfully Updated");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message.replace("Firebase: ", ""));
//     return { isSubmitButton: false };
//   }
// };

export const getUserFun =
  (page, search, filter = "") =>
  async (dispatch) => {
    try {
      let URL = `users/${filter}`;
      if (page) {
        URL = URL + `&page${page}`;
      }
      if (search) {
        URL = URL + `&search${search}`;
      }
      const response = await customAxios.get(URL);
      dispatch({
        type: types.GET_User,
        payload: response.data,
      });
      // if (response.data.data.length == 0) {
      //   toast.error("No Record Found!");
      // }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      return { isSubmitButton: false };
    }
  };

// export const getUserFun = () => async (dispatch) => {
//   try {
//     const usersCollectionRef = collection(db, "users");
//     const data = await getDocs(usersCollectionRef);
//     const parsed_data = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

//     dispatch({
//       type: types.GET_User,
//       payload: {
//         data: parsed_data,
//       },
//     });
//     // if (parsed_data.length === 0) {
//     //   toast.error("No Record Found!");
//     // }
//   } catch (error) {
//     toast.error(error.message.replace("Firebase: ", ""));
//     return { isSubmitButton: false };
//   }
// };

export const updateUserLeaves =
  (userId, leaveType, days) => async (dispatch) => {
    try {
      const userDoc = doc(db, "users", userId);
      const docSnap = await getDoc(userDoc);
      const data = docSnap.data();

      let updateUserLeaves = data.leaves;

      Object.keys(updateUserLeaves).forEach((key) => {
        if (key.toLowerCase() === (leaveType + "Utilized").toLowerCase()) {
          updateUserLeaves[key] = updateUserLeaves[key] + days;
        }
      });

      const newFields = { leaves: updateUserLeaves };
      await updateDoc(userDoc, newFields);
    } catch (error) {
      toast.error(error.message);
    }
  };

// // // Delete Doctor Function
// export const delUserFun = (uid) => async (dispatch) => {
//   try {
//     const userDoc = doc(db, "users", uid);
//     await deleteDoc(userDoc);

//     toast.success("Successfully Removed");
//     return { isSubmitButton: false };
//   } catch (error) {
//     toast.error(error.message.replace("Firebase: ", ""));
//     return { isSubmitButton: false };
//   }
// };
