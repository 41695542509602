import React from "react";
import { Spinner } from "react-bootstrap";

function Loader() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Spinner
        animation="border"
        role="status"
        style={{ height: "100px", width: "100px" }}
      >
        <span className="sr-only"></span>
      </Spinner>
    </div>
  );
}

export default Loader;
