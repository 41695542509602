import React, { useEffect, useState } from "react";
import ReportTablePagination from "components/ReportTablePagination";
import ReportTable from "components/ReportTable";
import TableData from "../../../Data/TableData.json";
import chartData2 from "../../../Data/Data_2.json";
import { Col, Row } from "react-bootstrap";
import "../../../scss/Financiallevel/FinancialLevel.scss";
import LineChartComponent from "components/LineChart";
import Select, { components } from "react-select";
import { CubeIcon } from "@heroicons/react/solid";
import CandlestickChart from "components/CandleStickChart";
import DateFilterMonth from "components/DateFilterMonth";
import { getProjectFun } from "redux/projects/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const dropdownOptions = [
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
  { value: "option4", label: "Option 4" },
  { value: "option5", label: "Option 5" },
];

const data = [
  // Replace the following rows with your actual data
  ["Day", "Low", "Open", "Close", "High"],
  ["2023-07-20", 100, 120, 130, 150],
  ["2023-07-21", 300, 270, 210, 140],
  ["2023-07-22", 115, 140, 185, 255],
  ["2023-07-23", 120, 140, 230, 300],
  ["2023-07-24", 300, 245, 180, 120],
  ["2023-07-25", 115, 135, 188, 220],
  ["2023-07-26", 123, 151, 165, 300],
  ["2023-07-27", 285, 230, 170, 120],
];

function FinancialLevel() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.allProjects);
  const [projectOptions, setProjectOptions] = useState([]);

  const DropdownIndicator = (props) => {
    const Icon = props.selectProps.indicatorIcon;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon width={20} height={20} />
        </components.DropdownIndicator>
      )
    );
  };
  useEffect(() => {
    if (Array.isArray(projects) && projects.length > 0) {
      const newProjectOptions = projects.map((project) => ({
        label: project.name,
        value: project.id,
      }));
      setProjectOptions(newProjectOptions);
    }
  }, [projects]);
  return (
    <>
      <h3 className="fw-bolder">Financial Level</h3>
      <DateFilterMonth />
      <div className="mt-3">
        <Row>
          <Col lg={6}>
            <h5>Financial Summary</h5>
            <ReportTable
              columns={TableData.columns_6}
              data={TableData.data_6}
            />
          </Col>
          <Col lg={4} className="mt-2">
            <h6 className="mb-4">Performance Last 8 Week</h6>
            <LineChartComponent
              data={chartData2.data}
              lines={chartData2.lines}
            />
          </Col>
        </Row>
      </div>
      <Row className="mt-5">
        <Col lg={6}>
          <h4>Projects Profitability</h4>
          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Projects</p>
            <Select
              className="custom-dropdown form-select"
              options={projectOptions}
              placeholder="Select Projects"
              isSearchable={true}
              components={{ DropdownIndicator }}
              indicatorIcon={CubeIcon}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </div>
          <div className="mt-3">
            <ReportTablePagination
              columns={TableData.columns_7}
              data={TableData.data_7}
            />
          </div>
        </Col>
        <Col lg={4} className="mt-8">
          <LineChartComponent data={chartData2.data} lines={chartData2.lines} />
        </Col>
      </Row>
      <Row>
        <h5 className="mt-5">Cash-flow Waterfall</h5>
        <DateFilterMonth />
        <CandlestickChart data={data} />
      </Row>
    </>
  );
}

export default FinancialLevel;
