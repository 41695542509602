import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import "./Styles/LineChart.scss";

const LineChartComponent = ({ data }) => {
  // Check if data is valid (not null or undefined)
  if (!data || Object.keys(data).length === 0) {
    // return <div>No data available</div>;
    return false;
  }

  // Format the data into an array of objects with the required keys
  const chartData = Object.keys(data).map((date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });

    const utilization =
      data[date].utilization !== undefined
        ? data[date].utilization.toFixed(2)
        : undefined;
    const activity =
      data[date].activity !== undefined
        ? data[date].activity.toFixed(2)
        : undefined;

    return {
      date: `${day} ${month}`,
      activity,
      utilization,
      rawDate: date,
    };
  });

  // Sort the data by the raw date in ascending order
  chartData.sort((a, b) => (a.rawDate < b.rawDate ? -1 : 1));

  return (
    <div className="line-chart-container">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <XAxis dataKey="date" />
          <YAxis
            yAxisId="left"
            label={{ value: "Value", angle: -90, position: "insideLeft" }}
            tickFormatter={(value) => `${value}%`}
            domain={[0, 100]}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={(value) => `Date: ${value}`}
            formatter={(value) => `${value}%`}
          />
          <Legend />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="utilization"
            name="Utilization"
            stroke="#8884d8"
            strokeWidth={4}
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="activity"
            name="Activity"
            stroke="#82ca9d"
            strokeWidth={4}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
