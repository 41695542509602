import customAxios from "config";
import { toast } from "react-toastify";

export const getUserProjects = async (email) => {
  try {
    const response = await customAxios.get(`projects/?email=${email}`);
    return response.data;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};

export const updateProjectMember = async (data, isUnassign) => {
  try {
    const response = await customAxios.put("/projects/update-member/", data);
    if (!isUnassign) {
      toast.success(response.data.detail);
    }
  } catch (err) {
    if (!isUnassign) {
      toast.error(err.response.data.detail);
    } else throw err;
  }
};
