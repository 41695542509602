import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

export function PieChartComponent(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  const COLORS = ["#165DFF", "#E2725B"]; // Colors for billable and non-billable sections

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          style={{ fontSize: "24px" }}
        >
          <tspan fill="#333">Utilization</tspan>
          <tspan x={cx} dy={24} style={{ fontWeight: "bold" }}>
            86%
          </tspan>
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <g>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >{`${value}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
            style={{ fontSize: "14px", fontStyle: "italic" }}
          ></text>
        </g>
      </g>
    );
  };

  return (
    <div>
      <PieChart width={props.width} height={props.height}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={props.data}
          cx={props.cx? props.cx : 250}
          cy={props.cy? props.cy : 250}
          innerRadius={80}
          outerRadius={120}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div>
        <span
          style={{
            display: "inline-block",
            width: "12px",
            height: "12px",
            backgroundColor: COLORS[0],
            marginRight: "5px",
            marginLeft: "24px",
          }}
        ></span>
        <span>Billable</span>
      </div>
      <div>
        <span
          style={{
            display: "inline-block",
            width: "12px",
            height: "12px",
            backgroundColor: COLORS[1],
            marginRight: "5px",
            marginLeft: "24px",
          }}
        ></span>
        <span>Non-billable</span>
      </div>
    </div>
  );
}
