import * as types from "./contants";

const initialState = {
  refresh: "",
  token: "",
  id: "",
  username: "",
  email: "",
  name: "",
  phone: "",
  cnic: "",
  gender: "",
  dob: "",
  joinDate : "",
  address : "",
  designation: "",
  skillSet: "",
  isActive: "",
  isSupervisor: "",
  isPartTime: "",
  isIntern: "",
  isAccountSetup: "",
  supervisors: "",
  hubStaffPresence: "",
  awazaPresence: "",
  hubStaffId: "",
  awazaId: "",
  createdAt: "",
 
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.ACC_SETUP: {
      let dataa = action.payload;
      return {
        ...state,
        ...dataa,
      };
    }
    case types.REFRESH_PROFILE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
