  // reducers.js
  import {
      ASSOCIATE_LEVEL_REQUEST,
      ASSOCIATE_LEVEL_SUCCESS,
      ASSOCIATE_LEVEL_FAILURE,
    } from './constants';
    
    const initialState = {
      loading: false,
      data: {
        user_data: {
          activity: 0,
          timesheet: 0,
          non_billable_hours: 0,
          billable_hours: 0,
          utilization: 0,
          projects: [],
          project_profits: {},
        },
      },
      error: null,
    };
    
    export const associateReportReducer = (state = initialState, action) => {
      switch (action.type) {
        case ASSOCIATE_LEVEL_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
    
        case ASSOCIATE_LEVEL_SUCCESS:
          return {
            ...state,
            loading: false,
            data: action.payload, 
            error: null,
          };
    
        case ASSOCIATE_LEVEL_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        default:
          return state;
      }
    };
    