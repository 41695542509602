import React from 'react';
import { Chart } from 'react-google-charts';

const CandlestickChart = ({ data }) => {
  const options = {
    legend: 'none',
    candlestick: {
      fallingColor: { strokeWidth: 0, fill: '#F53F3F' }, // Red color for falling candles
      risingColor: { strokeWidth: 0, fill: '#00B42A' },  // Green color for rising candles
    },
    chartArea: {
      width: '90%',
      height: '80%',
    }
  };

  return (
    <div>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="CandlestickChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  );
};

export default CandlestickChart;
