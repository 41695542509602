import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { IdentificationIcon, UserGroupIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { Row, Col, Button } from "react-bootstrap";

import { ChartPieIcon, ClockIcon, NewspaperIcon } from "@heroicons/react/solid";

// import chartData from "../../../Data/Data_1.json";
// import chartData2 from "../../../Data/Data_2.json";
import LineChartComponent from "components/LineChart";
import ReportTablePagination from "components/ReportTablePagination";
// import TableData from "../../../Data/TableData.json";
import "../../../scss/managementlevel/past.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProjectFun } from "redux/projects/action";
import Loader from "components/Loader";
import { getUserFun } from "redux/user/action";
import { getAllSupervisors, getTeamMembers } from "redux/employee/action";
import { getManagementLevelReport } from "redux/reports/Associate_Level/Management_level/action";
import reportCallback from "../../../assets/img/report-callback.svg";
import CallBackPlaceholder from "components/CallBackPlaceholder";

// const customDate = new Date("2023-09-17");

function formatDate(date) {
  const options = { month: "short", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

function getPreviousWeekDates() {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const diffToPreviousSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
  const previousWeekEnd = new Date(currentDate);
  previousWeekEnd.setDate(currentDate.getDate() - diffToPreviousSaturday - 1);
  const previousWeekStart = new Date(previousWeekEnd);
  previousWeekStart.setDate(previousWeekEnd.getDate() - 6);

  // Format the dates as strings
  const startDateString = formatDate(previousWeekStart);
  const endDateString = formatDate(previousWeekEnd);

  return { start: startDateString, end: endDateString };
}

function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day

  return `${year}-${month}-${day}`;
}

function PastContent() {
  const dispatch = useDispatch();
  const [supervisorsOption, setSupervisorsOption] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const initialInterval = getPreviousWeekDates();
  const [intervalStart, setIntervalStart] = useState(initialInterval.start);
  const [intervalEnd, setIntervalEnd] = useState(initialInterval.end);
  const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(true);
  const allSupervisors = useSelector((state) => state.employees.allSupervisors);
  const teamMembers = useSelector((state) => state.employees.teamMembers);
  const [supervisorId, setSupervisorId] = useState("");
  const [applied, setApplied] = useState(false);
  //const reportData = useSelector((state) => state.managemenLevel);
  const [reportData, setReportData] = useState([null]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [employee, setEmployee] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const [isFilterDisabled, setFilter] = useState(false);
  let userData = useSelector((state) => state.users.allUsers);

  const project_columns = [
    { title: "Project Name", field: "name" },
    { title: "Timesheet Hours", field: "timesheet" },
    { title: "Billable", field: "billable_hours" },
    { title: "Non-Billable", field: "non_billable_hours" },
    { title: "Activity", field: "activity" },
    { title: "Utilization", field: "utilization" },
  ];

  useEffect(() => {
    dispatch(getProjectFun());
    dispatch(getUserFun());
    dispatch(getAllSupervisors());
  }, [dispatch]);

  useEffect(() => {
    if (supervisorId) {
      dispatch(getTeamMembers(supervisorId));
    } else {
      const newUserOptions = userData.map((user) => ({
        label: user.name,
        value: user.email,
      }));
      setEmployeeOptions(newUserOptions);
    }

    setEmployee(null);
  }, [supervisorId, userData]);
  /** For Getting current employee Data*/
  const handleEmployeeSelect = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setEmployee(selectedOption);
  };
  const handleSupervisorSelect = (selectedOption) => {
    setSupervisorId(selectedOption?.value);
    setSupervisor(selectedOption);
  };

  const applyDataFilter = () => {
    if (employeeId) {
      setIsLoading(true);
      setFilter(true);
      const formattedEndDate = formatDateToYYYYMMDD(new Date(intervalEnd));
      const formattedStartDate = formatDateToYYYYMMDD(new Date(intervalStart));
      const requestBody = {
        end_of_week_date: formattedEndDate,
        start_of_week_date: formattedStartDate,
        employee_email: employeeId,
      };

      dispatch(getManagementLevelReport(requestBody))
        .then((response) => {
          setReportData(response);
          setIsLoading(false);
          setFilter(false);
          setApplied(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setFilter(false);
          console.error("Error fetching data:", error);
        });
    } else {
      toast.error("Please Select Employee");
    }
  };
  const resetDataFilter = () => {
    if (employeeId || supervisorId) {
      setApplied(false);
      setReportData(null);
      setIsLoading(false);
      setFilter(false);
      setEmployeeId(null);
      setSupervisorId(null);
      setSupervisor(null);
      setEmployee(null);
    }
  };

  useEffect(() => {
    // When the projects data changes, update projectOptions
    if (Array.isArray(allSupervisors) && allSupervisors.length > 0) {
      const newUserOptions = allSupervisors.map((user) => ({
        label: user.name,
        value: user.id,
        // value: user.email,
      }));
      setSupervisorsOption(newUserOptions);
    }
  }, [allSupervisors]);

  useEffect(() => {
    // When the projects data changes, update projectOptions
    if (Array.isArray(teamMembers) && teamMembers.length > 0) {
      const newUserOptions = teamMembers.map((user) => ({
        label: user.name,
        value: user.email,
      }));

      setEmployeeOptions(newUserOptions);
    } else {
      setEmployeeOptions([]);
    }
  }, [teamMembers]);

  // const apply_data_filter = () => {
  //   if (employeeId == null) {
  //     toast.error("Please Select Employee");
  //   } else {
  //     setApplied(true);
  //   }
  //   debugger;
  // };

  const currentDate = new Date();

  const moveIntervalLeft = () => {
    const currentStart = new Date(intervalStart);
    const currentEnd = new Date(intervalEnd);
    currentStart.setDate(currentStart.getDate() - 7);
    currentEnd.setDate(currentEnd.getDate() - 7);
    setIntervalStart(formatDate(currentStart));
    setIntervalEnd(formatDate(currentEnd));

    // Check if the new interval is on the previous week
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
    if (currentStart <= currentDate - oneWeekInMilliseconds) {
      setIsRightButtonDisabled(false);
      setApplied(false);
    } else {
      setIsRightButtonDisabled(true);
    }
  };

  const moveIntervalRight = () => {
    const currentStart = new Date(intervalStart);
    const currentEnd = new Date(intervalEnd);
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds

    // Calculate the new interval dates
    const newStart = new Date(currentStart.getTime() + oneWeekInMilliseconds);
    const newEnd = new Date(currentEnd.getTime() + oneWeekInMilliseconds);

    // Check if the new start date is after the week before the previous week
    const weekBeforePrevious = new Date(getPreviousWeekDates().start);
    weekBeforePrevious.setDate(weekBeforePrevious.getDate() - 7);

    if (newStart > weekBeforePrevious) {
      setIsRightButtonDisabled(true);
      setApplied(false);
      setEmployeeId(null);
      setEmployee(null);
    } else {
      setIsRightButtonDisabled(false);
    }

    // Update the interval dates
    setIntervalStart(formatDate(newStart));
    setIntervalEnd(formatDate(newEnd));
  };

  const DropdownIndicator = (props) => {
    const Icon = props.selectProps.indicatorIcon;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon width={20} height={20} />
        </components.DropdownIndicator>
      )
    );
  };

  function getUtilizationGrade(utilization) {
    if (utilization >= 61) {
      return "A+";
    } else if (utilization >= 40) {
      return "A";
    } else {
      return "B";
    }
  }
  function getActivityGrade(activity) {
    if (activity > 50) {
      return "A+";
    } else if (activity >= 40 && activity <= 50) {
      return "A";
    } else if (activity >= 25 && activity <= 39) {
      return "B";
    } else if (activity >= 0 && activity <= 24) {
      return "B";
    }
  }

  function getPadding(grade) {
    return grade === "A+" ? "4px 5px" : "2px 8px";
  }

  return (
    <>
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex align-items-center">
          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Supervisors</p>
            <Select
              className="custom-dropdown form-select"
              placeholder="Select Supervisors"
              options={[...supervisorsOption].sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              isSearchable={false}
              components={{ DropdownIndicator }}
              indicatorIcon={UserGroupIcon}
              value={supervisor}
              onChange={handleSupervisorSelect}
              styles={{
                control: (provided) => ({
                  ...provided,
                  cursor: "pointer", // Change cursor to pointer
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  cursor: "pointer",
                  width: "30px",
                }),

                indicatorSeparator: () => ({}),
                option: (provided) => ({
                  ...provided,
                  cursor: "pointer", // Change cursor to pointer
                }),
              }}
            />
          </div>
          <div className="dropdown-container custom-dropdown-container">
            <p className="mb-0">Employee</p>
            <Select
              className="custom-dropdown form-select"
              options={[...employeeOptions].sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              value={employee}
              placeholder="Select Employee"
              isSearchable={true}
              components={{ DropdownIndicator }}
              indicatorIcon={IdentificationIcon}
              styles={{
                control: (provided) => ({
                  ...provided,
                  cursor: "pointer", // Change cursor to pointer
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  width: "30px",
                  cursor: "pointer",
                }),
                indicatorSeparator: () => ({}),
                option: (provided) => ({
                  ...provided,
                  cursor: "pointer", // Change cursor to pointer
                }),
              }}
              onChange={handleEmployeeSelect}
            />
          </div>
          <div
            className="dropdown-container custom-dropdown-container d-flex jsutify-content-end"
            style={{ maxWidth: 135 }}
          >
            <Button
              className="mx-1  filter-button"
              onClick={applyDataFilter}
              disabled={isFilterDisabled}
            >
              Apply Filters
            </Button>
          </div>
          <div className="dropdown-container custom-dropdown-container">
            <Button
              className="mx-1  filter-button reset-filter-button"
              onClick={resetDataFilter}
              disabled={isFilterDisabled}
            >
              Reset Filters
            </Button>
          </div>
          {/* <DateFilter /> */}
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button onClick={moveIntervalLeft} className="btn btn-primary">
              &lt; {/* Left arrow */}
            </button>
            <div className="date-interval-container">
              <span>Showing Interval for Week</span>
              <br />
              <p>
                {intervalStart} - {intervalEnd}
              </p>
            </div>
            <button
              onClick={moveIntervalRight}
              className="btn btn-primary "
              disabled={isRightButtonDisabled}
            >
              &gt; {/* Right arrow */}
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader /> // Show loader while fetching data
      ) : applied ? (
        <>
          {reportData && reportData.user_data ? (
            <div className="mt-3">
              <Row>
                <Col>
                  <div className="card-group ">
                    <div className="card order-card-blue rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <ClockIcon className="mx-2" width={20} height={20} />
                        </div>
                        <div className="mt-3">
                          <span className="card-title">Time sheets</span>
                          <br />
                          <span className="mx-2 fw-bolder">
                            {`${reportData.user_data.timesheet.toFixed(2)}`}
                            hrs
                          </span>
                          <br />
                          <div className="mb-2">
                            {/* <span className="card-foot">
                              Monthly Average{" "}
                              <span className="fw-bolder">100+ hrs</span>
                            </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card order-card-red rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <ChartPieIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                          <span
                            className="grade-utilization"
                            style={{ padding: getPadding("B") }}
                          >
                            {getUtilizationGrade(
                              reportData.user_data.utilization
                            )}
                          </span>
                        </div>
                        <span className="card-title">Utilization</span>
                        <br />
                        <span className="mx-2 fw-bolder">{`${reportData.user_data.utilization.toFixed(
                          2
                        )}%`}</span>
                        <br />
                        <div className="mb-2">
                          {/* <span className="card-foot">
                            Monthly Average{" "}
                            <span className="fw-bolder">42%</span>
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="card order-card-green rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <NewspaperIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                          <span
                            className="grade-activity"
                            style={{ padding: getPadding("A+") }}
                          >
                            {getActivityGrade(reportData.user_data.activity)}
                          </span>
                        </div>
                        <span className="card-title">Activity</span>
                        <br />
                        <span className="mx-2 fw-bolder">
                          {" "}
                          {`${reportData.user_data.activity.toFixed(2)}%`}{" "}
                        </span>
                        <br />
                        <div className="mb-2">
                          {/* <span className="card-foot">
                            Monthly Average{" "}
                            <span className="fw-bolder">80%</span>
                          </span> */}
                        </div>
                      </div>
                    </div>
                    {/*
                    <div className="card order-card-blue rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <AdjustmentsIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                        </div>
                        <div className="mt-3">
                          <span className="card-title">Score</span>
                          <br />
                          <span className="mx-2 fw-bolder">82% </span>
                          <br />
                          <div className="mb-2">
                            <span className="card-foot">
                              Monthly Average{" "}
                              <span className="fw-bolder">68%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="card order-card-yellow rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <ChartSquareBarIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                        </div>
                        <div className="mt-3">
                          <span className="card-title">Revenue</span>
                          <br />
                          <span className="mx-2 fw-bolder">$867,892</span>
                          <br />
                          <div className="mb-2">
                            <span className="card-foot">
                              Monthly Average{" "}
                              <span className="fw-bolder">$867,892</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card order-card-green rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <CurrencyDollarIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                        </div>
                        <div className="mt-3">
                          <span className="card-title">Cost</span>
                          <br />
                          <span className="mx-2 fw-bolder">$167,562</span>
                          <br />
                          <div className="mb-2">
                            <span className="card-foot">
                              Monthly Average{" "}
                              <span className="fw-bolder">$167,892</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card order-card-red rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <PresentationChartLineIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                        </div>
                        <div className="mt-3">
                          <span className="card-title">Sales</span>
                          <br />
                          <span className="mx-2 fw-bolder">$167,562 </span>
                          <br />
                          <div className="mb-2">
                            <span className="card-foot">
                              Monthly Average{" "}
                              <span className="fw-bolder">$167,892</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card order-card-yellow rounded-2 mx-1">
                      <div className="card-block">
                        <div className="icon-container">
                          <PresentationChartBarIcon
                            className="mx-2"
                            width={20}
                            height={20}
                          />
                        </div>
                        <div className="mt-3">
                          <span className="card-title">MR</span>
                          <br />
                          <span className="mx-2 fw-bolder">$867,892 </span>
                          <br />
                          <div className="mb-2">
                            <span className="card-foot">
                              Monthly Average{" "}
                              <span className="fw-bolder">$867,892</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col>
                <br />
                <h3 className="text-center">No User Data Found!</h3>
                <br />
              </Col>
            </Row>
          )}
          <div className="mt-5">
            <Row>
              {" "}
              <Col lg={12}>
                <LineChartComponent
                  data={reportData.user_data.eight_weeks_performance}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col lg={6}>
                <LineChartComponent
                  data={chartData.data}
                  lines={chartData.lines}
                />
              </Col>
              <Col lg={6}>
                <LineChartComponent
                  data={chartData2.data}
                  lines={chartData2.lines}
                />
              </Col>
            </Row> */}
          </div>
          {/* <Row>
            <Col className="mt-5">
              <ReportTablePagination
                columns={TableData.columns_1}
                data={TableData.data_1}
              />
            </Col>
          </Row> */}

          {reportData &&
          reportData.user_data.projects &&
          Object.values(reportData.user_data.projects).length != 0 ? (
            <Row>
              <Col className="mt-5 mb-2">
                <ReportTablePagination
                  columns={project_columns}
                  data={reportData.user_data.projects.map((project) => ({
                    name: project.name,
                    timesheet: `${
                      Number.isInteger(project.timesheet)
                        ? project.timesheet.toFixed(0)
                        : project.timesheet.toFixed(2).replace(/\.0$/, "")
                    } hrs`,
                    billable_hours: `${
                      Number.isInteger(project.billable_hours)
                        ? project.billable_hours.toFixed(0)
                        : project.billable_hours.toFixed(2)
                    } hrs`,
                    non_billable_hours: `${
                      Number.isInteger(project.non_billable_hours)
                        ? project.non_billable_hours.toFixed(0)
                        : project.non_billable_hours.toFixed(2)
                    } hrs`,
                    activity: `${
                      Number.isInteger(project.activity)
                        ? project.activity.toFixed(0)
                        : project.activity.toFixed(2)
                    }%`,
                    utilization: `${
                      Number.isInteger(project.utilization)
                        ? project.utilization.toFixed(0)
                        : project.utilization.toFixed(2)
                    }%`,
                  }))}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <br />
                <h3 className="text-center">User data not found!</h3>
              </Col>
            </Row>
          )}
        </>
      ) : (
        // <div class="pt-7 m-auto text-center">
        //   <img
        //     style={{ maxWidth: "350px", width: "100%" }}
        //     src={reportCallback}
        //   />
        //   <br />
        //   <p class="mt-2">Select supervisors & employees and apply filters!</p>
        // </div>
        <CallBackPlaceholder
          url={reportCallback}
          message="Select supervisors & employees and apply filters!"
        />
      )}
    </>
  );
}

export default PastContent;
