import customAxios from "../../../../config/index";
import {
  MANAGEMENT_LEVEL_REQUEST,
  MANAGEMENT_LEVEL_SUCCESS,
  MANAGEMENT_LEVEL_FAILURE,
} from "./constants";

export const getManagementLevelReport = (data) => {
  return async (dispatch) => {
    dispatch({ type: MANAGEMENT_LEVEL_REQUEST });

    try {
      const response = await customAxios.post(
        "/reporting/management-level-report/",
        data
      );

      dispatch({
        type: MANAGEMENT_LEVEL_SUCCESS,
        payload: response.data.data,
      });
      return response.data.data;
    } catch (error) {
      dispatch({
        type: MANAGEMENT_LEVEL_FAILURE,
        payload: error.response,
      });
    }
  };
};
