import * as types from "./constants";

const initialState = {
  allSupervisors: [],
  teamMembers: [],
};

export default function supervisorReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_SUPERVISORS: {
      return {
        ...state,
        allSupervisors: action.payload,
      };
    }
    case types.GET_TEAM_MEMBERS: {
      return {
        ...state,
        teamMembers: action.payload,
      };
    }
    default:
      return state;
  }
}
