import React, { useEffect, useState } from "react";
import { CheckIcon, EyeIcon, HomeIcon, XIcon } from "@heroicons/react/solid";
import {
  Breadcrumb,
  Card,
  Form,
  Table,
  Button,
  Modal,
  Spinner,
  Col,
  Row,
  Tab,
  Nav,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CapitalizeFirstLetter } from "utils/Global";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams, Link } from "react-router-dom";
import { Routes } from "utils/routes";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { customSelectStyles } from "utils/Global";

export default () => {
  const params = useParams();
  let history = useHistory();
  const [projectObj, setProjectObj] = useState({});
  const [userList, setUserList] = useState([]);
  const tabs = ["Project info", "Members"];
  const [filtered, setFiltered] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [tabValue, setTabValue] = useState("Project info");
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.allProjects);

  useEffect(() => {
    let project = projects?.find((x) => x.id === parseInt(params.id));
    if (project.length === 0) {
      toast.error("Invalid Link");
      history.push(Routes.Project.path);
    } else {
      setProjectObj(project);
      setFiltered([
        {
          value: project.id,
          label: `${project?.name} )`,
        },
      ]);
      const update = projects.map((val) => ({
        value: val.id,
        label: `${val?.name} )`,
      }));
      setUserList(update);
    }
  }, [projects, params.id]);
 
  const [showDefaultDetail, setShowDefaultDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDefaultDetail(false);
    setFilteredProjects([]);
    Formik.resetForm();
  };

  const formikSchema = Yup.object().shape({
    projects: Yup.array().min(1).required("Projects is required"),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projects: filteredProjects,
    },
    validationSchema: formikSchema,
    onSubmit: async (values, action) => {
      let updatedObj = {
        projects: values.projects.map((key) => {
          return key.value;
        }),
        user: filtered[0].value,
        email: filtered[0].email,
      };
  
      toast.success("Successfully Assigned");
      handleCloseDetail();
      // }
    },
  });
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="pt-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} to={Routes.Project.path}>
              Projects
            </Breadcrumb.Item>
            <Breadcrumb.Item>{projectObj.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <Col xs={12} xl={12}>
          <Card className="p-3">
            <Tab.Container defaultActiveKey="Project info">
              <Row>
                <Col lg={12}>
                  <Nav className="nav-tabs">
                    {tabs.map((val, index) => {
                      return (
                        <Nav.Item key={index}>
                          <Nav.Link
                            eventKey={val}
                            onClick={() => {
                              setTabValue(val);
                            }}
                            className="mb-sm-3 mb-md-0"
                          >
                            {CapitalizeFirstLetter(val)}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col lg={12}>
                  <Tab.Content>
                    {tabs.map((val, index) => {
                      return (
                        <Tab.Pane key={index} eventKey={val} className="py-4">
                          <Row>
                            {tabValue === "Project info" && (
                              <div className="row">
                                <p className="mb-2 small pe-3 text-gray-700">
                                  <b className="text-gray-800">Name: </b>{" "}
                                  {projectObj?.name}
                                </p>

                                <p className="mb-2 small pe-3 text-gray-700">
                                  <b className="text-gray-800">AvazaId: </b>{" "}
                                  {projectObj?.avazaId
                                    ? projectObj.avazaId
                                    : "N/A"}
                                </p>
                                <p className="mb-2 small pe-3 text-gray-700">
                                  <b className="text-gray-800">HubStaffId: </b>{" "}
                                  {projectObj?.hubStaffId
                                    ? projectObj.hubStaffId
                                    : "N/A"}
                                </p>
                              </div>
                            )}
                            {tabValue === "Members" && (
                              <>
                                {/* <div className="btn-toolbar mb-3 mb-md-0 d-flex justify-content-end">
                                  <Button
                                    variant="tertiary"
                                    size="sm"
                                    className="d-inline-flex align-items-center"
                                    onClick={() => {
                                      setShowDefaultDetail(true);
                                    }}
                                  >
                                    <PlusIcon className="icon icon-xs me-2" />
                                    Assign Project
                                  </Button>
                                </div>
                                <div style={{ height: "20px" }}></div> */}

                                <Table striped bordered responsive>
                                  <thead>
                                    <tr>
                                      <td>
                                        <strong>Name</strong>
                                      </td>
                                      <td>
                                        <strong>Email</strong>
                                      </td>
                                      <td>
                                        <strong>HubStaff</strong>
                                      </td>
                                      <td>
                                        <strong>Avaza</strong>
                                      </td>
                                      <td>
                                        <strong>Action</strong>
                                      </td>
                                      {/* <td><strong>ACTIONS</strong></td> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {projectObj &&
                                      projectObj.users.map((user, index) => (
                                        <tr key={index}>
                                          <td>{user.name}</td>
                                          <td>{user.email}</td>
                                          <td>
                                            {user.hubStaffId ? (
                                              <CheckIcon
                                                className="icon icon-xs me-3"
                                                role="button"
                                              />
                                            ) : (
                                              <XIcon
                                                className="icon icon-xs"
                                                role="button"
                                              />
                                            )}
                                          </td>
                                          <td>
                                            {user.avazaId ? (
                                              <CheckIcon
                                                className="icon icon-xs me-3"
                                                role="button"
                                              />
                                            ) : (
                                              <XIcon
                                                className="icon icon-xs"
                                                role="button"
                                              />
                                            )}
                                          </td>
                                          <td>
                                            <EyeIcon
                                              className="icon icon-xs me-2"
                                              role="button"
                                              onClick={() => {
                                                history.push(
                                                  `/user_detail/${user.email}`
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                                <Modal
                                  as={Modal.Dialog}
                                  centered
                                  show={showDefaultDetail}
                                  onHide={handleCloseDetail}
                                >
                                  <Modal.Header>
                                    <Modal.Title className="h6">
                                      Assign Projects
                                    </Modal.Title>
                                    <Button
                                      variant="close"
                                      aria-label="Close"
                                      onClick={handleCloseDetail}
                                    />
                                  </Modal.Header>
                                  <Form
                                    onSubmit={Formik.handleSubmit}
                                    className="mt-0 signUp_Form"
                                  >
                                    <Modal.Body>
                                      <Form.Group
                                        id="select_user"
                                        className="mb-3"
                                      >
                                        <Form.Label>Select User</Form.Label>
                                        <ReactSelect
                                          options={userList}
                                          value={filtered}
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#61DAFB",
                                              primary: "#61DAFB",
                                            },
                                          })}
                                          styles={customSelectStyles}
                                          isDisabled={true}
                                          onChange={(arr) => {
                                            setFiltered([arr]);
                                          }}
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        id="select_user"
                                        className="mb-3"
                                      >
                                        <Form.Label>Select Projects</Form.Label>
                                        <ReactSelect
                                          //   options={projectArray}
                                          value={filteredProjects}
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#61DAFB",
                                              primary: "#61DAFB",
                                            },
                                          })}
                                          styles={customSelectStyles}
                                          isMulti
                                          onChange={(arr) => {
                                            setFilteredProjects([...arr]);
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {Formik?.errors?.projects
                                            ? "Projects is required"
                                            : ""}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        type="submit"
                                        disabled={disableSubmitButton}
                                      >
                                        {disableSubmitButton ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          "Save Changes"
                                        )}
                                      </Button>
                                      <Button
                                        variant="link"
                                        className="text-gray ms-auto"
                                        onClick={handleCloseDetail}
                                      >
                                        Close
                                      </Button>
                                    </Modal.Footer>
                                  </Form>
                                </Modal>
                              </>
                            )}
                          </Row>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};
