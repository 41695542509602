const moment = require("moment");

export const CapitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};
export const weekArr = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const GetDays = (first, last) => {
  const startDate = moment(first);
  const endDate = moment(last);
  endDate.add(1, "day");
  const daysOfWeek = [];
  let i = 0;
  while (i < 7 && startDate < endDate) {
    daysOfWeek.push(moment(startDate.toDate()).format("dddd")?.toLowerCase());
    startDate.add(1, "day");
    i++;
  }
  return daysOfWeek;
};

export const customSelectStyles = {
  control: (provided, state) => {
    const backgroundColor = state.isDisabled ? "#E5E7EB" : "white";
    return {
      ...provided,
      borderRadius: ".5rem",
      backgroundColor,
    };
  },

  multiValue: (provided,state) => {
    const backgroundColor = state.isDisabled ? "#c1c7cf" : "hsl(0, 0%, 90%)";
    return {
      ...provided,
      borderRadius: ".5rem",
     backgroundColor
    };
  },

  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: ".5rem",
  }),

  placeholder: (provided) => ({
    ...provided,
    borderRadius: ".5rem",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
    };
  },
};
