import React from "react";
const CallBackPlaceholder = (props) => {
  const { url, message } = props;
  return (
    <>
      <div class="pt-7 m-auto text-center">
        <img style={{ maxWidth: "350px", width: "100%" }} src={url} alt="img" />
        <br />
        <p class="mt-2">{message}</p>
      </div>
    </>
  );
};
export default CallBackPlaceholder;
