import React, { useState, useEffect, useMemo } from "react";
import { HomeIcon, PlusIcon, MailIcon } from "@heroicons/react/solid";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { UserIcon, CalendarIcon } from "@heroicons/react/solid";
import CreatableSelect from "react-select/creatable";
import { EyeIcon, PencilIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import ReactSelect from "react-select";
import UpDownIcon from "components/UpDownIcon";
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  InputGroup,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobleTable from "components/GlobleTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import generator from "generate-password";
import "react-phone-number-input/style.css";
import {
  addUserFun,
  editUsersFun,
  getUserFun,
  updateHubstaffandAvazaId,
  toggleUserAccountStatus,
} from "redux/user/action";
import { CapitalizeFirstLetter, customSelectStyles } from "utils/Global";
import UserDetailedView from "./UserDetailedView";
import Select from "react-select";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";

export default () => {
  const dispatch = useDispatch();
  const [showDefault, setShowDefault] = useState(false);
  const [showDefaultDetail, setShowDefaultDetail] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [isSupervisorState, setIsSupervisorState] = useState(false);
  const [loadIds, setLoadIds] = useState(false);
  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleClose = () => {
    setShowDefault(false);
    setFiltered([]);
    setIsSupervisorState(false);
    setSelectedSkills([]);
    Formik.resetForm();
  };

  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const handleCloseDetail = () => {
    setShowDefaultDetail(false);
  };

  let userData = useSelector((state) => state.users.allUsers);
  const [rowObj, setRowObj] = useState({});
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const typeOptions = [
    {
      value: "full_time",
      label: "Full Time",
    },
    { value: "part_time", label: "Part Time" },
    { value: "intern", label: "Intern" },
  ];

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: false,
    })
  );

  useEffect(() => {
    let count = 0;
    // userData &&
    //   userData.forEach((user) => {
    //     if (
    //       (!user.isSuperAdmin &&
    //         user.leaves.year !== new Date().getFullYear()) ||
    //       (!user.isSuperAdmin &&
    //         user.jobType === "intern" &&
    //         user.leaves.month !== moment().format("MM-YYYY"))
    //     ) {
    //       count = count + 1;
    //       dispatch(
    //         resetLeaves(
    //           leavesCalc({
    //             values: user,
    //             isEdit,
    //             rowObj,
    //             isAnnualAllowed: true,
    //             setUtilizedToZero: true,
    //           }),
    //           user.id
    //         )
    //       );
    // }
    //   });
    if (count) dispatch(getUserFun());
  }, [userData]);

  const toggleAccountStatusHandler = async (obj, val) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: val ? "success" : "error",
      title: "",
      text: val
        ? `Do you want to activate '${obj.name ? obj.name : obj.email}'?`
        : `Do you want to deactivate '${obj.name ? obj.name : obj.email}'?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      dispatch(toggleUserAccountStatus({ isActive: val }, obj.id));
    }
  };

  const generatePassword = () => {
    const pwd = generator.generate({
      length: 8,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    return pwd;
  };

  const skillSetOptions = [
    { value: "ReactJS", label: "ReactJS" },
    { value: "VueJS", label: "VueJS" },
    { value: "AngularJS", label: "AngularJS" },
    { value: "NodeJS", label: "NodeJS" },
    { value: "Java", label: "Java" },
    { value: "Python", label: "Python" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "HTML", label: "HTML" },
    { value: "CSS", label: "CSS" },
    { value: "MySQL", label: "MySQL" },
    { value: "MongoDB", label: "MongoDB" },
    { value: "AWS", label: "AWS" },
    { value: "Azure", label: "Azure" },
    { value: "Git", label: "Git" },
    { value: "Jira", label: "Jira" },
    { value: "Unity", label: "Unity" },
    { value: "Unreal Engine", label: "Unreal Engine" },
    { value: "Cocos2D", label: "Cocos2D" },
    { value: "Android", label: "Android" },
    { value: "Kotlin", label: "Kotlin" },
    { value: "React Native", label: "React Native" },
    { value: "Flutter", label: "Flutter" },
    { value: "Block Chain", label: "Block Chain" },
  ];

  const accountStatusFormatter = (cellContent, row) => {
    const handleSwitchChange = (e) => {
      if (isAdmin) {
        toggleAccountStatusHandler(row, e.target.checked);
      }
    };

    return (
      <Form.Check
        type="switch"
        label=""
        checked={row.isActive}
        // disabled={!isAdmin}
        onChange={handleSwitchChange}
        style={{
          pointerEvents: isAdmin ? "auto" : "none",
        }}
      />
    );
  };

  const columns = [
    { dataField: "id", text: "ID", hidden: true, csvExport: false },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: () => (
        <span>
          Name <UpDownIcon width={14} height={14} />
        </span>
      ),
    },
    { dataField: "email", text: "Email" },
    {
      dataField: "cnic",
      text: "CNIC",
      hidden: true,
      csvFormatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "dob",
      text: "Date of Joining",
      hidden: true,
      csvFormatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "phone",
      text: "Phone Number",
      csvFormatter: (cell) => {
        if (cell) {
          // Remove non-digit characters
          const cleanedNumber = cell.replace(/\D/g, "");
          // Add a dash after the first 5 digits
          const formattedNumber = cleanedNumber.replace(/^(\d{5})/, "$1-");
          return formattedNumber;
        }
        return "-";
      },
      hidden: true,
    },

    {
      dataField: "designation",
      text: "Designation",
      csvFormatter: (col) => (col ? col : "-"),
      formatter: (col) => (col ? col : "-"),
    },
    {
      dataField: "isAdmin",
      text: "Account Status",
      formatter: accountStatusFormatter,
      csvFormatter: (cell, row) => (row.isActive ? "Active" : "Inactive"),
    },
    {
      dataField: "action",
      text: "Action",
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <span title="View">
              <EyeIcon
                className="icon icon-xs me-2"
                role="button"
                onClick={() => {
                  setRowObj(row);
                  setShowDefaultDetail(true);
                }}
              />
            </span>
            {isAdmin && (
              <span title="Edit">
                <PencilIcon
                  className="icon icon-xs"
                  role="button"
                  onClick={() => {
                    setSelectedSkills(row?.skillSet);
                    setRowObj(row);
                    setFiltered(row?.supervisors);
                    setShowDefault(true);
                    setIsEdit(true);
                    setIsSupervisorState(row?.isSupervisor || false);
                  }}
                />
              </span>
            )}

            {/* <span title="Remove">
              <TrashIcon
                className="icon icon-xs ms-2"
                role="button"
                onClick={() => {
        
                }}
              />
            </span> */}
          </div>
        );
      },
    },
  ];

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const formikSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
    name: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Too Short!")
      .matches(/^[A-Za-z ]*$/, "Please enter a valid name")
      .max(50, "Too Long!"),
    password: Yup.string()
      .trim()
      .min(6, "Password is too short - should be 6 chars minimum"),
    designation: Yup.string()
      .trim()
      .required("Designation is required")
      .matches(
        /^[a-zA-Z\s()\-.]+$/,
        "Invalid input! Only letters and following special characters are allowed: ( ) . -"
      )
      .test(
        "has-alphabet",
        "Designation must include at least one alphabet character",
        (value) => {
          return value ? /[a-zA-Z]/.test(value) : false;
        }
      )

      .min(3, "Too Short!")
      .max(50, "Too Long!"),
    address: Yup.string().required("Address is required"),
    joinDate: Yup.date().required("Joining Date is required").max(today),
    isSupervisor: Yup.boolean(),
    jobType: Yup.string().required("Job type is required"),
    phone: Yup.string()
      .matches(/^\+\d{2}\d{3}\d{7}$/, "Please enter a valid phone number")
      .required("Phone Number is required"),
    dob: Yup.date().max(today),
    gender: Yup.string()
      .notOneOf(["Gender"], "Please select a valid gender")
      .required("Gender is required"),
    cnic: Yup.string()
      .trim()
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
        "Please enter a valid CNIC number"
      )
      .required("CNIC is required"),
    country: Yup.string().required("Country is required"),
    hubStaffId: Yup.string(),
    avazaId: Yup.string(),
  });

  const handleUpdateAwazaHubstaff = async (email) => {
    setLoadIds(true);
    const awazaHubstaffUser = await dispatch(updateHubstaffandAvazaId(email));

    if (awazaHubstaffUser?.id) {
      Formik.setFieldValue("avazaId", awazaHubstaffUser.avazaId);
      Formik.setFieldValue("hubStaffId", awazaHubstaffUser.hubStaffId);
    } else {
      toast.error("Hubstaff or Avaza ID not Found");
    }

    setLoadIds(false);
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: rowObj?.email ? rowObj.email : "",
      name: rowObj?.name ? rowObj.name : "",
      designation: rowObj?.designation ? rowObj.designation : "",
      address: rowObj?.address ? rowObj.address : "",
      phone: rowObj?.phone ? rowObj.phone : "",
      gender: rowObj?.gender ? rowObj.gender : "",
      cnic: rowObj?.cnic ? rowObj.cnic : "",
      dob: rowObj?.dob ? moment(rowObj.dob).format("YYYY-MM-DD") : today,
      joinDate: rowObj?.joinDate
        ? moment(rowObj.joinDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      jobType: rowObj?.jobType ? rowObj.jobType : "",
      skillSet: rowObj?.skillSet ? rowObj.skillSet : "",
      isSupervisor: rowObj?.isSupervisor ? rowObj.isSupervisor : false,
      supervisors: rowObj?.supervisors ? rowObj : "",
      maritalStatus: rowObj?.maritalStatus ? rowObj.maritalStatus : "single",
      country: rowObj?.country ? rowObj.country : "",
      childbirthLeavesAllowed: rowObj?.childbirthLeavesAllowed
        ? rowObj.childbirthLeavesAllowed
        : "",
      isActive: rowObj?.isActive,
      hubStaffId: rowObj?.hubStaffId ? rowObj.hubStaffId : "Null",
      avazaId: rowObj?.avazaId ? rowObj.avazaId : "Null",
    },

    validationSchema: formikSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
      let validPhone = isValidPhoneNumber(values.phone);
      if (validPhone === false) {
        Formik.setFieldError("phone", "Invalid phone number");
        return false;
      }
      let updatedObj = Object.assign(
        values,
        { password: generatePassword() },
        { supervisors: filtered.map((item) => item.value).join(", ") },
        { skillSet: selectedSkills.map((item) => item.value).join(", ") }
      );
      updatedObj.childbirthLeaveStatus = values.childbirthLeavesAllowed
        ? "True"
        : "False";

      delete updatedObj.childbirthLeavesAllowed;

      setDisableSubmitButton(!disableSubmitButton);
      let isSubmit = "";
      if (isEdit) {
        isSubmit = await dispatch(editUsersFun(updatedObj, rowObj.id));
        await dispatch(getUserFun());
      } else {
        isSubmit = await dispatch(addUserFun(updatedObj));
      }
      if (!isSubmit?.isSubmitButton) {
        setDisableSubmitButton(isSubmit?.isSubmitButton);
        handleClose();
        Formik.resetForm();
      }
    },
  });

  useEffect(() => {
    dispatch(getUserFun());
  }, []);

  useEffect(() => {
    if (userData.length) {
      const onlySupervisors = userData.filter((user) => user.isSupervisor);

      // Exclude the supervisor whose edit button is clicked
      const filteredSupervisors = onlySupervisors.filter(
        (supervisor) => supervisor.id !== rowObj?.id
      );

      const arr = filteredSupervisors.map((val) => ({
        value: val.id,
        label: `${val.name} - (${val.designation})`,
      }));

      setSupervisorsList(arr);
    }
  }, [userData, rowObj]);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="pt-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h4>User List</h4>
            </div>
          </div>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          {isAdmin && (
            <Button
              variant="tertiary"
              size="sm"
              className="d-inline-flex align-items-center"
              onClick={() => {
                setIsEdit(false);
                setRowObj({});
                setShowDefault(true);
              }}
            >
              <PlusIcon className="icon icon-xs me-2" /> Add User
            </Button>
          )}
        </div>
      </div>
      <GlobleTable
        data={userData || {}}
        columns={columns}
        totalEntries={userData?.totalEntries}
        totalPage={userData?.totalPages}
        tableType={"doctor"}
        currentPage={userData?.currentPage}
      />
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {isEdit ? "Edit " : "Add "} User
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={Formik.handleSubmit} className={`mt-0 signUp_Form`}>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Form.Group id="name" className="mb-2 mt-0">
                    <Form.Label>
                      Full Name<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        className="rounded-end"
                        autoFocus
                        type="text"
                        name="name"
                        value={Formik.values.name}
                        onChange={(e) => {
                          Formik.setFieldValue("name", e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="email" className="mb-2 mt-0">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        className="rounded-end"
                        type="email"
                        name="email"
                        value={Formik.values.email}
                        onChange={(e) => {
                          Formik.setFieldValue("email", e.target.value);
                        }}
                        disabled={isEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="designation" className="mb-2 mt-0">
                    <Form.Label>
                      Designation<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        className="rounded-end"
                        type="text"
                        name="designation"
                        value={Formik.values.designation}
                        onChange={(e) => {
                          Formik.setFieldValue("designation", e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik.errors.designation}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="phone" className="mb-2 mt-0">
                    <Form.Label>
                      Phone Number<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <PhoneInput
                        international
                        defaultCountry="PK"
                        type="text"
                        label="Phone Number"
                        isinvalid
                        value={Formik.values.phone}
                        className="phoneNumberField form-control rounded-end d-flex"
                        onChange={(phone) => {
                          Formik.setFieldValue("phone", phone);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="gender" className="mb-2 mt-0">
                    <Form.Label>
                      Gender<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={Formik.values.gender}
                        onChange={(e) => {
                          Formik.setFieldValue("gender", e.target.value);
                        }}
                        className="mb-0 rounded-end"
                        multiple={false}
                      >
                        <option defaultValue>Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.gender}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="dob" className="mb-2 mt-0">
                    <Form.Label>
                      Date of Birth<span className="text-danger">*</span>
                    </Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={(date) => {
                        Formik.setFieldValue(
                          "dob",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarIcon className="icon icon-xs" />
                          </InputGroup.Text>
                          <Form.Control
                            className="rounded-end"
                            required
                            type="text"
                            placeholder="YYYY-MM-DD"
                            value={Formik.values.dob}
                            onFocus={openCalendar}
                            onChange={() => {}}
                            closeOnSelect={true}
                          />
                          <Form.Control.Feedback type="invalid">
                            {Formik?.errors?.dob}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                  <Form.Group id="country">
                    <Form.Label>
                      Country<span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      style={{ index: "999" }}
                      options={countryOptions}
                      value={countryOptions.find(
                        (option) => option.value === Formik.values.country
                      )}
                      onChange={(selectedOption) => {
                        Formik.setFieldValue("country", selectedOption.value);
                      }}
                      isSearchable
                      placeholder="Select a country"
                    />
                    <Form.Control.Feedback type="invalid">
                      {Formik?.errors?.country}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    id="isSupervisor"
                    className="mb-2 mt-1 rounded-end"
                  >
                    <Form.Check
                      type="checkbox"
                      defaultChecked={isSupervisorState}
                      label="Is Supervisor"
                      name="isSupervisor"
                      onChange={(e) => {
                        Formik.setFieldValue("isSupervisor", e.target.checked);
                      }}
                    />
                  </Form.Group>
                  {isEdit && isAdmin && (
                    <Form.Group id="hubStaffId" className="mb-2 mt-0">
                      <Form.Label>Hubstaff ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className="rounded-end"
                          type="text"
                          name="hubStaffId"
                          value={Formik.values.hubStaffId}
                          onChange={(e) => {
                            Formik.setFieldValue("hubStaffId", e.target.value);
                          }}
                          disabled={isEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          {Formik?.errors?.hubStaffId}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  <Form.Group id="cnic" className="mb-2 mt-0">
                    <Form.Label>
                      CNIC<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        className="rounded-end"
                        type="cnic"
                        name="cnic"
                        value={Formik.values.cnic}
                        onChange={(e) => {
                          const inputCnic = e.target.value;
                          const formattedCnic = inputCnic
                            .replace(/\D/g, "") // Remove all non-numeric characters
                            .replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3"); // Add dashes at appropriate positions
                          Formik.setFieldValue("cnic", formattedCnic);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.cnic}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="startDate" className="mb-2 mt-0">
                    <Form.Label>
                      Add joining date<span className="text-danger">*</span>
                    </Form.Label>
                    <Datetime
                      timeFormat={false}
                      onChange={(date) => {
                        Formik.setFieldValue(
                          "joinDate",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarIcon className="icon icon-xs" />
                          </InputGroup.Text>
                          <Form.Control
                            className="rounded-end"
                            required
                            type="text"
                            placeholder="YYYY-MM-DD"
                            value={Formik.values.joinDate}
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                          <Form.Control.Feedback type="invalid">
                            {Formik?.errors?.joinDate}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                      closeOnSelect={true}
                    />
                  </Form.Group>
                  <Form.Group id="address" className="mb-2 mt-0">
                    <Form.Label>
                      Address<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        className="rounded-end"
                        type="text"
                        name="address"
                        value={Formik.values.address}
                        onChange={(e) => {
                          Formik.setFieldValue("address", e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Formik?.errors?.address}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="skillSet" className="mb-3 mt-0 rounded-end">
                    <Form.Label>Skill Set</Form.Label>
                    <CreatableSelect
                      styles={customSelectStyles}
                      isMulti
                      options={skillSetOptions}
                      value={selectedSkills}
                      onChange={(arr) => {
                        setSelectedSkills([...arr]);
                      }}
                      formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Formik?.errors?.skillSet}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group id="job_type" className="mb-3 mt-0">
                    <Form.Label>
                      Job Type<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="mb-0 rounded-end"
                      name="jobType"
                      value={Formik.values.jobType}
                      onChange={(e) => {
                        Formik.setFieldValue("jobType", e.target.value);
                      }}
                    >
                      <option value="">Select Type</option>
                      {typeOptions.map((item, i) => {
                        return (
                          <>
                            <option value={item.value} key={i}>
                              {CapitalizeFirstLetter(item.label)}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {Formik?.errors?.jobType}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group id="select_supervisors" className="mb-2 mt-0">
                    <Form.Label>Select Supervisors</Form.Label>
                    <ReactSelect
                      options={supervisorsList}
                      value={filtered}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#61DAFB",
                          primary: "#61DAFB",
                        },
                      })}
                      styles={customSelectStyles}
                      isMulti
                      onChange={(arr) => {
                        setFiltered([...arr]);
                      }}
                    />
                  </Form.Group>
                  <Form.Group id="maritalStatus" className="mb-2 mt-0">
                    <Form.Label>
                      Marital Status<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="mb-0 rounded-end"
                      name="maritalStatus"
                      value={Formik.values.maritalStatus}
                      onChange={(e) => {
                        Formik.setFieldValue("maritalStatus", e.target.value);
                        Formik.setFieldValue(
                          "childbirthLeavesAllowed",
                          e.target.defaultChecked
                        );
                      }}
                    >
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                    </Form.Select>
                  </Form.Group>
                  {isEdit && Formik.values.maritalStatus === "married" && (
                    <Form.Group
                      id="childbirthLeavesAllowed"
                      className="mb-2 mt-1 rounded-end"
                    >
                      <Form.Check
                        type="checkbox"
                        checked={Formik.values.childbirthLeavesAllowed}
                        label="Allow Maternity/Paternity"
                        name="childbirthLeavesAllowed"
                        onChange={(e) => {
                          Formik.setFieldValue(
                            "childbirthLeavesAllowed",
                            e.target.checked
                          );
                        }}
                      />
                    </Form.Group>
                  )}

                  {isEdit && isAdmin && (
                    <Form.Group id="avazaId" className="mb-2 mt-0">
                      <Form.Label>Avaza ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className="rounded-end"
                          type="text"
                          name="avazaId"
                          value={Formik.values.avazaId}
                          onChange={(e) => {
                            Formik.setFieldValue("avazaId", e.target.value);
                          }}
                          disabled={isEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          {Formik?.errors?.avazaId}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              disabled={disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Spinner animation="border" size="sm" />
              ) : isEdit ? (
                "Update"
              ) : (
                "Invite"
              )}
            </Button>
            {isAdmin &&
              isEdit &&
              Formik.values.hubStaffId == "Null" &&
              Formik.values.avazaId == "Null" && (
                <Button
                  variant="primary"
                  className="text-white"
                  onClick={() => handleUpdateAwazaHubstaff(Formik.values.email)}
                  // disabled={loadIds}
                  disabled={loadIds}
                >
                  <Row fluid>
                    <Col md={loadIds ? 10 : 12}>Get Hubstaff and avazaId</Col>
                    {loadIds && (
                      <Col md={1}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="ps-2"
                        />
                      </Col>
                    )}
                  </Row>
                </Button>
              )}
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefaultDetail}
        onHide={handleCloseDetail}
      >
        <Modal.Header>
          <Modal.Title className="h6">Detailed View</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDetail}
          />
        </Modal.Header>
        <Modal.Body>
          <UserDetailedView obj={rowObj} />
        </Modal.Body>
      </Modal>
    </>
  );
};
