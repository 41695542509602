import moment from "moment-timezone";
import React from "react";
import { CapitalizeFirstLetter } from "utils/Global";

export default function RequestView({ obj, isView }) {
  return (
    obj && (
      <>
        {isView && (
          <>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Name: </b> {obj.user?.name}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Email: </b> {obj.user?.email}
            </p>
            <p className="mb-2 small pe-3 text-gray-400">
              <b className="text-gray-800">Designation: </b>{" "}
              {obj.user?.designation}
            </p>
          </>
        )}
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Start: </b>{" "}
          {moment(obj.start_date).format("DD-MM-YYYY")}
        </p>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">End: </b>{" "}
          {moment(obj.end_date).format("DD-MM-YYYY")}
        </p>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Number of days: </b> {obj.days}
        </p>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Status: </b>{" "}
          {CapitalizeFirstLetter(obj.status)}{" "}
          <span class={`dot_${obj.status}`}></span>
        </p>
        <p className="mb-2 small pe-3 text-gray-400">
          <b className="text-gray-800">Description: </b> {obj.description}
        </p>
      </>
    )
  );
}
