import React, { useState } from "react";
import { Table, Badge } from "react-bootstrap";
import "./Styles/ReportTable.scss";

const ReportTable = ({ columns, data, currentRoute }) => {
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="table-container">
      <Table className="custom-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                onClick={() => handleSort(column.field)}
                className={
                  sortField === column.field ? `sorted ${sortOrder}` : ""
                }
              >
                {column.title.charAt(0).toUpperCase() + column.title.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => (
            <tr key={index}>
              {columns.map((column, columnIndex) => {
                let cellContent;
                if (column.field === "status") {
                  cellContent = (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                  );
                } else if (column.field === "skills") {
                  if (Array.isArray(row[column.field])) {
                    cellContent = row[column.field].join(", ");
                  } else {
                    cellContent = row[column.field];
                  }
                } else if (column.field === "notes") {
                  cellContent = (
                    <div style={{ whiteSpace: "pre-line" }}>
                      {row[column.field]}
                    </div>
                  );
                } else {
                  cellContent = row[column.field];
                }

                return <td key={columnIndex}>{cellContent}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ReportTable;
