import { combineReducers } from "redux";
import requestReducer from "redux/requests/reducer";
import allUser from "redux/user/reducer";
import authReducer from "../redux/auth/reducer";
import projectReducer from "redux/projects/reducer";
import { associateReportReducer } from "redux/reports/Associate_Level/reducer";
import supervisorReducer from "redux/employee/reducer";
import managementReportReducer from "redux/reports/Associate_Level/Management_level/reducer";


const combinedReducer = combineReducers({
  auth: authReducer,
  users: allUser,
  reqs: requestReducer,
  projects: projectReducer,
  associateLevel: associateReportReducer,
  managemenLevel: managementReportReducer,
  employees: supervisorReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
